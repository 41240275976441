export const SearchIcon = () => (
    <svg
        className="search-icon__popup"
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect width="32" height="32" rx="8" fill="#EAF2F1" />
        <g clipPath="url(#clip0_148_2305)">
            <path
                d="M18 7.072C18.9525 7.62194 19.7813 8.36238 20.4348 9.24706C21.0882 10.1317 21.5522 11.1417 21.7978 12.2137C22.0434 13.2858 22.0653 14.397 21.8621 15.4779C21.659 16.5589 21.2351 17.5863 20.617 18.496L25.561 23.439C25.8363 23.7102 25.9962 24.0772 26.0075 24.4635C26.0188 24.8498 25.8806 25.2255 25.6217 25.5124C25.3628 25.7993 25.0032 25.9752 24.6178 26.0034C24.2324 26.0317 23.851 25.9101 23.553 25.664L23.439 25.561L18.496 20.617C17.3134 21.4203 15.9371 21.892 14.5104 21.983C13.0837 22.0741 11.6586 21.7812 10.3835 21.1347C9.10835 20.4883 8.02975 19.5119 7.25989 18.3073C6.49004 17.1027 6.05706 15.7137 6.006 14.285L6 14L6.005 13.715C6.05419 12.3357 6.45937 10.9927 7.18115 9.81633C7.90292 8.63998 8.91675 7.67037 10.1241 7.00173C11.3314 6.33308 12.6912 5.98816 14.0713 6.00047C15.4514 6.01278 16.8048 6.38192 18 7.072ZM17.707 11.292C17.5195 11.1045 17.2652 10.9992 17 10.9992C16.7348 10.9992 16.4805 11.1045 16.293 11.292L13 14.586L11.707 13.293L11.613 13.21C11.412 13.0546 11.1594 12.9815 10.9065 13.0056C10.6536 13.0297 10.4193 13.1492 10.2512 13.3397C10.0832 13.5303 9.99402 13.7777 10.0018 14.0316C10.0095 14.2856 10.1137 14.527 10.293 14.707L12.293 16.707L12.387 16.79C12.5794 16.9392 12.8197 17.0132 13.0627 16.9979C13.3057 16.9826 13.5348 16.8792 13.707 16.707L17.707 12.707L17.79 12.613C17.9393 12.4206 18.0132 12.1803 17.9979 11.9373C17.9826 11.6943 17.8792 11.4652 17.707 11.293V11.292Z"
                fill="var(--main-color)"
            />
        </g>
        <defs>
            <clipPath id="clip0_148_2305">
                <rect width="24" height="24" fill="white" transform="translate(4 4)" />
            </clipPath>
        </defs>
    </svg>
);

export const ParagonIcon = ({ color = '#2D7876' }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.63309 3.90338C1.87552 2.87387 0.430396 2.02417 0.421647 2.0152C0.412935 2.00618 5.63427 1.9994 12.0246 2.00004C18.5498 2.00072 23.6184 2.01635 23.5864 2.03566C23.4711 2.10509 21.5917 3.20738 18.755 4.86921C17.1543 5.80692 14.9843 7.07899 13.9327 7.69602L12.0206 8.81786L9.42468 7.29654C7.99683 6.4598 5.39065 4.93286 3.63309 3.90338ZM0.127692 3.40388C0.0574655 3.28827 2.11522e-05 3.18454 2.11522e-05 3.17333C2.11522e-05 3.17118 -0.000194333 3.16908 0.000750252 3.16795C0.0100404 3.15689 0.131544 3.24009 1.67389 4.29282C2.37483 4.77126 3.28214 5.38973 3.69015 5.66726C4.09815 5.94479 4.8086 6.42878 5.26892 6.7428L7.34075 8.15585L9.74498 9.7955C10.3881 10.2342 10.9424 10.6111 10.9766 10.6332C10.9792 10.6349 10.9817 10.6365 10.9841 10.6382C11.045 10.6818 11.0491 10.805 11.1643 14.2023C11.1967 15.1564 11.2401 16.4306 11.2608 17.0338C11.2816 17.637 11.3239 18.8753 11.3549 19.7855C11.3859 20.6959 11.4221 21.7233 11.4354 22.0687C11.4486 22.4142 11.4504 22.6968 11.4392 22.6968C11.4281 22.6968 11.3854 22.6385 11.3443 22.5672C11.1431 22.2186 9.9685 20.2092 8.3893 17.5124C7.94616 16.7556 7.18976 15.4635 6.70837 14.641C6.22702 13.8184 5.37068 12.3559 4.80548 11.3907C4.24025 10.4256 2.98526 8.28103 2.01658 6.62499C1.0479 4.96895 0.197919 3.51945 0.127692 3.40388ZM21.3793 4.94757C22.3939 4.258 23.3953 3.57517 23.6049 3.43024C23.8144 3.28527 23.9922 3.17229 24 3.17919C24.0078 3.18605 22.4937 5.79021 20.6353 8.96618C18.777 12.1421 16.8946 15.3598 16.4523 16.1165C14.7809 18.9765 13.4411 21.2664 13.024 21.9759L12.5921 22.7108V22.4897C12.5921 22.368 12.609 21.7682 12.6298 21.1567C12.6505 20.5451 12.6935 19.1834 12.7253 18.1305C12.7905 15.9736 12.8391 14.3843 12.8979 12.4875C12.92 11.7745 12.9415 11.0763 12.9458 10.9357L12.9535 10.68L13.9826 9.97851C14.5488 9.5927 15.6444 8.84714 16.4174 8.32167C17.1904 7.79621 18.2081 7.10416 18.6789 6.78384C19.1497 6.46352 20.3649 5.63719 21.3793 4.94757Z"
            fill={color}
        />
    </svg>
);

export const CartIcon = ({ color = '#2D7876' }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M1.5 3H3.25L4 6M4 6L6.5 16H19.5L22 6H4Z"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M6.5 21C7.32843 21 8 20.3284 8 19.5C8 18.6716 7.32843 18 6.5 18C5.67157 18 5 18.6716 5 19.5C5 20.3284 5.67157 21 6.5 21Z"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M19.5 21C20.3284 21 21 20.3284 21 19.5C21 18.6716 20.3284 18 19.5 18C18.6716 18 18 18.6716 18 19.5C18 20.3284 18.6716 21 19.5 21Z"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const ShareIcon = ({ color = '#2D7876' }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M18 22C17.1667 22 16.4583 21.7083 15.875 21.125C15.2917 20.5417 15 19.8333 15 19C15 18.8833 15.0083 18.7623 15.025 18.637C15.0417 18.5117 15.0667 18.3993 15.1 18.3L8.05 14.2C7.76667 14.45 7.45 14.646 7.1 14.788C6.75 14.93 6.38333 15.0007 6 15C5.16667 15 4.45833 14.7083 3.875 14.125C3.29167 13.5417 3 12.8333 3 12C3 11.1667 3.29167 10.4583 3.875 9.875C4.45833 9.29167 5.16667 9 6 9C6.38333 9 6.75 9.071 7.1 9.213C7.45 9.355 7.76667 9.55067 8.05 9.8L15.1 5.7C15.0667 5.6 15.0417 5.48767 15.025 5.363C15.0083 5.23833 15 5.11733 15 5C15 4.16667 15.2917 3.45833 15.875 2.875C16.4583 2.29167 17.1667 2 18 2C18.8333 2 19.5417 2.29167 20.125 2.875C20.7083 3.45833 21 4.16667 21 5C21 5.83333 20.7083 6.54167 20.125 7.125C19.5417 7.70833 18.8333 8 18 8C17.6167 8 17.25 7.92933 16.9 7.788C16.55 7.64667 16.2333 7.45067 15.95 7.2L8.9 11.3C8.93333 11.4 8.95833 11.5127 8.975 11.638C8.99167 11.7633 9 11.884 9 12C9 12.1167 8.99167 12.2377 8.975 12.363C8.95833 12.4883 8.93333 12.6007 8.9 12.7L15.95 16.8C16.2333 16.55 16.55 16.3543 16.9 16.213C17.25 16.0717 17.6167 16.0007 18 16C18.8333 16 19.5417 16.2917 20.125 16.875C20.7083 17.4583 21 18.1667 21 19C21 19.8333 20.7083 20.5417 20.125 21.125C19.5417 21.7083 18.8333 22 18 22Z"
            fill={color}
        />
    </svg>
);

export const SeeAllIcon = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6.66675 2.66663H4.00008C3.64646 2.66663 3.30732 2.8071 3.05727 3.05715C2.80722 3.3072 2.66675 3.64634 2.66675 3.99996V12C2.66675 12.3536 2.80722 12.6927 3.05727 12.9428C3.30732 13.1928 3.64646 13.3333 4.00008 13.3333H12.0001C12.3537 13.3333 12.6928 13.1928 12.9429 12.9428C13.1929 12.6927 13.3334 12.3536 13.3334 12V9.33329M8.00008 7.99996L13.3334 2.66663M13.3334 2.66663V5.99996M13.3334 2.66663H10.0001"
            stroke="#E03138"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const ArrowConnectorIcon = ({ style }) => (
    <svg style={style} width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7 0L13.9282 12H0.0717969L7 0Z" fill="#EAF2F1" />
    </svg>
);

export const InfoIcon = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_35_24661)">
            <path
                d="M8.00016 14.6666C9.8411 14.6666 11.5078 13.9204 12.7142 12.714C13.9206 11.5075 14.6668 9.84085 14.6668 7.99992C14.6668 6.15899 13.9206 4.49232 12.7142 3.28587C11.5078 2.07945 9.8411 1.33325 8.00016 1.33325C6.15923 1.33325 4.49256 2.07945 3.28612 3.28587C2.07969 4.49232 1.3335 6.15899 1.3335 7.99992C1.3335 9.84085 2.07969 11.5075 3.28612 12.714C4.49256 13.9204 6.15923 14.6666 8.00016 14.6666Z"
                stroke="#0056CC"
                stroke-width="1.2"
                stroke-linejoin="round"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.99984 3.66675C8.46007 3.66675 8.83317 4.03985 8.83317 4.50008C8.83317 4.96031 8.46007 5.33341 7.99984 5.33341C7.5396 5.33341 7.1665 4.96031 7.1665 4.50008C7.1665 4.03985 7.5396 3.66675 7.99984 3.66675Z"
                fill="#0056CC"
            />
            <path
                d="M8.16667 11.3334V6.66675H7.83333H7.5"
                stroke="#0056CC"
                stroke-width="1.2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M7 11.3333H9.33333"
                stroke="#0056CC"
                stroke-width="1.2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </g>
        <defs>
            <clipPath id="clip0_35_24661">
                <rect width="16" height="16" fill="white" />
            </clipPath>
        </defs>
    </svg>
);
