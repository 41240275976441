import { Text } from '@chakra-ui/react';
import { ActionErrorColumn, ErrorMessageColumn } from '@pages/UploadSellout/components/otherColumns';

export const tableList = [
    {
        name: 'No',
        width: 100,
        value: 'no',
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },

    {
        name: 'Created At',
        width: 140,
        value: 'created_at',
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },

    {
        name: 'Rows',
        width: 180,
        value: 'line',
        component: ({ value, values }) => <ErrorMessageColumn value={value} values={values} />,
    },

    {
        name: 'Description',
        width: 340,
        value: 'description',
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },

    {
        name: 'File Error',
        width: 160,
        value: 'url_file',
        component: ({ value, values }) => <ActionErrorColumn value={value} values={values} />,
    },
];
