export const UploadIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M4.86573 8.44626C3.03117 8.88255 1.66669 10.5319 1.66669 12.5C1.66669 14.8012 3.53217 16.6667 5.83335 16.6667C6.2281 16.6667 6.61002 16.6118 6.9719 16.5092"
            stroke="var(--main-color)"
            strokeWidth="1.67"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M15.0228 8.44626C16.8574 8.88255 18.2218 10.5319 18.2218 12.5C18.2218 14.8012 16.3564 16.6667 14.0552 16.6667C13.6604 16.6667 13.2785 16.6118 12.9166 16.5092"
            stroke="var(--main-color)"
            strokeWidth="1.67"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M15 8.33334C15 5.57193 12.7614 3.33334 10 3.33334C7.23858 3.33334 5 5.57193 5 8.33334"
            stroke="var(--main-color)"
            strokeWidth="1.67"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M7.1106 11.6172L9.99997 8.71826L12.9716 11.6668"
            stroke="var(--main-color)"
            strokeWidth="1.67"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M10 15.8334V10.1924"
            stroke="var(--main-color)"
            strokeWidth="1.67"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const OrderIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M15.4167 3.33333H4.58333C4.1231 3.33333 3.75 3.70642 3.75 4.16666V17.5C3.75 17.9602 4.1231 18.3333 4.58333 18.3333H15.4167C15.8769 18.3333 16.25 17.9602 16.25 17.5V4.16666C16.25 3.70642 15.8769 3.33333 15.4167 3.33333Z"
            stroke="var(--main-color)"
            strokeWidth="1.66667"
            strokeLinejoin="round"
        />
        <path
            d="M7.5 1.66667V4.16667"
            stroke="var(--main-color)"
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M12.5 1.66667V4.16667"
            stroke="var(--main-color)"
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M6.66669 7.91667H13.3334"
            stroke="var(--main-color)"
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M6.66669 11.25H11.6667"
            stroke="var(--main-color)"
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M6.66669 14.5833H10"
            stroke="var(--main-color)"
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const AuditIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M17.5 1.66667H2.5V5.83334H17.5V1.66667Z"
            stroke="var(--main-color)"
            strokeWidth="1.66667"
            strokeLinejoin="round"
        />
        <path
            d="M17.5 7.91667H2.5V12.0833H17.5V7.91667Z"
            stroke="var(--main-color)"
            strokeWidth="1.66667"
            strokeLinejoin="round"
        />
        <path
            d="M17.5 14.1667H2.5V18.3333H17.5V14.1667Z"
            stroke="var(--main-color)"
            strokeWidth="1.66667"
            strokeLinejoin="round"
        />
        <path d="M8.75 3.75H11.25" stroke="var(--main-color)" strokeWidth="1.66667" strokeLinecap="round" />
        <path d="M8.75 10H11.25" stroke="var(--main-color)" strokeWidth="1.66667" strokeLinecap="round" />
        <path d="M8.75 16.25H11.25" stroke="var(--main-color)" strokeWidth="1.66667" strokeLinecap="round" />
    </svg>
);

export const DashboardIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M8.33333 2.5H2.5V7.08333H8.33333V2.5Z"
            fill="var(--main-color)"
            stroke="var(--main-color)"
            strokeWidth="2"
            strokeLinejoin="round"
        />
        <path
            d="M17.5 12.9167H11.6667V17.5H17.5V12.9167Z"
            fill="var(--main-color)"
            stroke="var(--main-color)"
            strokeWidth="2"
            strokeLinejoin="round"
        />
        <path
            d="M17.5 2.5H11.6667V9.58333H17.5V2.5Z"
            fill="var(--main-color)"
            stroke="var(--main-color)"
            strokeWidth="2"
            strokeLinejoin="round"
        />
        <path
            d="M8.33333 10.4167H2.5V17.5H8.33333V10.4167Z"
            fill="var(--main-color)"
            stroke="var(--main-color)"
            strokeWidth="2"
            strokeLinejoin="round"
        />
    </svg>
);

export const StoreIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M15.9567 2.47583C15.8082 2.2296 15.5988 2.02579 15.3486 1.88406C15.0984 1.74233 14.8159 1.66746 14.5284 1.66667H5.47169C4.89002 1.66667 4.34252 1.97667 4.04335 2.47583L1.78585 6.2375C1.70773 6.36701 1.66652 6.51542 1.66669 6.66667C1.67002 7.47101 1.96627 8.2466 2.50002 8.84833V15.8333C2.50002 16.7525 3.24752 17.5 4.16669 17.5H15.8334C16.7525 17.5 17.5 16.7525 17.5 15.8333V8.84833C18.0338 8.2466 18.33 7.47101 18.3334 6.66667C18.3335 6.51542 18.2923 6.36701 18.2142 6.2375L15.9567 2.47583ZM16.6534 6.87583C16.6019 7.27819 16.4057 7.64804 16.1015 7.91627C15.7972 8.1845 15.4056 8.33276 15 8.33333C14.0809 8.33333 13.3334 7.58583 13.3334 6.66667C13.3334 6.61 13.3125 6.56 13.3009 6.50667L13.3175 6.50333L12.6834 3.33333H14.5284L16.6534 6.87583ZM9.01585 3.33333H10.9834L11.6609 6.72083C11.6317 7.61417 10.9 8.33333 10 8.33333C9.10002 8.33333 8.36835 7.61417 8.33919 6.72083L9.01585 3.33333ZM5.47169 3.33333H7.31669L6.68335 6.50333L6.70002 6.50667C6.68752 6.56 6.66669 6.61 6.66669 6.66667C6.66669 7.58583 5.91919 8.33333 5.00002 8.33333C4.5944 8.33276 4.20286 8.1845 3.89859 7.91627C3.59431 7.64804 3.39812 7.27819 3.34669 6.87583L5.47169 3.33333ZM8.33335 15.8333V13.3333H11.6667V15.8333H8.33335ZM13.3334 15.8333V13.3333C13.3334 12.4142 12.5859 11.6667 11.6667 11.6667H8.33335C7.41419 11.6667 6.66669 12.4142 6.66669 13.3333V15.8333H4.16669V9.88167C4.43419 9.95083 4.71002 10 5.00002 10C5.4732 10.0002 5.94098 9.8995 6.37211 9.70449C6.80323 9.50948 7.18777 9.22469 7.50002 8.86917C8.11085 9.5625 9.00585 10 10 10C10.9942 10 11.8892 9.5625 12.5 8.86917C12.8123 9.22469 13.1968 9.50948 13.6279 9.70449C14.0591 9.8995 14.5268 10.0002 15 10C15.29 10 15.5659 9.95083 15.8334 9.88167V15.8333H13.3334Z"
            fill="var(--main-color)"
        />
    </svg>
);

export const UserManagementIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1615_1230)">
            <path
                d="M5.83333 14.1667C6.98393 14.1667 7.91667 13.2339 7.91667 12.0833C7.91667 10.9327 6.98393 10 5.83333 10C4.68274 10 3.75 10.9327 3.75 12.0833C3.75 13.2339 4.68274 14.1667 5.83333 14.1667Z"
                stroke="var(--main-color)"
                strokeWidth="1.67"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M14.1666 14.1667C15.3172 14.1667 16.25 13.2339 16.25 12.0833C16.25 10.9327 15.3172 10 14.1666 10C13.0161 10 12.0833 10.9327 12.0833 12.0833C12.0833 13.2339 13.0161 14.1667 14.1666 14.1667Z"
                stroke="var(--main-color)"
                strokeWidth="1.67"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10 5.83333C11.1506 5.83333 12.0834 4.90059 12.0834 3.75C12.0834 2.59941 11.1506 1.66667 10 1.66667C8.84943 1.66667 7.91669 2.59941 7.91669 3.75C7.91669 4.90059 8.84943 5.83333 10 5.83333Z"
                stroke="var(--main-color)"
                strokeWidth="1.67"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10 18.3333C10 16.0322 8.13452 14.1667 5.83335 14.1667C3.53217 14.1667 1.66669 16.0322 1.66669 18.3333"
                stroke="var(--main-color)"
                strokeWidth="1.67"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18.3333 18.3333C18.3333 16.0322 16.4678 14.1667 14.1667 14.1667C11.8655 14.1667 10 16.0322 10 18.3333"
                stroke="var(--main-color)"
                strokeWidth="1.67"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M14.1666 10C14.1666 7.69883 12.3011 5.83333 9.99998 5.83333C7.69881 5.83333 5.83331 7.69883 5.83331 10"
                stroke="var(--main-color)"
                strokeWidth="1.67"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
        <defs>
            <clipPath id="clip0_1615_1230">
                <rect width="20" height="20" fill="var(--main-color)" />
            </clipPath>
        </defs>
    </svg>
);
