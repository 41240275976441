import { onUploadFile } from './crud';

export const onChangeFile = ({ selectedFile, onChangePopUp, onFetchStoreConfig, onChangeSnackbarNotification }) => {
    onChangePopUp({
        status: 'submit',
        title: 'Apakah anda yakin?',
        description: 'Data yang sudah diupload akan masuk kedalam database',
        onSubmit: () => {
            onUploadFile({
                selectedFile,
                onChangeSnackbarNotification,
                onChangePopUp,
                onFetchStoreConfig,
            });
        },
    });
};


export const onChangeSetting = ({ newValue, store }) => {
    store?.onChangeConfigDetail({
        storeInfo: { ...store?.storeInfo, ...newValue },
    });
};

export const onUpdateActiveReplenisment = ({ newValue, store }) => {
    let newValueTemp = newValue;

    if (newValue?.is_replenishment) {
        newValueTemp.exagon_id = store?.storeInfo?.customer_id;
    }

    onChangeSetting({
        newValue: newValueTemp,
        store,
    });
};