import { Skeleton, Th, Td, Tr, Flex, Tbody, Thead } from '@chakra-ui/react';
import { IterationComponent } from '@components/functionComponent';

export const TableHeadSkeleton = ({ tableList }) => {
    return (
        <Thead>
            <Tr>
                <IterationComponent
                    arr={tableList}
                    render={() => (
                        <Th className="table-head__item ">
                            <Skeleton
                                h="20px"
                                startColor="var(--main-color)"
                                endColor={`rgba(var(--main-color),0.5)`}
                                borderRadius="4px"
                            />
                        </Th>
                    )}
                />
            </Tr>
        </Thead>
    );
};

export const TableColumnSkeleton = ({ tableList }) => {
    return (
        <Tbody>
            <IterationComponent
                arr={[1, 2, 3, 4, 5, 6, 7]}
                render={(item) => (
                    <Tr key={item} className="row-column__container">
                        <IterationComponent
                            arr={tableList}
                            render={(_, idx) => (
                                <Td className="table-column__item" key={idx}>
                                    <Skeleton
                                        h="24px"
                                        startColor="var(--main-color-light-active)"
                                        endColor={`rgba(var(--main-color-light-active),0.5)`}
                                        borderRadius="4px"
                                    />
                                </Td>
                            )}
                        />
                    </Tr>
                )}
            />
        </Tbody>

    );
};

export const TextFooterSkeleton = () => (
    <Flex h="40px" alignItems="center">
        <Skeleton
            h="20px"
            width={`110px`}
            startColor="var(--main-color)"
            endColor={`rgba(var(--main-color-dark),0.5)`}
            borderRadius="4px"
            mr="8px"
        />
        <Skeleton
            h="20px"
            width={`40px`}
            startColor="var(--main-color)"
            endColor={`rgba(var(--main-color-dark),0.5)`}
            borderRadius="4px"
        />
    </Flex>
);

export const PaginationSkeleton = () => (
    <Flex h="40px" alignItems="center">
        <IterationComponent
            arr={[1, 2, 3, 4, 5, 6, 7]}
            render={(item, idx) => (
                <Skeleton
                    key={item}
                    height="32px"
                    width="32px"
                    startColor={idx === 1 ? 'var(--main-color)' : 'var(--main-color-light-active)'}
                    endColor={
                        idx === 1 ? `rgba(var(--main-color-dark),0.5)` : `rgba(var(--main-color-light-active),0.5)`
                    }
                    m="0 4px"
                    borderRadius="50%"
                    box-shadow="0px 2px 15px 0px #0D0A2C14"
                />
            )}
        />
    </Flex>
);
