//========================== LIBRARY ===========================

import { useNavigate } from 'react-router-dom';
import { Box, Flex, Text } from '@chakra-ui/react';

//========================== GLOBAL ASSETS ===========================

import TableTemplate from '@components/tableTemplate';
import { ChevronRightIcon } from '@assets/icons';

//========================== LOCAL ASSETS ===========================

import { tableList } from './const';
import './styles.scss';

const History = ({ history, isLoading }) => {
    const navigate = useNavigate();

    return (
        <Box className="history__upload-sellout" id="history__upload-sellout">
            {/* //==================== HEAD =========================== */}

            <Flex className="head">
                <Text className="title">Riwayat</Text>
                <Flex className="button-see-all" onClick={() => navigate('/audit-trail')}>
                    <Text className="text">Lihat Semua</Text>
                    <ChevronRightIcon />
                </Flex>
            </Flex>
            {/* //==================== BODY =========================== */}

            <Flex className="body">
                <TableTemplate
                    tableList={tableList}
                    data={history}
                    dataCount={null}
                    totalData={null}
                    page={null}
                    isLoading={isLoading}
                    totalPage={null}
                    handlePageClick={null}
                    isPagination={false}
                    isDynamicTable={false}
                />
            </Flex>
        </Box>
    );
};

export default History;
