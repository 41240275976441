import { Fragment } from 'react';

import SnackbarNotification from './snackbarNotification';
import DownloadNotification from './downloadNotification';
import PopUp from './popUp';
import Loader from './loader';
import SpotLight from './spotlight';

const AssetsComponent = () => {
    return (
        <Fragment>
            <SnackbarNotification />
            <Loader />
            <DownloadNotification />
            <PopUp />
            <SpotLight />
        </Fragment>
    );
};

export default AssetsComponent;
