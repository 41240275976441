import { get, find } from 'lodash';
import moment from 'moment';

export const getDownloadTemplateEndpoint = ({ templateType }) => {
    const obj = {
        'default-template': '/api/v1/sellout/product/download-product-mapping',
        'empty-template': 'api/v1/sellout/product/download-example-product-mapping',
    };

    return obj[templateType];
};

//=========================== UPLOAD POPUP =========================

export const onFormatOption = ({ arr, selectedFile, optionType }) => {
    let result = [...arr];
    const isPdf = selectedFile?.name?.includes('pdf');

    result = result?.map((item) => {
        return {
            ...item,
            isActive: true,
        };
    });

    if (isPdf && optionType === 'upload-type') {
        result = arr?.map((item) => {
            return {
                ...item,
                isActive: item?.value === 'Smart Upload' && isPdf,
            };
        });
    }

    return result;
};

export const getTimeUploadDescription = ({ selected, options }) => {
    return get(find(options, { value: selected }), 'description', '');
};

export const getLeftIconPosition = ({ type }) => {
    const obj = {
        monthly: '10px',
        daily: '100px',
        multipledays: '190px',
        weekly: '320px',
    };

    return obj[type];
};

export const isActiveButtonHandler = ({ store }) => {
    const { selectedTimeUploadType, selectedTimeUploadValue, selectedBrand, isTermsChecked, selectedFormatUploadType } =
        store;

    return (
        isTermsChecked &&
        selectedBrand?.length &&
        selectedTimeUploadType &&
        selectedTimeUploadValue &&
        selectedFormatUploadType
    );
};

export const getUploadDateHandler = ({ type, value, category }) => {
    const obj = {
        daily: {
            start_date: moment(value[0])?.format('YYYY-MM-DD'),
            end_date: moment(value[1] || value[0])?.format('YYYY-MM-DD'),
        },
        monthly: {
            start_date: moment(value).startOf('month')?.format('YYYY-MM-DD'),
            end_date: moment(value).endOf('month')?.format('YYYY-MM-DD'),
        },
        multipledays: {
            start_date: moment(value[0])?.format('YYYY-MM-DD'),
            end_date: moment(value[1])?.format('YYYY-MM-DD'),
        },
        weekly: {
            start_date: moment(value[0])?.format('YYYY-MM-DD'),
            end_date: moment(value[1])?.format('YYYY-MM-DD'),
        },
    };

    return obj[type][category];
};

export const getUploadEndpoint = ({ type }) => {
    const obj = {
        'Format Paragon': `sellout/upload-sellout`,
        'Smart Upload': 'sellout/smart-upload',
        'Auto Format': 'sellout/translate-upload-sellout',
    };

    return obj[type];
};

export const getSelectedBrandName = ({ selectedBrand }) => {
    return selectedBrand?.map(({ name }) => `"${name}"`);
};
