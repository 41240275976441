import { create } from 'zustand';

const state = {
    trails: [],
    //==================== PAGINATION ================== //
    paginationInfo: {
        dataCount: 0,
        totalData: 0,
        totalPage: 0,
    },
    isLoadingTable: true,
    isLoadingHead: false,
};

export const useAuditTrailStore = create((set) => ({
    ...state,
    onChangeAuditTrailStore: (newState) => set((state) => ({ ...state, ...newState })),
    onResetAuditTrailStore: () => set(() => ({ ...state })),
}));

//========================================= FILTER =========================

const filterSelectedState = {
    selectedDC: [],
    selectedStore: [],
    selectedStatus: null,
    selectedStoreGroup: [],
    selectedUploadType: null,
    dateUploadSelected: null,
    dateSelloutSelected: null,
    searchQuery: '',
    page: 1,
    selectedSorting: 'DESC',
};

export const useAuditTrailFilterStore = create((set) => ({
    ...filterSelectedState,
    onChangeAuditTrailFilterSelectedStore: (newState) => set((state) => ({ ...state, ...newState })),
    onResetAuditTrailFilterSelectedStore: () => set(() => ({ ...filterSelectedState })),
}));

//========================================= DC =========================
const dcOptionState = {
    isLoadingDCOption: false,
    dcOptions: [],
    searchDCQuery: '',
};

export const useDCOptionStore = create((set) => ({
    ...dcOptionState,
    onChangeDCOptionStore: (newState) => set((state) => ({ ...state, ...newState })),
    onResetDCOptionStore: () => set(() => ({ ...dcOptionState })),
}));

//========================================= CUSTOMERS =========================

const customerOptionState = {
    isLoadingCustomerOption: false,
    customerOptions: [],
    searchCustomerQuery: '',
    pageCustomer: 1,
};

export const useCustomerOptionStore = create((set) => ({
    ...customerOptionState,
    onChangeCustomerOptionStore: (newState) => set((state) => ({ ...state, ...newState })),
    onResetCustomerOptionStore: () => set(() => ({ ...customerOptionState })),
}));

//========================================= STORE GROUP =========================
const storeGroupOptionState = {
    isLoadingStoreGroupOption: false,
    storeGroupOptions: [],
    searchStoreGroupQuery: '',
};

export const useGroupOptionStore = create((set) => ({
    ...storeGroupOptionState,
    onChangeStoreGroupOptionStore: (newState) => set((state) => ({ ...state, ...newState })),
    onResetStoreGroupOptionStore: () => set(() => ({ ...storeGroupOptionState })),
}));

//========================================= STATUS LIST =========================
const statusOptionState = {
    isLoadingStatusOption: false,
    statusOptions: [],
    searchStatusQuery: '',
};

export const useStatusOptionStore = create((set) => ({
    ...statusOptionState,
    onChangeStatusOptionStore: (newState) => set((state) => ({ ...state, ...newState })),
    onResetStatusOptionStore: () => set(() => ({ ...statusOptionState })),
}));

//========================================= UPLOAD TYPE LIST =========================
const uploadTypeOptions = {
    isLoadingUploadTypeOption: false,
    uploadTypeOptions: [],
    searchUploadTypeQuery: '',
};

export const useUploadTypeOptionStore = create((set) => ({
    ...uploadTypeOptions,
    onChangeUploadTypeOptionStore: (newState) => set((state) => ({ ...state, ...newState })),
    onResetUploadTypeOptionStore: () => set(() => ({ ...uploadTypeOptions })),
}));
