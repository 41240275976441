import { create } from 'zustand';

const state = {
    storeConfigs: [],
    isOpenPopUpUpload: false,

    //==================== PAGINATION ================== //

    paginationInfo: {
        dataCount: 0,
        totalData: 0,
        totalPage: 0,
    },
    isLoadingTable: true,
};
export const useStoreConfigStore = create((set) => ({
    ...state,
    onChangeStoreConfigStore: (newState) => set((state) => ({ ...state, ...newState })),
    onResetStoreConfigStore: () => set(() => ({ ...state })),
}));

//========================================= FILTER =========================

const filterSelectedState = {
    selectedCustomer: [],
    selectedSalesArea: [],
    selectedDC: [],
    storeConfigStatus: '',
    storeConfigConsignment: null,
    page: 1,
    storeConfigSystem: [
        {
            id: 1,
            name: 'iSeller',
        },
    ],
};

export const useSelectedFilterStore = create((set) => ({
    ...filterSelectedState,
    onChangeFilterSelectedStore: (newState) => set((state) => ({ ...state, ...newState })),
    onResetFilterSelectedStore: () => set(() => ({ ...filterSelectedState })),
}));

//========================================= DC =========================
const dcOptionState = {
    isLoadingDCOption: false,
    dcOptions: [],
    searchDCQuery: '',
};
export const useFilterDCStore = create((set) => ({
    ...dcOptionState,
    onChangeFilterDCOptionStore: (newState) => set((state) => ({ ...state, ...newState })),
    onResetFilterDCOptionStore: () => set(() => ({ ...dcOptionState })),
}));

//========================================= SALESAREA =========================
const salesAreaOptionState = {
    isLoadingSalesAreaOption: false,
    salesAreaOptions: [],
    searchSalesAreaQuery: '',
};
export const useFilterSalesAreaStore = create((set) => ({
    ...salesAreaOptionState,
    onChangeFilterSalesAreaOptionStore: (newState) => set((state) => ({ ...state, ...newState })),
    onResetFilterSalesAreaOptionStore: () => set(() => ({ ...salesAreaOptionState })),
}));

//========================================= CUSTOMER =========================
const customerOptionState = {
    isLoadingCustomerOption: false,
    customerOptions: [],
    searchCustomerQuery: '',
};
export const useFilterCustomerStore = create((set) => ({
    ...customerOptionState,
    onChangeFilterCustomerOptionStore: (newState) => set((state) => ({ ...state, ...newState })),
    onResetFilterCustomerOptionStore: () => set(() => ({ ...customerOptionState })),
}));

//========================================= SYSTEM =========================
const systemOptionState = {
    isLoadingSystemOption: false,
    systemOptions: [],
};
export const useFilterSystemStore = create((set) => ({
    ...systemOptionState,
    onChangeFilterSystemOptionStore: (newState) => set((state) => ({ ...state, ...newState })),
    onResetFilterSystemOptionStore: () => set(() => ({ ...systemOptionState })),
}));

//========================================= CONFIG DETAIL =========================

const configDetailState = {
    selectedTemplate: null,
    storeInfo: {},
    assets: {},
    isLoading: true,
};
export const useConfigDetail = create((set) => ({
    ...configDetailState,
    onChangeConfigDetail: (newState) => set((state) => ({ ...state, ...newState })),
    onResetConfigDetail: () => set(() => ({ ...configDetailState })),
}));

const configDetailAssetState = {
    systemList: [],
    templateList: [],
};
export const useConfigAssetDetail = create((set) => ({
    ...configDetailAssetState,
    onChangeConfigAssetDetail: (newState) => set((state) => ({ ...state, ...newState })),
    onResetConfigAssetDetail: () => set(() => ({ ...configDetailAssetState })),
}));
