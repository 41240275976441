export const ChevronDownIcon = ({ color = 'var(--main-color)' }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18 9L12 15L6 9" stroke={color} strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

export const ChevronDownThickIcon = ({ color = '#2D7876', className }) => (
    <svg
        className={className}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M18 9L12 15L6 9" stroke={color} strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

export const ChevronLeftIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M10.4141 11.9998L14.0001 15.5858C14.3911 15.9768 14.3911 16.6098 14.0001 16.9998C13.6091 17.3908 12.9761 17.3908 12.5861 16.9998L9.00006 13.4138C8.21906 12.6328 8.21906 11.3668 9.00006 10.5858L12.5861 6.99979C12.9761 6.60879 13.6091 6.60879 14.0001 6.99979C14.3911 7.39079 14.3911 8.02379 14.0001 8.41379L10.4141 11.9998Z"
            fill="#6B6B6B"
        />
    </svg>
);

export const ChevronRightIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M13.5859 11.9998L9.99991 15.5858C9.60991 15.9758 9.60991 16.6088 9.99991 16.9998C10.3899 17.3908 11.0239 17.3908 11.4139 16.9998L14.9999 13.4138C15.7809 12.6328 15.7809 11.3668 14.9999 10.5858L11.4149 6.99979C11.0239 6.60879 10.3909 6.60879 9.99991 6.99979C9.60891 7.39079 9.60991 8.02379 9.99991 8.41379L13.5859 11.9998Z"
            fill="#686868"
        />
    </svg>
);

export const ResetIcon = ({ color, size = 24, onClick }) => (
    <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={onClick}
    >
        <path d="M14 15L10 19L14 23" stroke={color} strokeWidth="2" />
        <path
            d="M5.93782 15.5C5.16735 14.1655 4.85875 12.6141 5.05989 11.0863C5.26102 9.55856 5.96064 8.13986 7.05025 7.05025C8.13986 5.96064 9.55856 5.26102 11.0863 5.05989C12.6141 4.85875 14.1655 5.16735 15.5 5.93782C16.8345 6.70829 17.8775 7.89757 18.4672 9.32122C19.0568 10.7449 19.1603 12.3233 18.7615 13.8117C18.3627 15.3002 17.4838 16.6154 16.2613 17.5535C15.0388 18.4915 13.5409 19 12 19"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
        />
    </svg>
);

export const CloseIcon = ({ color, onClick }) => (
    <svg onClick={onClick} width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18 6L6 18" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6 6L18 18" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

export const SortIcon = ({ color, size }) => (
    <svg width={size || '24'} height={size || '24'} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M8 20L7.29289 20.7071L8 21.4142L8.70711 20.7071L8 20ZM14 5C14.5523 5 15 4.55228 15 4C15 3.44772 14.5523 3 14 3L14 5ZM3.29289 16.7071L7.29289 20.7071L8.70711 19.2929L4.70711 15.2929L3.29289 16.7071ZM8.70711 20.7071L12.7071 16.7071L11.2929 15.2929L7.29289 19.2929L8.70711 20.7071ZM9 20L9 8L7 8L7 20L9 20ZM12 5L14 5L14 3L12 3L12 5ZM9 8C9 6.34315 10.3431 5 12 5L12 3C9.23858 3 7 5.23858 7 8L9 8Z"
            fill={color}
        />
        <path
            d="M16 10L15.2929 9.29289L16 8.58579L16.7071 9.29289L16 10ZM16 18L17 18L16 18ZM14 21C13.4477 21 13 20.5523 13 20C13 19.4477 13.4477 19 14 19L14 21ZM11.2929 13.2929L15.2929 9.29289L16.7071 10.7071L12.7071 14.7071L11.2929 13.2929ZM16.7071 9.29289L20.7071 13.2929L19.2929 14.7071L15.2929 10.7071L16.7071 9.29289ZM17 10L17 18L15 18L15 10L17 10ZM17 18C17 19.6569 15.6569 21 14 21L14 19C14.5523 19 15 18.5523 15 18L17 18Z"
            fill={color}
        />
    </svg>
);

export const FilterIcon = ({ color }) => (
    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5 2C4.44772 2 4 2.44772 4 3V5.00001H20V3C20 2.44772 19.5523 2 19 2H5ZM19.7822 7.00001H4.21776C4.3321 7.22455 4.48907 7.42794 4.68299 7.59762L10.683 12.8476C11.437 13.5074 12.563 13.5074 13.317 12.8476L19.317 7.59762C19.5109 7.42794 19.6679 7.22455 19.7822 7.00001Z"
            fill={color}
        />
        <path fillRule="evenodd" clipRule="evenodd" d="M14 16.7049L14 10H10V18.7049L14 16.7049Z" fill={color} />
    </svg>
);

export const CalendarIcon = ({ color }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M3 10C3 8.11438 3 7.17157 3.58579 6.58579C4.17157 6 5.11438 6 7 6H17C18.8856 6 19.8284 6 20.4142 6.58579C21 7.17157 21 8.11438 21 10H3Z"
            fill="#7E869E"
            fillOpacity="0.25"
        />
        <rect x="3" y="6" width="18" height="15" rx="2" stroke={color} strokeWidth="1.2" />
        <path d="M7 3L7 6" stroke={color} strokeWidth="1.2" strokeLinecap="round" />
        <path d="M17 3L17 6" stroke={color} strokeWidth="1.2" strokeLinecap="round" />
        <rect x="7" y="12" width="4" height="2" rx="0.5" fill={color} />
        <rect x="7" y="16" width="4" height="2" rx="0.5" fill={color} />
        <rect x="13" y="12" width="4" height="2" rx="0.5" fill={color} />
        <rect x="13" y="16" width="4" height="2" rx="0.5" fill={color} />
    </svg>
);

export const IconAdditional = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none" viewBox="0 0 32 32">
        <path
            fill="#2D2D2D"
            d="M14.4 24.4a2 2 0 114 0 2 2 0 01-4 0zm0-8a2 2 0 114 0 2 2 0 01-4 0zm0-8a2 2 0 114 0 2 2 0 01-4 0z"
        ></path>
    </svg>
);

export const OpenEyeIcon = ({ onClick, color = '#4D4D4D' }) => (
    <svg
        className="eye-icon"
        onClick={onClick}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M10 7.5C9.33831 7.50437 8.70496 7.76916 8.23706 8.23706C7.76916 8.70496 7.50437 9.33831 7.5 10C7.5 11.3683 8.63167 12.5 10 12.5C11.3675 12.5 12.5 11.3683 12.5 10C12.5 8.6325 11.3675 7.5 10 7.5Z"
            fill={color}
        />
        <path
            d="M10 4.16669C3.6392 4.16669 1.72754 9.68085 1.71004 9.73669L1.6217 10L1.7092 10.2634C1.72754 10.3192 3.6392 15.8334 10 15.8334C16.3609 15.8334 18.2725 10.3192 18.29 10.2634L18.3784 10L18.2909 9.73669C18.2725 9.68085 16.3609 4.16669 10 4.16669ZM10 14.1667C5.54087 14.1667 3.81337 10.9617 3.39504 10C3.81504 9.03502 5.54337 5.83335 10 5.83335C14.4592 5.83335 16.1867 9.03835 16.605 10C16.185 10.965 14.4567 14.1667 10 14.1667Z"
            fill={color}
        />
    </svg>
);

export const CloseEyeIcon = ({ onClick }) => (
    <svg
        className="eye-icon"
        onClick={onClick}
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.9202 12.7988C15.9725 12.5407 16 12.2736 16 12C16 9.79086 14.2091 8 12 8C11.7264 8 11.4593 8.02746 11.2012 8.07977L15.9202 12.7988ZM8.66676 9.78799C8.24547 10.4216 8 11.1821 8 12C8 14.2091 9.79086 16 12 16C12.8179 16 13.5784 15.7545 14.212 15.3332L12.7381 13.8594C12.5098 13.9501 12.2607 14 12 14C10.8954 14 10 13.1046 10 12C10 11.7393 10.0499 11.4902 10.1406 11.2619L8.66676 9.78799Z"
            fill="#4D4D4D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.5189 17.6405L15.0496 16.1712C14.0774 16.6805 13.0475 17 11.9998 17C10.474 17 8.98592 16.3224 7.6589 15.3677C6.33978 14.4186 5.26384 13.2558 4.58362 12.43C4.48385 12.3088 4.40934 12.2182 4.34761 12.1385C4.29874 12.0754 4.26762 12.0315 4.24731 12C4.26762 11.9685 4.29874 11.9246 4.34761 11.8615C4.40934 11.7818 4.48385 11.6912 4.58362 11.57C5.24903 10.7622 6.2931 9.63187 7.57307 8.69463L6.14434 7.2659C4.79618 8.29616 3.72222 9.47005 3.03985 10.2985C3.01626 10.3272 2.99168 10.3566 2.96638 10.3869L2.96636 10.3869C2.65294 10.7624 2.22949 11.2696 2.22949 12C2.22949 12.7304 2.65294 13.2376 2.96636 13.6131L2.96658 13.6133C2.99181 13.6435 3.01633 13.6729 3.03985 13.7015C3.77972 14.5998 4.97993 15.9041 6.49087 16.9912C7.99391 18.0725 9.88983 19 11.9998 19C13.6698 19 15.2057 18.419 16.5189 17.6405ZM8.80658 5.6855C9.79037 5.26871 10.8641 5 11.9998 5C14.1097 5 16.0056 5.92747 17.5087 7.00885C19.0196 8.0959 20.2198 9.40025 20.9597 10.2985C20.9833 10.3272 21.0078 10.3566 21.0331 10.3869L21.0332 10.3869C21.3466 10.7624 21.77 11.2696 21.77 12C21.77 12.7304 21.3466 13.2376 21.0332 13.6131C21.0079 13.6434 20.9833 13.6728 20.9597 13.7015C20.473 14.2923 19.7872 15.0589 18.9448 15.8237L17.5287 14.4077C18.3086 13.708 18.9536 12.9912 19.4159 12.43C19.5157 12.3088 19.5902 12.2182 19.6519 12.1385C19.7008 12.0754 19.7319 12.0315 19.7522 12C19.7319 11.9685 19.7008 11.9246 19.6519 11.8615C19.5902 11.7818 19.5157 11.6912 19.4159 11.57C18.7357 10.7442 17.6598 9.58138 16.3406 8.63233C15.0136 7.6776 13.5256 7 11.9998 7C11.4488 7 10.9027 7.08837 10.3673 7.24624L8.80658 5.6855Z"
            fill="#4D4D4D"
        />
        <path d="M5 2L21 18" stroke="#4D4D4D" strokeWidth="2" />
    </svg>
);

export const SettingIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <ellipse
            cx="7"
            cy="7"
            rx="3"
            ry="3"
            transform="rotate(90 7 7)"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
        />
        <path
            d="M9.5 5H18C19.1046 5 20 5.89543 20 7V7C20 8.10457 19.1046 9 18 9H9.5"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
        />
        <ellipse
            cx="3"
            cy="3"
            rx="3"
            ry="3"
            transform="matrix(4.37114e-08 1 1 -4.37114e-08 14 14)"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
        />
        <path
            d="M14.5 15H6C4.89543 15 4 15.8954 4 17V17C4 18.1046 4.89543 19 6 19H14.5"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
        />
    </svg>
);
export const MenuIcon = ({ color = '#CCD2E3' }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5 7H19" stroke={color} strokeWidth="2" strokeLinecap="round" />
        <path d="M5 12H19" stroke={color} strokeWidth="2" strokeLinecap="round" />
        <path d="M5 17H19" stroke={color} strokeWidth="2" strokeLinecap="round" />
    </svg>
);

export const DeleteIcon = () => (
    <svg width="28" height="28" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10 15L10 12" stroke="#f27373" strokeWidth="1.2" strokeLinecap="round" />
        <path d="M14 15L14 12" stroke="#f27373" strokeWidth="1.2" strokeLinecap="round" />
        <path
            d="M3 7H21C20.0681 7 19.6022 7 19.2346 7.15224C18.7446 7.35523 18.3552 7.74458 18.1522 8.23463C18 8.60218 18 9.06812 18 10V16C18 17.8856 18 18.8284 17.4142 19.4142C16.8284 20 15.8856 20 14 20H10C8.11438 20 7.17157 20 6.58579 19.4142C6 18.8284 6 17.8856 6 16V10C6 9.06812 6 8.60218 5.84776 8.23463C5.64477 7.74458 5.25542 7.35523 4.76537 7.15224C4.39782 7 3.93188 7 3 7Z"
            fill="#f27373"
            fillOpacity="0.25"
            stroke="#f27373 "
            strokeWidth="1.2"
            strokeLinecap="round"
        />
        <path
            d="M10.0681 3.37059C10.1821 3.26427 10.4332 3.17033 10.7825 3.10332C11.1318 3.03632 11.5597 3 12 3C12.4403 3 12.8682 3.03632 13.2175 3.10332C13.5668 3.17033 13.8179 3.26427 13.9319 3.37059"
            stroke="#f27373"
            strokeWidth="1.2"
            strokeLinecap="round"
        />
    </svg>
);

export const EditIcon = () => (
    <svg width="28" height="28" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12.5 7.5L5.92819 14.0718C5.71566 14.2843 5.60939 14.3906 5.53953 14.5212C5.46966 14.6517 5.44019 14.7991 5.38124 15.0938L4.64709 18.7646C4.58057 19.0972 4.5473 19.2635 4.64191 19.3581C4.73652 19.4527 4.90283 19.4194 5.23544 19.3529L8.90621 18.6188C9.20093 18.5598 9.3483 18.5303 9.47885 18.4605C9.60939 18.3906 9.71566 18.2843 9.92819 18.0718L16.5 11.5L12.5 7.5Z"
            fill="#005b99"
            fillOpacity="0.25"
        />
        <path
            d="M5.95396 19.38L5.95397 19.38L5.9801 19.3734L5.98012 19.3734L8.60809 18.7164C8.62428 18.7124 8.64043 18.7084 8.65654 18.7044C8.87531 18.65 9.08562 18.5978 9.27707 18.4894C9.46852 18.381 9.62153 18.2275 9.7807 18.0679C9.79242 18.0561 9.80418 18.0444 9.81598 18.0325L17.0101 10.8385L17.0101 10.8385L17.0369 10.8117C17.3472 10.5014 17.6215 10.2272 17.8128 9.97638C18.0202 9.70457 18.1858 9.39104 18.1858 9C18.1858 8.60896 18.0202 8.29543 17.8128 8.02361C17.6215 7.77285 17.3472 7.49863 17.0369 7.18835L17.01 7.16152L16.8385 6.98995L16.8117 6.96314C16.5014 6.6528 16.2272 6.37853 15.9764 6.1872C15.7046 5.97981 15.391 5.81421 15 5.81421C14.609 5.81421 14.2954 5.97981 14.0236 6.1872C13.7729 6.37853 13.4986 6.65278 13.1884 6.96311L13.1615 6.98995L5.96745 14.184C5.95565 14.1958 5.94386 14.2076 5.93211 14.2193C5.77249 14.3785 5.61904 14.5315 5.51064 14.7229C5.40225 14.9144 5.34999 15.1247 5.29562 15.3435C5.29162 15.3596 5.28761 15.3757 5.28356 15.3919L4.62003 18.046C4.61762 18.0557 4.61518 18.0654 4.61272 18.0752C4.57411 18.2293 4.53044 18.4035 4.51593 18.5518C4.49978 18.7169 4.50127 19.0162 4.74255 19.2574C4.98383 19.4987 5.28307 19.5002 5.44819 19.4841C5.59646 19.4696 5.77072 19.4259 5.92479 19.3873C5.9346 19.3848 5.94433 19.3824 5.95396 19.38Z"
            stroke="#004473"
            strokeWidth="1.2"
        />
        <path d="M12.5 7.5L16.5 11.5" stroke="#004473" strokeWidth="1.2" />
    </svg>
);

export const DottedIcon = () => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M16 10C17.1046 10 18 9.10457 18 8C18 6.89543 17.1046 6 16 6C14.8954 6 14 6.89543 14 8C14 9.10457 14.8954 10 16 10Z"
            fill="#4D4D4D"
        />
        <path
            d="M16 18C17.1046 18 18 17.1046 18 16C18 14.8954 17.1046 14 16 14C14.8954 14 14 14.8954 14 16C14 17.1046 14.8954 18 16 18Z"
            fill="#4D4D4D"
        />
        <path
            d="M16 25.3333C17.1046 25.3333 18 24.4379 18 23.3333C18 22.2288 17.1046 21.3333 16 21.3333C14.8954 21.3333 14 22.2288 14 23.3333C14 24.4379 14.8954 25.3333 16 25.3333Z"
            fill="#4D4D4D"
        />
    </svg>
);

export const UserIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M7.91667 8.33333C9.5275 8.33333 10.8333 7.0275 10.8333 5.41667C10.8333 3.80584 9.5275 2.5 7.91667 2.5C6.30583 2.5 5 3.80584 5 5.41667C5 7.0275 6.30583 8.33333 7.91667 8.33333Z"
            fill="white"
            stroke="white"
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M1.66666 17V17.5H14.1667V17C14.1667 15.1332 14.1667 14.1997 13.8034 13.4867C13.4838 12.8595 12.9738 12.3495 12.3466 12.0299C11.6336 11.6667 10.7002 11.6667 8.83332 11.6667H6.99999C5.13316 11.6667 4.19974 11.6667 3.48669 12.0299C2.85948 12.3495 2.34954 12.8595 2.02997 13.4867C1.66666 14.1997 1.66666 15.1332 1.66666 17Z"
            fill="white"
            stroke="white"
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M15.8333 5.41666V10.4167M13.3333 7.91666H18.3333"
            stroke="white"
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const UploadIcon = ({ color }) => (
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M25 20.8334V39.5834M25 20.8334L31.25 27.0834M25 20.8334L18.75 27.0834M36.4583 31.25C39.6229 31.25 41.6667 28.6854 41.6667 25.5209C41.6666 24.268 41.2558 23.0497 40.4972 22.0525C39.7386 21.0554 38.6741 20.3344 37.4667 20C37.2809 17.6635 36.3125 15.4579 34.7181 13.7398C33.1237 12.0217 30.9964 10.8916 28.6802 10.5322C26.364 10.1727 23.9943 10.6049 21.9542 11.759C19.914 12.913 18.3227 14.7214 17.4375 16.8917C15.5738 16.3751 13.5812 16.62 11.8981 17.5725C10.2149 18.525 8.97913 20.1072 8.46251 21.9709C7.94589 23.8346 8.19078 25.8272 9.14331 27.5103C10.0958 29.1934 11.678 30.4292 13.5417 30.9459"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
