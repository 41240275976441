// import { MainDashboardIcon } from '@assets/icons';
import Documentation from '@pages/Documentation';
import Login from '@pages/Login';
import UserManagement from '@pages/UserManagement';
import AddEditUserManagment from '@pages/UserManagement/components/addEdit';
import UploadUserManagement from '@pages/UserManagement/components/upload';
import AuditTrail from '@pages/AuditTrail';
import UploadMapping from '@pages/UploadMapping';
import UploadSellout from '@pages/UploadSellout';
import SiSo from '@pages/SiSo';
import StoreConfig from '@pages/StoreConfig';
import StoreConfigUpload from '@pages/StoreConfig/components/upload';
import ForbiddenPage from '@pages/Forbidden';
import StockTrail from '@pages/StockTrail';
import ResetPassword from '@pages/ResetPassword';
import ErrorPage from '@pages/ErrorPage';
import StoreConfigDetail from '@pages/StoreConfig/sections/detail';
import Profile from '@pages/Profile';
import Achievement from '@pages/Achievement';
import { AuditIcon, DashboardIcon, OrderIcon, StoreIcon, UploadIcon, UserManagementIcon } from './icons';

export const routeListUnauth = [
    {
        path: '/login',
        element: <Login />,
        name: 'Login',
        showInSideBar: false,
    },
    {
        path: '/error-page',
        element: <ErrorPage />,
        type: '*',
        name: 'Error Page',
        showInSideBar: false,
    },
];

export const routeList = [
    {
        path: '/upload-sellout',
        icon: <UploadIcon />,
        name: 'Upload',
        showInSideBar: true,
        id: 'upload_data',
        children: [
            {
                path: '/upload-sellout',
                id: 'upload_data',
                name: 'Sellout',
                element: <UploadSellout />,
            },
            {
                path: '/upload-mapping',
                id: 'upload_data',
                name: 'Mapping',
                element: <UploadMapping />,
            },
        ],
    },
    {
        path: '/order-management/create',
        icon: <OrderIcon />,
        name: 'Order Management',
        showInSideBar: true,
        children: [
            {
                path: '/order-management/create',
                element: <ForbiddenPage />,
                name: 'Create',
            },
            {
                path: '/order-management/active-order',
                element: <ForbiddenPage />,
                name: 'Active Order',
            },
            {
                path: '/order-management/history',
                element: <ForbiddenPage />,
                name: 'History',
            },
        ],
    },
    {
        path: '/audit-trail',
        icon: <AuditIcon />,
        name: 'Audit Trail',
        id: 'audit_trail',
        showInSideBar: true,
        children: [
            {
                path: '/audit-trail',
                id: 'audit_trail',
                showInSideBar: true,
                name: 'Sellout',
                element: <AuditTrail />,
            },
            {
                path: '/stock-trail',
                id: 'audit_trail',
                element: <StockTrail />,
                name: 'Stock',
            },
            // {
            //     path: '/request',
            //     id: 'audit_trail',
            //     element: <ForbiddenPage />,
            //     name: 'Request  ',
            // },
        ],
    },
    {
        path: '/dashboard',
        icon: <DashboardIcon />,
        name: 'Dashboard',
        id: 'dashboard',
        showInSideBar: true,
        children: [
            {
                path: '/dashboard',
                id: 'dashboard',
                element: <Achievement />,
                name: 'Pencapaian',
            },
            // {
            //     path: '/siso',
            //     id: 'dashboard',
            //     name: 'SiSo',
            //     element: <SiSo />,
            // },
            // {
            //     path: '/product-performance',
            //     id: 'dashboard',
            //     element: <ForbiddenPage />,
            //     name: 'Product Performance  ',
            // },
        ],
    },
    {
        path: '/store-config/iseller',
        icon: <StoreIcon />,
        id: 'configuration_store',
        name: 'Konfigurasi Toko',
        showInSideBar: true,
        children: [
            {
                path: '/store-config/iseller',
                name: 'Integrasi iSeller',
                element: <StoreConfig />,
                id: 'configuration_store',
            },
            // {
            //     path: '/store-config/sagita',
            //     id: 'configuration_store',
            //     element: <ForbiddenPage />,
            //     name: 'Integrasi Sagita  ',
            // },
            // {
            //     path: '/store-config/detail',
            //     id: 'configuration_store',
            //     element: <ForbiddenPage />,
            //     name: 'Detail Toko',
            // },
        ],
    },
    {
        path: '/store-config/iseller/:id',
        id: 'configuration_store',
        name: 'Store Config',
        element: <StoreConfigDetail />,
        showInSideBar: false,
    },
    {
        path: '/profile',
        id: 'profile',
        name: 'Profile',
        element: <Profile />,
        type: '*',
        showInSideBar: false,
    },

    {
        path: '/store-config/upload',
        id: 'configuration_store',
        name: 'Store Config',
        element: <StoreConfigUpload />,
        showInSideBar: false,
    },
    {
        path: '/user-management',
        icon: <UserManagementIcon />,
        name: 'User Management',
        element: <UserManagement />,
        id: 'user_management',
        showInSideBar: true,
    },
    {
        path: '/user-management/create',
        id: 'user_management',
        name: 'User Management',
        element: <AddEditUserManagment />,
        showInSideBar: false,
    },

    {
        path: '/user-management/edit/:id/:uniqueEmail',
        id: 'user_management',
        name: 'User Management',
        element: <AddEditUserManagment />,
        showInSideBar: false,
    },
    {
        path: '/user-management/upload',
        id: 'user_management',
        name: 'User Management',
        element: <UploadUserManagement />,
        showInSideBar: false,
    },

    {
        path: '/documentation',
        element: <Documentation />,
        type: '*',
        name: 'Documentation',
        showInSideBar: false,
    },
    {
        path: '/reset-password',
        element: <ResetPassword />,
        type: '*',
        name: 'Reset password',
        showInSideBar: false,
    },
    {
        path: '/error-page',
        element: <ErrorPage />,
        type: '*',
        name: 'Error Page',
        showInSideBar: false,
    },
    {
        path: '*',
        type: '*',
        element: <ForbiddenPage />,
        showInSideBar: false,
    },
];

const routeListHandler = () => {
    const arr = [];
    const routeListTemp = [...routeList];

    //================ PUSH CHILDREN TO ROUTES ===========================

    routeListTemp?.forEach((route) => {
        route.children ? route.children.forEach((item) => arr.push(item)) : arr.push(route);
    });

    return arr;
};

const routes = routeListHandler();

export default routes;
