import Cookies from 'universal-cookie';
import Axios from '@services/axios';
import { onRemoveAllCookies, redirectToPage } from '@helpers/events';

export const onRefreshPage = ({ navigate }) => {
    const cookies = new Cookies();
    const menuAccess = cookies.get('menuAccess');

    redirectToPage({ menuAccess, navigate })
};


export const onLogout = ({ navigate }) => {
    onRemoveAllCookies();
    navigate('/login');
};