import Cookies from 'universal-cookie';
import queryString from 'query-string';
import { saveAs } from 'file-saver';

import Axios from '@services/axios';
import moment from 'moment';

export const fetchStockTrail = async ({
    onChangeStockTrailStore,
    page,
    selectedStoreGroup,
    selectedStore,
    selectedDC,
    selectedStatus,
    selectedUploadType,
    selectedSorting,
    dateUploadSelected,
}) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    onChangeStockTrailStore({ isLoadingTable: true, users: [] });

    const nama_dc = selectedDC?.length ? `[${selectedDC?.map((item) => item?.name)}]` : [];
    const partner_ref = selectedStore?.length ? `[${selectedStore?.map((item) => item?.id)}]` : [];
    const store_group = selectedStoreGroup?.length ? `[${selectedStoreGroup?.map((item) => item?.name)}]` : [];
    const status = selectedStatus?.length ? selectedStatus[0]?.name : '';
    const upload_type = selectedUploadType?.length ? selectedUploadType[0]?.name : '';
    const date_uploaded = dateUploadSelected ? moment(dateUploadSelected).format('YYYY-MM-DD') : '';

    const filterStringify = queryString.stringify(
        {
            page: page,
            limit: 7,
            partner_ref,
            nama_dc,
            store_group,
            sortdate: selectedSorting,
            date_uploaded,
            status,
            upload_type,
        },
        { skipEmptyString: true, arrayFormat: 'separator' }
    );

    try {
        const { data } = await Axios({
            url: `/api/v1/audit-trail/stock-info?${filterStringify}`,
            method: 'POST',
            headers: { Authorization: 'Bearer ' + userToken },
        });

        const { total_data, page_data, total_page } = data?.data;

        onChangeStockTrailStore({
            trails: page_data,
            isLoadingTable: false,
            paginationInfo: {
                dataCount: page_data?.length,
                totalData: total_data,
                totalPage: total_page,
            },
        });
    } catch (error) {
        console.log(error);
        onChangeStockTrailStore({ trails: [], isLoadingTable: false });
    }
};

export const fetchDC = async ({ onChangeFilterDCOptionStore = () => null, searchQuery }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    onChangeFilterDCOptionStore({ isLoadingDCOption: true, dcOptions: [] });

    const filterStringify = queryString.stringify(
        {
            page: 1,
            limit: 1000,
            searchparam: searchQuery ? `dc_name.${searchQuery}` : '',
        },
        { skipEmptyString: true, arrayFormat: 'separator' }
    );

    try {
        const { data } = await Axios({
            url: `/api/v1/dc/list?${filterStringify}`,
            method: 'GET',
            headers: { Authorization: 'Bearer ' + userToken },
        });

        let result = data?.data?.page_data;

        result = result?.map((item) => {
            return {
                ...item,
                name: item?.dc_name,
            };
        });

        onChangeFilterDCOptionStore({ dcOptions: result, isLoadingDCOption: false });

        return result;
    } catch (error) {
        console.log(error);
        onChangeFilterDCOptionStore({ isLoadingDCOption: false, dcOptions: [] });
    }
};

export const fetchCustomer = async ({ onChangeFilterCustomerOptionStore = () => null, searchQuery }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    onChangeFilterCustomerOptionStore({ isLoadingCustomerOption: true, customerOptions: [] });

    const filterStringify = queryString.stringify(
        {
            page: 1,
            limit: 30,
            searchparam: searchQuery ? `nama_outlet.${searchQuery},customer_id.${searchQuery}` : '',
            dc: `[]`,
        },
        { skipEmptyString: true, arrayFormat: 'separator' }
    );

    try {
        const { data } = await Axios({
            url: `/api/v1/user/customer-dc?${filterStringify}`,
            method: 'GET',
            headers: { Authorization: 'Bearer ' + userToken },
        });

        let result = data?.data?.page_data;

        result = result?.map((item) => {
            return {
                store_name: item?.nama_outlet,
                name: `${item?.nama_outlet} (${item?.customer_id})`,
                id: item?.customer_id,
            };
        });

        onChangeFilterCustomerOptionStore({ customerOptions: result, isLoadingCustomerOption: false });

        return result;
    } catch (error) {
        console.log(error);
        onChangeFilterCustomerOptionStore({ isLoadingCustomerOption: false, customerOptions: [] });
    }
};

export const fetchStoreGroup = async ({ onChangeFilterStoreGroupOptionStore = () => null, searchQuery }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    onChangeFilterStoreGroupOptionStore({ isLoadingStoreGroupOption: true, storeGroupOptions: [] });

    const filterStringify = queryString.stringify(
        {
            page: 1,
            limit: 30,
            searchparam: `group.${searchQuery}`,
        },
        { skipEmptyString: true, arrayFormat: 'separator' }
    );

    try {
        const { data } = await Axios({
            url: `/api/v1/group-store/list?${filterStringify}`,
            method: 'GET',
            headers: { Authorization: 'Bearer ' + userToken },
        });

        let result = data?.data?.page_data;

        result = result?.map((item) => {
            return {
                name: item?.group,
                id: item?.group,
            };
        });

        onChangeFilterStoreGroupOptionStore({ storeGroupOptions: result, isLoadingStoreGroupOption: false });

        return result;
    } catch (error) {
        console.log(error);
        onChangeFilterStoreGroupOptionStore({ isLoadingStoreGroupOption: false, storeGroupOptions: [] });
    }
};

export const fetchStatus = async ({ onChangeFilterStatusOptionStore = () => null, searchQuery }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    onChangeFilterStatusOptionStore({ isLoadingStatusOption: true, statusOptions: [] });

    try {
        const { data } = await Axios({
            url: `/api/v1/status-upload/list`,
            method: 'GET',
            headers: { Authorization: 'Bearer ' + userToken },
        });

        let result = data?.data?.page_data;

        result = result?.map((item) => {
            return {
                ...item,
                name: item?.name,
                id: item?.name,
            };
        });

        onChangeFilterStatusOptionStore({ statusOptions: result, isLoadingStatusOption: false });

        return result;
    } catch (error) {
        console.log(error);
        onChangeFilterStatusOptionStore({ isLoadingStatusOption: false, statusOptions: [] });
    }
};

export const fetchUploadType = async ({ onChangeUploadTypeOptionStore = () => null, searchQuery }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    onChangeUploadTypeOptionStore({ isLoadingUploadTypeOption: true, uploadTypeOptions: [] });

    try {
        const { data } = await Axios({
            url: `/api/v1/upload-type/list`,
            method: 'GET',
            headers: { Authorization: 'Bearer ' + userToken },
        });

        let result = data?.data?.page_data;

        result = result?.map((item) => {
            return {
                ...item,
                name: item?.name,
                id: item?.name,
            };
        });

        onChangeUploadTypeOptionStore({ uploadTypeOptions: result, isLoadingUploadTypeOption: false });

        return result;
    } catch (error) {
        console.log(error);
        onChangeUploadTypeOptionStore({ isLoadingUploadTypeOption: false, uploadTypeOptions: [] });
    }
};

//======================================= ACTIONS ===============================

export const onDownloadFile = async ({ path, type, status, onChangeDownloadNotification }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');
    const fileName = `stock trail ${type}`;

    //======== DISABLED CLICK WHEN DOWNLOADING FILE ================

    if (status === 'download') return;

    onChangeDownloadNotification({
        status: 'download',
        title: 'Downloading',
        description: `Harap tunggu, sedang mendownload "${fileName}"`,
    });

    path = path?.replace('/sellout', '');

    try {
        const { data } = await Axios({
            method: 'POST',
            url: '/api/download-file',
            data: {
                path,
            },
            responseType: 'blob',
            headers: {
                Authorization: `Bearer ${userToken}`,
            },
        });

        const blob = new Blob([data], { type: 'text/xlsx' });
        saveAs(blob, `${fileName}.xlsx`);

        onChangeDownloadNotification({
            status: 'success',
            title: 'Success',
            description: `Download "${fileName}.xlsx" berhasil`,
        });
    } catch (error) {
        console.log(error);

        onChangeDownloadNotification({
            status: 'error',
            title: 'Failed',
            description: `Download "${fileName}.xlsx" gagal`,
        });
    }
};

export const onProcessingFile = async ({
    id,
    status,
    file,
    onResetPopUp,
    onChangeSnackbarNotification,
    onChangePopUp,
    refetchStockTrail,
}) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    onChangePopUp({ isLoading: true });

    const formData = new FormData();

    formData.append('id', id);
    formData.append('status', status);
    formData.append('file', file);

    try {
        await Axios({
            url: `/api/v1/stock-trail/processing-file`,
            method: 'POST',
            headers: { Authorization: 'Bearer ' + userToken },
            data: formData,
        });

        await refetchStockTrail();

        onChangeSnackbarNotification({
            status: 'success',
            title: 'Success',
            description: 'Berhasil mengubah data',
            duration: 5,
        });
    } catch (error) {
        console.log(error, '<<');
        const errorMessage = error?.response?.data?.message || '';
        onChangeSnackbarNotification({
            status: 'error',
            title: 'Gagal mengubah data',
            description: errorMessage,
            duration: 5,
        });
    } finally {
        onResetPopUp();
    }
};

export const onCancelStockTrail = async ({
    onChangePopUp,
    refetchStockTrail,
    onChangeSnackbarNotification,
    onResetPopUp,
    id,
}) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    onChangePopUp({ isLoading: true });

    try {
        await Axios({
            method: 'POST',
            url: '/api/v1/cancel-process/sellout-date',
            data: { id },
            headers: { Authorization: `Bearer ${userToken}` },
        });

        await refetchStockTrail();

        onChangeSnackbarNotification({
            status: 'success',
            title: 'Success',
            description: 'Berhasil cancel data',
            duration: 5,
        });
    } catch (error) {
        console.log(error);

        const errorMessage = error?.response?.data?.message || '';

        onChangeSnackbarNotification({
            status: 'error',
            title: 'Failed cancel data',
            description: errorMessage,
            duration: 5,
        });
    } finally {
        onResetPopUp();
    }
};
