import { Fragment, useEffect, useState } from 'react';
import { Box, Text, Input, Flex, Spinner } from '@chakra-ui/react';
import { ChevronDownThickIcon } from '@assets/icons';
import { initialState } from './const';
import { ConditionalComponent, IterationComponent } from '@components/functionComponent';
import OutsideClickHandler from 'react-outside-click-handler';
import { find, get } from 'lodash';
import { CloseIcon } from '@chakra-ui/icons';
import { debounce } from '@helpers/utils';
import './styles.scss';

const Select = ({
    onFetch,
    onSearch,
    options,
    isMultiple,
    searchQuery,
    isCanSearch,
    label,
    isLoading,
    onSelect,
    placeholder,
    isRequired,
    value,
}) => {
    const [state, setState] = useState(initialState);
    const { isOpen, selectedFilter } = state;

    const onChangeState = (newState) => setState({ ...state, ...newState });

    const onSelectOption = ({ selectedFilter, item, isMultiple }) => {
        const isExist = get(find(selectedFilter, { name: item?.name }), 'name');
        let newSelected = [];
        if (isExist) {
            newSelected = selectedFilter?.filter((option) => option?.name !== item?.name);
            onSelect(newSelected);
            onChangeState({ selectedFilter: newSelected, isOpen: isMultiple });
        } else {
            const newSelected = isMultiple ? [...selectedFilter, item] : [item];
            onSelect(newSelected);
            onChangeState({ selectedFilter: newSelected, isOpen: isMultiple });
        }
    };

    const onSeacrhHandler = debounce((query) => onSearch(query));

    useEffect(() => {
        if (isOpen) {
            onFetch({ searchQuery });
            onChangeState({ selectedFilter: value });
        }
        // eslint-disable-next-line
    }, [searchQuery, isOpen]);

    return (
        <Fragment>
            <Text className="label">{label}</Text>
            <OutsideClickHandler onOutsideClick={() => onChangeState({ isOpen: false })}>
                <Box className="select-form__container">
                    {/* //====================== FORM BUTTON =========================== */}

                    <Box
                        className={`select-button ${isRequired && 'field-required__button'}`}
                        onClick={() => onChangeState({ isOpen: true })}
                    >
                        {/* //====================== SELECTED ITEM =========================== */}
                        <ConditionalComponent statement={selectedFilter?.length}>
                            <IterationComponent
                                arr={selectedFilter}
                                render={(item, idx) => (
                                    <Text className="selected-text">{`${item?.name} ${
                                        idx === selectedFilter?.length - 1 ? '' : ','
                                    }`}</Text>
                                )}
                            />
                        </ConditionalComponent>
                        <Input
                            placeholder={selectedFilter?.length > 0 ? '' : placeholder}
                            onChange={(e) => onSeacrhHandler(e.target.value)}
                            isDisabled={!isCanSearch}
                            cursor={isCanSearch ? 'caret' : 'pointer !important'}
                        />
                        <Box className="icon-select">
                            <ConditionalComponent
                                statement={!isLoading}
                                fallback={<Spinner color="var(--main-color)" size="sm" />}
                            >
                                <ChevronDownThickIcon color="grey" className="icon-container" />
                            </ConditionalComponent>
                        </Box>
                    </Box>

                    {/* //====================== REQUIRED TEXT =========================== */}

                    <ConditionalComponent statement={isRequired}>
                        <Text className="field-required__text">Field is required!</Text>
                    </ConditionalComponent>

                    {/* //====================== FORM LIST =========================== */}
                    <ConditionalComponent statement={isOpen}>
                        <Box className="form-list">
                            <ConditionalComponent
                                statement={options?.length}
                                fallback={<Text className="not-found__text">Not Found</Text>}
                            >
                                <IterationComponent
                                    arr={options}
                                    render={(item) => {
                                        const isExist = get(find(selectedFilter, { name: item?.name }), 'name');
                                        return (
                                            <Flex
                                                key={item?.id}
                                                className={`select-item ${isExist && 'select-item__active'}`}
                                                onClick={() => onSelectOption({ item, selectedFilter, isMultiple })}
                                            >
                                                <Text className="select-text">{item?.name}</Text>
                                                <Box className="circle-active" />
                                            </Flex>
                                        );
                                    }}
                                />
                            </ConditionalComponent>
                        </Box>
                    </ConditionalComponent>
                </Box>
            </OutsideClickHandler>
        </Fragment>
    );
};

export default Select;
