import { create } from 'zustand';

const state = {
    users: [],
    //==================== PAGINATION ================== //
    paginationInfo: {
        dataCount: 0,
        totalData: 0,
        totalPage: 0,
    },
    isLoadingTable: true,
    isLoadingHead: false,
};

export const useUserManagementStore = create((set) => ({
    ...state,
    onChangeUserManagementStore: (newState) => set((state) => ({ ...state, ...newState })),
    onResetUserManagementStore: () => set(() => ({ ...state })),
}));

//========================================= FILTER =========================

const filterSelectedState = {
    selectedDC: [],
    selectedRole: '',
    searchQuery: '',
    page: 1,
};

export const useUserManagementFilterStore = create((set) => ({
    ...filterSelectedState,
    onChangeUserManagementFilterSelectedStore: (newState) => set((state) => ({ ...state, ...newState })),
    onResetUserManagementFilterSelectedStore: () => set(() => ({ ...filterSelectedState })),
}));

//========================================= DC =========================
const dcOptionState = {
    isLoadingDCOption: false,
    dcOptions: [],
    searchDCQuery: '',
};

export const useFilterDCOptionStore = create((set) => ({
    ...dcOptionState,
    onChangeFilterDCOptionStore: (newState) => set((state) => ({ ...state, ...newState })),
    onResetilterDCOptionStore: () => set(() => ({ ...dcOptionState })),
}));

//========================================= ROLE =========================

const roleOptionState = {
    isLoadingRoleOption: false,
    roleOptions: [],
    roleAccessOptions: [],
    searchRoleQuery: '',
};

export const useFilterRoleOptionStore = create((set) => ({
    ...roleOptionState,
    onChangeFilterRoleOptionStore: (newState) => set((state) => ({ ...state, ...newState })),
    onResetilterRoleOptionStore: () => set(() => ({ ...roleOptionState })),
}));

//========================================= CUSTOMERS =========================

const customerOptionState = {
    isLoadingCustomerOption: false,
    customerOptions: [],
    searchCustomerQuery: '',
    pageCustomer: 1,
};

export const useFilterCustomerOptionStore = create((set) => ({
    ...customerOptionState,
    onChangeFilterCustomerOptionStore: (newState) => set((state) => ({ ...state, ...newState })),
    onResetilterCustomerOptionStore: () => set(() => ({ ...customerOptionState })),
}));

//========================================= ADD EDIT STORE ===========================

const addEditState = {
    selectedDC: [],
    selectedRole: [],
    selectedCustomer: [],
    selectedAccess: [],
    username: '',
    password: '',
    confirmPassword: '',
    email: '',
    hasSubmit: false,
    isLoadingSubmit: false,
    isShowPassword: false,
};

export const useAddEditStore = create((set) => ({
    ...addEditState,
    onChangeAddEditStore: (newState) => set((state) => ({ ...state, ...newState })),
    onResetAddEditStore: () => set(() => ({ ...addEditState })),
}));
