import { onUploadFile } from './crud';

export const onChangeFile = ({ selectedFile, onChangePopUp, onResetPopUp, onChangeSnackbarNotification }) => {
    onChangePopUp({
        status: 'submit',
        title: 'Apakah anda yakin?',
        description: 'Data yang sudah diupload akan masuk kedalam database',
        onSubmit: () => {
            onUploadFile({
                selectedFile,
                onChangeSnackbarNotification,
                onClose: () => onResetPopUp(),
                onChangePopUp,
                onLoadingSubmit: () => onChangePopUp({ isLoading: true }),
            });
        },
    });
};
