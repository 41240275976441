import { CheckCircleIcon, NotAllowedIcon } from '@chakra-ui/icons';
import { Text } from '@chakra-ui/react';
import { ConditionalComponent } from '@components/functionComponent';
import {
    ActionsComponent,
    BadgeStatus,
    DateSellout,
    QtySellout,
    TextColumn,
} from '@pages/AuditTrail/components/columns';

export const tableListStore = [
    {
        name: 'Date Upload',
        width: 140,
        value: 'date_uploaded',
        component: ({ value }) => <TextColumn value={value} withTooltip />,
    },

    {
        name: 'Date Sellout',
        width: 300,
        value: 'date_sellout',
        component: ({ value }) => <DateSellout value={value} />,
    },

    {
        name: 'Sellout Type',
        value: 'type_date',
        width: 120,
        component: ({ value }) => <TextColumn value={value} />,
    },

    {
        name: 'Upload Type',
        value: 'upload_type',
        width: 150,
        component: ({ value }) => <TextColumn value={value} />,
    },
    {
        name: 'Uploader',
        width: 180,
        value: 'name',
        component: ({ value }) => <TextColumn value={value} />,
    },
    {
        name: 'Status',
        width: 120,
        value: 'status',
        component: ({ value }) => <BadgeStatus status={value} />,
    },
    {
        name: 'Qty',
        value: 'qty',
        width: 450,
        component: ({ value }) => <QtySellout value={value} />,
    },
    {
        name: 'Is Edit',
        value: 'is_edit',
        width: 85,
        component: ({ value }) => (
            <Text className="column-text">
                <ConditionalComponent statement={value} fallback={<NotAllowedIcon color="red.500" boxSize={4} />}>
                    <CheckCircleIcon color="green.500" boxSize={4} />
                </ConditionalComponent>
            </Text>
        ),
    },
    {
        name: 'Actions',
        width: 80,
        value: null,
        component: ({ values }) => <ActionsComponent values={values} />,
    },
];

export const TableListNotStore = [
    {
        name: 'Customer Id',
        width: 120,
        value: 'customer_id',
        component: ({ value }) => <TextColumn value={value} withTooltip />,
    },
    {
        name: 'Store Name',
        width: 120,
        value: 'nama_outlet',
        component: ({ value }) => <TextColumn value={value} withTooltip />,
    },

    {
        name: 'DC',
        width: 120,
        value: 'nama_dc',
        component: ({ value }) => <TextColumn value={value} withTooltip />,
    },
    {
        name: 'Store Group',
        width: 120,
        value: 'group',
        component: ({ value }) => <TextColumn value={value} withTooltip />,
    },

    ...tableListStore,
];
