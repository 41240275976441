import { create } from 'zustand';

const state = { spotlightActive: false };

export const useSpotLight = create((set) => ({
    ...state,
    onSpotlight: ({ selectedElement }) => {
        set(() => ({ spotlightActive: true }));
        const selectedEl = document?.getElementById(selectedElement);

        window.scrollTo({ top: selectedEl?.clientHeight + 300, behavior: 'smooth' });

        selectedEl?.classList?.add('spotlight-element-selected__container');
    },
    onResetSpotlight: () => {
        set(() => ({ ...state }));
        const selectedEl = document?.getElementsByClassName('spotlight-element-selected__container');

        for (let i = 0; i < selectedEl.length; i++) {
            selectedEl[i]?.classList?.remove('spotlight-element-selected__container');
        }
    },
}));
