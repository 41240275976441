import { Flex, Text, Box } from '@chakra-ui/react';

import { InfoIcon } from '../../assets/icons';
import './styles.scss';

const BlockedNotification = ({ blockedUpload, onClose }) => {
    return (
        <Flex className="blocked-notification__container">
            <Flex>
                <Box className="box-icon">
                    <InfoIcon />
                </Box>
                <Text className="information-text">
                    Upload Sell-Out periode <span>{blockedUpload?.block_text}</span> telah ditutup untuk penghitungan
                    pencapaian.{' '}
                    <span>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://paracorpgroup-my.sharepoint.com/:w:/p/muhammad_adobson/EbgzPxNJrHlAhqODaRRBSQgBxmIL3jA9lQIYJmssFEjevg?e=8V6X7s"
                        >
                            Pelajari Selengkapnya Disini.
                        </a>
                    </span>
                </Text>
            </Flex>
            <Text className="close-button" onClick={onClose}>
                Tutup
            </Text>
        </Flex>
    );
};

export default BlockedNotification;
