import { onCheckInputFile } from '@helpers/utils';
import { useSnackbarNotification } from '@store/snackbarNotification';
import { Input } from '@chakra-ui/react';
import './styles.scss';

const FileInput = ({ onChange, maxSize, accessAllowed }) => {
    const { onChangeSnackbarNotification } = useSnackbarNotification();

    const onChangeFile = (event) => {
        const file = event.target.files[0];

        const checkInputFileStatus = onCheckInputFile({
            file,
            maxSize,
            accessAllowed,
            onChangeSnackbarNotification,
        });

        onChange({ checkedStatus: checkInputFileStatus, selectedFile: file });
    };
    return <Input className="file-input__global-container" type="file" onChange={onChangeFile} />;
};

export default FileInput;
