import { Text as TextComponent } from '@chakra-ui/react';
import './styles.scss';

export const Text = ({ children, className = '' }) => (
    <TextComponent className={`main-text__global ${className}`}>{children}</TextComponent>
);

export const TextLarge = ({ children, className }) => (
    <TextComponent className={`main-text-large__global ${className}`}>{children}</TextComponent>
);

export const TextSmall = ({ children, className }) => (
    <TextComponent className={`main-text-small__global ${className}`}>{children}</TextComponent>
);
