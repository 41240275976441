import Cookies from 'universal-cookie';
import { Flex, Text, Box } from '@chakra-ui/react';

import FilterDropdown from '@components/filter/dropdown';
import { ConditionalComponent } from '@components/functionComponent';
import FilterSearch from '@components/filter/search';
import FloatingMenu from '@components/floatingMenu';
import Sort from '@components/filter/sort';
import FilterDate from '@components/filter/date';
import { DottedIcon } from '@assets/icons';

import HeadSkeleton from '@pages/AuditTrail/components/headSkeleton';
import { fetchDC, fetchCustomer, fetchStoreGroup, fetchStatus, fetchUploadType } from '@pages/AuditTrail/helpers/crud';

import {
    useDCOptionStore,
    useAuditTrailFilterStore,
    useAuditTrailStore,
    useCustomerOptionStore,
    useGroupOptionStore,
    useStatusOptionStore,
    useUploadTypeOptionStore,
} from '@pages/AuditTrail/helpers/mainState';

import { listHeadMenu } from './const';
import './styles.scss';

const Head = () => {
    const cookies = new Cookies();
    const userRole = cookies.get('role');

    //======================== FILTER STATE =============================================

    const { onChangeAuditTrailFilterSelectedStore, selectedSorting, dateUploadSelected, dateSelloutSelected } =
        useAuditTrailFilterStore();
    const { isLoadingHead } = useAuditTrailStore();

    //======================== FILTER ASSETS STATE =============================================

    const { onChangeDCOptionStore, dcOptions, isLoadingDCOption } = useDCOptionStore();
    const {
        onChangeCustomerOptionStore,
        customerOptions,
        isLoadingCustomerOption,
        searchCustomerQuery,
        onResetCustomerOptionStore,
    } = useCustomerOptionStore();

    const {
        isLoadingStoreGroupOption,
        storeGroupOptions,
        searchStoreGroupQuery,
        onChangeStoreGroupOptionStore,
        onResetStoreGroupOptionStore,
    } = useGroupOptionStore();

    const { isLoadingStatusOption, statusOptions, onChangeStatusOptionStore } = useStatusOptionStore();
    const { isLoadingUploadTypeOption, uploadTypeOptions, onChangeUploadTypeOptionStore } = useUploadTypeOptionStore();

    //======================== FETCH ASSETS =============================================

    const onFetchDC = () => fetchDC({ onChangeDCOptionStore });
    const onFetchStatus = () => fetchStatus({ onChangeStatusOptionStore });
    const onFetchUploadType = () => fetchUploadType({ onChangeUploadTypeOptionStore });

    const onFetchCustomer = ({ searchQuery }) => fetchCustomer({ onChangeCustomerOptionStore, searchQuery });
    const onFetchStoreGroup = ({ searchQuery }) => fetchStoreGroup({ onChangeStoreGroupOptionStore, searchQuery });

    return (
        <Box className="section-head__audit-trail">
            <Text className="title">Sellout</Text>
            <ConditionalComponent statement={!isLoadingHead} fallback={<HeadSkeleton />}>
                <Flex alignItems="center" justifyContent="space-between">
                    {/* //==================== LEFT SECTION ==================== */}

                    <Flex alignItems="center">
                        <FilterDate
                            onReset={() => onChangeAuditTrailFilterSelectedStore({ dateUploadSelected: null, page: 1 })}
                            onChange={(date) =>
                                onChangeAuditTrailFilterSelectedStore({ dateUploadSelected: new Date(date), page: 1 })
                            }
                            selected={dateUploadSelected}
                            placeholder="Date Upload"
                        />

                        <FilterDate
                            onReset={() =>
                                onChangeAuditTrailFilterSelectedStore({ dateSelloutSelected: null, page: 1 })
                            }
                            onChange={(date) =>
                                onChangeAuditTrailFilterSelectedStore({ dateSelloutSelected: date, page: 1 })
                            }
                            selected={dateSelloutSelected}
                            placeholder="Date Sellout"
                            isMulti
                        />

                        <ConditionalComponent statement={userRole === 'National Leaders'}>
                            <FilterDropdown
                                placeholder="DC"
                                options={dcOptions}
                                maxFilter={3}
                                onFetchData={onFetchDC}
                                isLoading={isLoadingDCOption}
                                filterId="filter-dc__audit-trail"
                                onSelect={({ selected }) =>
                                    onChangeAuditTrailFilterSelectedStore({ selectedDC: selected, page: 1 })
                                }
                            />
                        </ConditionalComponent>
                        <ConditionalComponent
                            statement={userRole === 'Area Leaders' || userRole === 'National Leaders'}
                        >
                            <FilterSearch
                                placeholder="Group"
                                options={storeGroupOptions}
                                maxFilter={3}
                                searchQuery={searchStoreGroupQuery}
                                onClose={onResetStoreGroupOptionStore}
                                onSearchQuery={(value) =>
                                    onChangeStoreGroupOptionStore({ searchStoreGroupQuery: value })
                                }
                                onFetchData={({ searchQuery }) => onFetchStoreGroup({ searchQuery })}
                                isLoading={isLoadingStoreGroupOption}
                                filterId="filter-store-group__audit-trail"
                                onSelect={({ selected }) =>
                                    onChangeAuditTrailFilterSelectedStore({ selectedStoreGroup: selected, page: 1 })
                                }
                            />

                            <FilterSearch
                                placeholder="Store"
                                options={customerOptions}
                                maxFilter={3}
                                searchQuery={searchCustomerQuery}
                                onClose={onResetCustomerOptionStore}
                                onSearchQuery={(value) => onChangeCustomerOptionStore({ searchCustomerQuery: value })}
                                onFetchData={({ searchQuery }) => onFetchCustomer({ searchQuery })}
                                isLoading={isLoadingCustomerOption}
                                filterId="filter-customer__audit-trail"
                                onSelect={({ selected }) =>
                                    onChangeAuditTrailFilterSelectedStore({ selectedStore: selected, page: 1 })
                                }
                            />
                        </ConditionalComponent>

                        <FilterDropdown
                            isMultiple={false}
                            placeholder="Status"
                            options={statusOptions}
                            maxFilter={1}
                            onFetchData={onFetchStatus}
                            isLoading={isLoadingStatusOption}
                            filterId="filter-status__audit-trail"
                            onSelect={({ selected }) =>
                                onChangeAuditTrailFilterSelectedStore({ selectedStatus: selected, page: 1 })
                            }
                        />
                        <FilterDropdown
                            isMultiple={false}
                            placeholder="Type"
                            options={uploadTypeOptions}
                            maxFilter={1}
                            onFetchData={onFetchUploadType}
                            isLoading={isLoadingUploadTypeOption}
                            filterId="filter-upload-type__audit-trail"
                            onSelect={({ selected }) =>
                                onChangeAuditTrailFilterSelectedStore({ selectedUploadType: selected, page: 1 })
                            }
                        />
                    </Flex>

                    {/* //==================== RIGHT SECTION ==================== */}

                    <Flex alignItems="center">
                        <Sort
                            selected={selectedSorting}
                            onChangeSort={(value) => onChangeAuditTrailFilterSelectedStore({ selectedSorting: value })}
                        />

                        <FloatingMenu
                            className="dotted-icon"
                            isClickMenu={true}
                            list={listHeadMenu}
                            buttonElement={<DottedIcon />}
                        />
                    </Flex>
                </Flex>
            </ConditionalComponent>
        </Box>
    );
};

export default Head;
