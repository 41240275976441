import { create } from 'zustand';

const flowStore = {
    nodes: [],
};

export const useFlowStore = create((set) => ({
    ...flowStore,
    onChangeFlowStore: (newState) => set((state) => ({ ...state, ...newState })),
    onResetFlowStore: () => {
        set(() => ({ ...flowStore }));
    },
}));

const additionalFlowState = {
    addType: null,
    selectedElement: null,
    isShowAddType: false,
};

export const useAdditionalFlow = create((set) => ({
    ...additionalFlowState,
    onChangeAdditionalFlow: (newState) => set((state) => ({ ...state, ...newState })),
    onResetAdditionalFlow: () => {
        set(() => ({ ...additionalFlowState }));
    },
}));

const viewPosition = {
    x: 300,
    y: 300,
};

export const useViewPosition = create((set) => ({
    ...viewPosition,
    onChangeViewPosition: (newState) => set((state) => ({ ...state, ...newState })),
    onResetViewPosition: () => {
        set(() => ({ ...viewPosition }));
    },
}));

// import { ConditionalComponent } from '@components/functionComponent';

// const CustomNode = ({ data }) => {
//     const background = data?.background || 'var(--main-color-light)';
//     const color = data?.color || 'var(--main-color)';

//     return (
//         <Box className="default-custom__node" bg={background} border={`2px solid ${color}`}>
//             <ConditionalComponent statement={data?.dots?.target}>
//                 <Handle type="target" position="top" />
//             </ConditionalComponent>
//             <ConditionalComponent statement={data?.dots?.source}>
//                 <Handle type="source" position="bottom" />
//             </ConditionalComponent>
//             <Text color={color}>{data.label}</Text>
//         </Box>
//     );
// };

// const Notes = ({ data }) => {
//     return (
//         <Box className="flow-notes__container">
//             <Text>{data.label}</Text>
//         </Box>
//     );
// };

// const EndLabel = ({ id, sourceX, sourceY, targetX, targetY, sourcePosition, targetPosition, data, label }) => {
//     const [edgePath] = getSmoothStepPath({
//         sourceX,
//         sourceY,
//         sourcePosition,
//         targetX,
//         targetY,
//         targetPosition,
//     });

//     return (
//         <>
//             <BaseEdge id={id} path={edgePath} style={{ stroke: 'var(--main-color)' }} />
//             <EdgeLabelRenderer>
//                 <div
//                     style={{
//                         position: 'absolute',
//                         padding: 10,
//                         color: 'black',
//                         fontSize: 10,
//                         transform: `translate(-50%, -100%) translate(${targetX}px,${targetY - 50}px)`,
//                     }}
//                     className="nodrag nopan"
//                 >
//                     {label}
//                 </div>
//             </EdgeLabelRenderer>
//         </>
//     );
// };
// const nodeTypes = {
//     ['default-custom']: CustomNode,
//     notes: Notes,
// };

// const edgeTypes = {
//     ['end_label']: EndLabel,
// };
