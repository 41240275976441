import { Box, Flex, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useConfigDetail } from '@pages/StoreConfig/helpers/mainState';
import { useSnackbarNotification } from '@store/snackbarNotification';
import { OpenEyeIcon } from '@assets/icons';
import { ConditionalComponent } from '@components/functionComponent';

import { onFetchReplenishment, onForceRunPost } from '@pages/StoreConfig/helpers/crud';
import { onChangeSetting, onUpdateActiveReplenisment } from '@pages/StoreConfig/helpers/event';
import { getStatusColor, onCheckBackgroundProgress } from '@pages/StoreConfig/helpers/utils';
import ReplenishmentProgressModal from '../replenismentProgressModal';
import { InputComponent, CheckboxComponent, TextComponent } from '../detailElement';
import { PlayIcon } from './icons';

import './styles.scss';

const Replenishment = ({ id, setIsBackgroundProgress, isBackgroundProgress }) => {
    const store = useConfigDetail();
    const storeInfo = store?.storeInfo;
    const { onChangeSnackbarNotification } = useSnackbarNotification();
    const [replenishment, setReplenisment] = useState({});
    const [isOpenModal, setIsOpenModal] = useState(false);

    const fetchReplenisment = () => onFetchReplenishment({ setReplenisment, id });

    useEffect(() => {
        let intervalRef = null;
        const isBackgroundProgress = onCheckBackgroundProgress(replenishment?.progress_stage);
        setIsBackgroundProgress(isBackgroundProgress);

        if (isBackgroundProgress) intervalRef = setInterval(() => fetchReplenisment(), 2000);
        else clearInterval(intervalRef);

        return () => clearInterval(intervalRef);
    }, [replenishment?.progress_stage]);

    useEffect(() => {
        fetchReplenisment();
    }, []);

    return (
        <Box className="replenisment-container">
            <Box className="section-item">
                <Text className="label">Replenisment</Text>
                <Flex className="checkbox-item force-run__container">
                    <Text className="checkbox-label">Force run</Text>
                    <ConditionalComponent
                        statement={!isBackgroundProgress}
                        fallback={<OpenEyeIcon color="var(--main-color)" onClick={() => setIsOpenModal(true)} />}
                    >
                        <PlayIcon
                            onClick={() =>
                                onForceRunPost({
                                    onChangeSnackbarNotification,
                                    id,
                                    setIsOpenModal,
                                    fetchReplenisment,
                                })
                            }
                        />
                    </ConditionalComponent>
                </Flex>

                <Flex className="checkbox-item">
                    <Text className="checkbox-label">Active</Text>
                    <CheckboxComponent
                        storeInfo={storeInfo}
                        store={store}
                        fieldName="is_replenishment"
                        onChangeSetting={(value) => onUpdateActiveReplenisment(value)}
                    />
                </Flex>

                <Flex className="checkbox-item">
                    <Text className="checkbox-label">Include pending transfer</Text>
                    <CheckboxComponent storeInfo={storeInfo} store={store} fieldName="is_replenishment_pending" />
                </Flex>

                <Flex className="input-item">
                    <Text className="input-label">Exagon ID</Text>'
                    <InputComponent
                        storeInfo={storeInfo}
                        store={store}
                        fieldName="exagon_id"
                        placeholder="Input ID"
                        onChangeSetting={onChangeSetting}
                    />
                </Flex>

                <Flex className="text-item">
                    <Text className="text-label">Last run</Text>
                    <TextComponent store={store} storeInfo={storeInfo} value={replenishment?.status?.updated_at} />
                </Flex>

                <Flex className="text-item">
                    <Text className="text-label">Status</Text>
                    <TextComponent
                        storeInfo={storeInfo}
                        store={store}
                        value={replenishment?.status?.name}
                        color={getStatusColor(replenishment?.status?.name)}
                    />
                </Flex>

                <Flex className="text-item">
                    <Text className="text-label">Task no</Text>
                    <TextComponent storeInfo={storeInfo} store={store} value={replenishment?.task_id} />
                </Flex>

                <ConditionalComponent statement={isOpenModal}>
                    <ReplenishmentProgressModal onClose={() => setIsOpenModal(false)} id={id} />
                </ConditionalComponent>
            </Box>
        </Box>
    );
};

export default Replenishment;
