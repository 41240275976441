import { memo } from 'react';
import { Box } from '@chakra-ui/react';
import { Handle, Position, NodeResizer } from 'reactflow';
import { ConditionalComponent } from '@components/functionComponent';

const handleStyle = {
    width: 12,
    height: 12,
    border: '2px solid var(--main-color-dark)',
    background: 'white',
};

const Nodes = ({ data, selected }) => {
    return (
        <>
            <NodeResizer
                lineStyle={{ borderWidth: '2px', background: 'pink)' }}
                handleStyle={{ background: 'var(--main-color)', width: '10px', height: '10px', borderRadius: '50%' }}
                isVisible={selected}
                minWidth={100}
                minHeight={30}
            />
            <Box
                className={`default-custom__node ${selected && 'selected-node'}`}
                onClick={() => data?.onSelectElement(data)}
                background={data?.background?.hex || 'var(--main-color-light)'}
                border={`1px solid ${data?.borderColor?.hex || 'var(--main-color-dark)'}`}
                color={data?.color?.hex || 'var(--main-color-dark)'}
            >
                <ConditionalComponent statement={data?.handle?.target}>
                    <Handle type="target" position={Position.Left} style={{ ...handleStyle }} />
                </ConditionalComponent>

                <ConditionalComponent statement={data?.handle?.source}>
                    <Handle type="source" position={Position.Right} style={{ ...handleStyle }} />
                </ConditionalComponent>

                {data?.label}
            </Box>
        </>
    );
};

export default memo(Nodes);
