import { Box, Spinner } from '@chakra-ui/react';
import './styles.scss';
import { useLoader } from '@store/loader';
import { ConditionalComponent } from '@components/functionComponent';

const Loader = () => {
    const { isLoadingPage } = useLoader();
    return (
        <ConditionalComponent statement={isLoadingPage}>
            <Box className="global-loader__container">
                <Box className="spinner-box">
                    <Spinner size="md" color="white" />
                </Box>
            </Box>
        </ConditionalComponent>
    );
};

export default Loader;
