import { Box } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import TableSkeleton from './components/table';
import UploadSkeleton from './components/upload';
import Loader from '../loader';

import './styles.scss';

const DashboarSkeleton = () => {
    const { pathname } = useLocation();
    const defaultResult = <TableSkeleton />;

    const obj = {
        '/upload-sellout': <UploadSkeleton />,
        '/upload-mapping': <UploadSkeleton />,
    };

    return <Box className="dashboard-skeleton">{obj[pathname] || defaultResult}</Box>;
};

export default DashboarSkeleton;
