import { Box, Flex, Skeleton, Table } from '@chakra-ui/react';
import onGetClientScreen from '@store/clientUser';

import { ChevronDownIcon, UploadIcon } from '@assets/icons';
import { TableColumnSkeleton, TableHeadSkeleton } from '@components/tableTemplate/components/skeleton';

const UploadSkeleton = () => {
    const clientScreen = onGetClientScreen();
    const skeletonParentWidth =
        clientScreen === 'wideScreen' ? '200px' : clientScreen === 'mediumScreen' ? '136px' : '140px';

    return (
        <>
            <Flex className="reminder">
                <Skeleton
                    height="100%"
                    w={skeletonParentWidth}
                    borderRadius="8px"
                    startColor="var(--main-color-light-active)"
                    endColor={`rgba(var(--main-color-light-active),0.5)`}
                />
                <Flex ml="48px" flex={1} gap="12px" justifyContent="center" flexDirection="column">
                    <Skeleton
                        height="20px"
                        w={'40%'}
                        borderRadius="8px"
                        startColor="var(--main-color-light-active)"
                        endColor={`rgba(var(--main-color-light-active),0.5)`}
                    />

                    <Skeleton
                        height="20px"
                        w={'100%'}
                        borderRadius="8px"
                        startColor="var(--main-color-light-active)"
                        endColor={`rgba(var(--main-color-light-active),0.5)`}
                    />

                    <Skeleton
                        height="20px"
                        w={'80%'}
                        borderRadius="8px"
                        startColor="var(--main-color-light-active)"
                        endColor={`rgba(var(--main-color-light-active),0.5)`}
                    />

                    <Skeleton
                        height="20px"
                        w={'40%'}
                        borderRadius="8px"
                        startColor="var(--main-color-light-active)"
                        endColor={`rgba(var(--main-color-light-active),0.5)`}
                    />
                </Flex>
            </Flex>
            <Box className="upload">
                <Skeleton
                    height="28px"
                    w="150px"
                    borderRadius="8px"
                    startColor="var(--main-color-light-active)"
                    endColor={`rgba(var(--main-color-light-active),0.5)`}
                    mb="16px"
                />
                <Flex alignItems="center" justifyContent="space-between" mb="24px">
                    <Box className="download-menu">
                        <Skeleton
                            height="28px"
                            w="160px"
                            borderRadius="8px"
                            startColor="var(--main-color-light-active)"
                            endColor={`rgba(var(--main-color-light-active),0.5)`}
                        />
                        <ChevronDownIcon color="var(--main-color)" />
                    </Box>

                    <Skeleton
                        height="28px"
                        w="160px"
                        borderRadius="8px"
                        startColor="var(--main-color-light-active)"
                        endColor={`rgba(var(--main-color-light-active),0.5)`}
                    />
                </Flex>
                <Box className="upload-zone">
                    <UploadIcon color="var(--main-color)" />
                    <Skeleton
                        height="28px"
                        w="160px"
                        borderRadius="8px"
                        startColor="var(--main-color-light-active)"
                        endColor={`rgba(var(--main-color-light-active),0.5)`}
                    />
                </Box>
            </Box>

            <Box className="table">
                <Flex alignItems="center" justifyContent="space-between" mb="24px">
                    <Skeleton
                        height="28px"
                        w="160px"
                        borderRadius="8px"
                        startColor="var(--main-color-light-active)"
                        endColor={`rgba(var(--main-color-light-active),0.5)`}
                    />

                    <Skeleton
                        height="28px"
                        w="160px"
                        borderRadius="8px"
                        startColor="var(--main-color-light-active)"
                        endColor={`rgba(var(--main-color-light-active),0.5)`}
                    />
                </Flex>
                <Table>
                    <TableHeadSkeleton tableList={[1, 2, 3, 4, 5, 6, 7, 8]} />
                    <TableColumnSkeleton tableList={[1, 2, 3, 4, 5, 6, 7, 8]} />
                </Table>
            </Box>
        </>
    );
};

export default UploadSkeleton;
