import { Box, Flex } from '@chakra-ui/react';
import { ReactFlowProvider } from 'reactflow';
import DocumentationHeader from './components/header';
import DocumentationNavbar from './components/navbar';
import PagesContent from './components/content';

import './styles.scss';

const Documentation = () => {
    return (
        <ReactFlowProvider>
            <Box className="documentation-page__container">
                <DocumentationHeader />
                <Flex className="documentation-body">
                    <DocumentationNavbar />
                    <Box className="documentation-content__container">
                        <PagesContent />
                    </Box>
                </Flex>
            </Box>
        </ReactFlowProvider>
    );
};

export default Documentation;

// {
//     "name": "Components",
//     "path": "components",
//     "icon": "<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M3 7L10 3L21 7M3 7V12L14 16L21 12V7M3 7L14 11L21 7' stroke='var(--main-color)' strokeLinejoin='round'/><path d='M3 12V17L14 21L21 17V12' stroke='var(--main-color)' strokeLinejoin='round'/></svg>            "
// },
// {
//     "name": "Examples",
//     "path": "examples",
//     "icon": "<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M13.1716 3H9C7.11438 3 6.17157 3 5.58579 3.58579C5 4.17157 5 5.11438 5 7V17C5 18.8856 5 19.8284 5.58579 20.4142C6.17157 21 7.11438 21 9 21H15C16.8856 21 17.8284 21 18.4142 20.4142C19 19.8284 19 18.8856 19 17V8.82843C19 8.41968 19 8.2153 18.9239 8.03153C18.8478 7.84776 18.7032 7.70324 18.4142 7.41421L14.5858 3.58579C14.2968 3.29676 14.1522 3.15224 13.9685 3.07612C13.7847 3 13.5803 3 13.1716 3Z' stroke='var(--main-color)'/><path d='M13.8553 14.081L10.6727 12.717C9.88086 12.3776 9 12.9584 9 13.8199V16.1803C9 17.0417 9.88086 17.6226 10.6727 17.2832L13.8553 15.9192C14.6634 15.5729 14.6634 14.4273 13.8553 14.081Z' stroke='var(--main-color)' strokeLinecap='round' strokeLinejoin='round'/><path d='M13 3V7C13 7.94281 13 8.41421 13.2929 8.70711C13.5858 9 14.0572 9 15 9H19' stroke='var(--main-color)'/></svg>            "
// },
// {
//     "name": "Api Reference",
//     "path": "api-reference",
//     "icon": "<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'><ellipse cx='12' cy='7' rx='7' ry='3' stroke='var(--main-color)'/><path d='M5 13C5 13 5 15.3431 5 17C5 18.6569 8.13401 20 12 20C15.866 20 19 18.6569 19 17C19 16.173 19 13 19 13' stroke='var(--main-color)' strokeLinecap='square'/><path d='M5 7C5 7 5 10.3431 5 12C5 13.6569 8.13401 15 12 15C15.866 15 19 13.6569 19 12C19 11.173 19 7 19 7' stroke='var(--main-color)'/></svg>            "
// },
// {
//     "name": "Testing",
//     "path": "testing",
//     "icon": "<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M8.5 12.5L15.5 12.5' stroke='var(--main-color)' strokeLinecap='round'/><path d='M8.5 15.5L12.5 15.5' stroke='var(--main-color)' strokeLinecap='round'/><path d='M5.5 5.9C5.5 5.05992 5.5 4.63988 5.66349 4.31901C5.8073 4.03677 6.03677 3.8073 6.31901 3.66349C6.63988 3.5 7.05992 3.5 7.9 3.5H12.5059C12.8728 3.5 13.0562 3.5 13.2288 3.54145C13.3819 3.57819 13.5282 3.6388 13.6624 3.72104C13.8138 3.8138 13.9435 3.94352 14.2029 4.20294L17.7971 7.79706C18.0565 8.05648 18.1862 8.1862 18.279 8.33757C18.3612 8.47178 18.4218 8.6181 18.4586 8.77115C18.5 8.94378 18.5 9.12723 18.5 9.49411V18.1C18.5 18.9401 18.5 19.3601 18.3365 19.681C18.1927 19.9632 17.9632 20.1927 17.681 20.3365C17.3601 20.5 16.9401 20.5 16.1 20.5H7.9C7.05992 20.5 6.63988 20.5 6.31901 20.3365C6.03677 20.1927 5.8073 19.9632 5.66349 19.681C5.5 19.3601 5.5 18.9401 5.5 18.1V5.9Z' stroke='var(--main-color)'/><path d='M12.5 3.5V7.1C12.5 7.94008 12.5 8.36012 12.6635 8.68099C12.8073 8.96323 13.0368 9.1927 13.319 9.33651C13.6399 9.5 14.0599 9.5 14.9 9.5H18.5' stroke='var(--main-color)'/></svg>            "
// },
