import { useEffect, useState } from 'react';
import { Box, Flex, Spinner, Text } from '@chakra-ui/react';
import { CloseIcon, EditIcon } from '@assets/icons';
import Editor from '@pages/Documentation/components/fields';
import { ConditionalComponent } from '@components/functionComponent';
import { onFetchDocs, onUpdateDocs } from '@pages/Documentation/helpers/crud';
import { useSnackbarNotification } from '@store/snackbarNotification';

import './styles.scss';


const GitFlow = () => {
    const type = 'gitflow';
    const [data, setData] = useState({ docs: {}, isLoading: true, isEdit: false });
    const onChangeState = (newValue) => setData({ ...data, ...newValue });

    const { onChangeSnackbarNotification } = useSnackbarNotification();
    const onRefetchDocs = () => onFetchDocs({ onChangeState, type });

    useEffect(() => {
        onRefetchDocs();
    }, []);

    return (
        <Box className="folder-structure__container">
            <Flex justifyContent="space-between" mb="24px">
                <Text className="title">{data?.docs?.title}</Text>

                <ConditionalComponent
                    statement={data?.isEdit}
                    fallback={
                        <Box cursor="pointer" onClick={() => onChangeState({ isEdit: true })}>
                            <EditIcon color="var(--main-color)" />
                        </Box>
                    }
                >
                    <Box cursor="pointer" onClick={() => onChangeState({ isEdit: false })}>
                        <CloseIcon color="var(--main-color)" />
                    </Box>
                </ConditionalComponent>
            </Flex>
            <ConditionalComponent statement={data?.isEdit}>
                <Editor
                    currentValue={data?.docs?.contents}
                    onSave={({ value }) =>
                        onUpdateDocs({
                            value,
                            title: 'Folder Structure',
                            onChangeSnackbarNotification,
                            onRefetchDocs,
                            onChangeState,
                            type,
                        })
                    }
                />
            </ConditionalComponent>

            <ConditionalComponent statement={!data?.isLoading && !data?.isEdit}>
                <Box dangerouslySetInnerHTML={{ __html: data?.docs?.contents }} />
            </ConditionalComponent>

            <ConditionalComponent statement={data?.isLoading}>
                <Box className="loading-container">
                    <Spinner />
                </Box>
            </ConditionalComponent>
        </Box>
    );
};

export default GitFlow;
