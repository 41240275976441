import { create } from 'zustand';

const state = {
    password: '',
    confirmationPassword: '',
    isLoading: false,
    isOpenPassword: false,
};

export const useResetPassword = create((set) => ({
    ...state,
    onChangeResetPasswordStore: (newState) => set((state) => ({ ...state, ...newState })),
    onResetResetPasswordStore: () => {
        set(() => ({ ...state }));
    },
}));
