import { useNavigate } from 'react-router-dom';
import { Text, Flex } from '@chakra-ui/react';
import { LogoutIcon, ProfileIcon } from './icons';

import { useSnackbarNotification } from '@store/snackbarNotification';
import { onLogoutHandler } from './helpers/events';
import { useLoader } from '@store/loader';
import { useRouteList } from '@store/routes';

const GoToProfileComponent = () => {
    const navigate = useNavigate();
    return (
        <Flex className="floating-button__wrapper" onClick={() => navigate('/profile')}>
            <ProfileIcon />
            <Text>Profile</Text>
        </Flex>
    );
};

const LogoutComponent = () => {
    const { onResetRouteList } = useRouteList();
    const navigate = useNavigate();
    const { onChangeSnackbarNotification } = useSnackbarNotification();
    const { onChangeLoader } = useLoader();

    return (
        <Flex
            className="floating-button__wrapper"
            onClick={() =>
                onLogoutHandler({ onResetRouteList, navigate, onChangeLoader, onChangeSnackbarNotification })
            }
        >
            <LogoutIcon />
            <Text>Logout</Text>
        </Flex>
    );
};

export const clickProfileList = [<GoToProfileComponent />, <LogoutComponent />];
