import Cookies from 'universal-cookie';
import Axios from '@services/axios';
import { saveAs } from 'file-saver';
import { getDownloadTemplateEndpoint } from './utils';
        
export const onDownloadTemplate = async ({ templateType, onChangeDownloadNotification }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    const endpoint = getDownloadTemplateEndpoint({ templateType });

    const filename = `${templateType}.xlsx`;

    onChangeDownloadNotification({
        status: 'download',
        title: 'Downloading',
        description: `Harap tunggu, sedang mendownload file "${filename}"`,
    });

    try {
        const { data } = await Axios({
            url: endpoint,
            method: 'GET',
            responseType: 'blob',
            headers: { Authorization: 'Bearer ' + userToken },
        });

        const blob = new Blob([data], { type: 'text/xlsx' });
        saveAs(blob, `${filename}`);

        onChangeDownloadNotification({
            status: 'success',
            title: 'Success',
            description: `Download "${filename}" berhasil`,
        });
    } catch (err) {
        console.log(err);
        onChangeDownloadNotification({
            status: 'error',
            title: 'Failed',
            description: `Download file template gagal`,
        });
    }
};

export const onUploadFile = async ({
    selectedFile,
    onChangeSnackbarNotification,
    onLoadingSubmit,
    onClose,
    onResetUploadtore,
}) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    onLoadingSubmit();

    onChangeSnackbarNotification({
        status: 'info',
        title: 'Uploading!',
        description: `Harap tunggu, sedang mengupload file ${selectedFile?.name}`,
        alwaysShow: true,
    });

    try {
        const formData = new FormData();

        formData.append('file', selectedFile);

        await Axios({
            url: '/api/v1/sellout/product/upload-product-mapping',
            method: 'POST',
            headers: { Authorization: 'Bearer ' + userToken },
            data: formData,
        });

        onChangeSnackbarNotification({
            status: 'success',
            title: 'Success!',
            description: `Berhasil mengupload file ${selectedFile?.name}`,
            duration: 3,
            alwaysShow: false,
        });

        //====================== SEND TRANSLATE ====================
    } catch (err) {
        console.log(err);
        const errorMessages = err?.response?.data?.message || 'Gagal mengupload file';

        onChangeSnackbarNotification({
            status: 'error',
            title: 'Success!',
            description: errorMessages,
            duration: 3,
            alwaysShow: false,
        });
    } finally {
        onClose();
        onResetUploadtore();
    }
};
