import { ErrorBoundary } from 'react-error-boundary';
import { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ChakraProvider, Box } from '@chakra-ui/react';
import { datadogRum } from '@datadog/browser-rum';

import { useRouteList } from '@store/routes';

import DashboardContainer from '@components/dashboardContainer';
import ErrorPage from '@pages/ErrorPage';
import { fetchGlobalStyles } from '@helpers/utils';

function App() {
    const { routes } = useRouteList();

    useEffect(() => fetchGlobalStyles(), []);

    return (
        <div className="App">
            <ChakraProvider>
                <Router>
                    <ErrorBoundary fallbackRender={(error) => <ErrorPage error={error} />}>
                        <DashboardContainer>
                            <Routes>
                                {routes.map((route) => {
                                    return (
                                        <Route
                                            exact
                                            path={route.path}
                                            element={route.element}
                                            key={route.path}
                                            errorElement={route.errorElement}
                                        />
                                    );
                                })}
                            </Routes>
                        </DashboardContainer>
                    </ErrorBoundary>
                </Router>
            </ChakraProvider>
        </div>
    );
}

process.env.REACT_APP_PUBLIC_DEPLOYMENT_ENV === 'production' &&
    process.env.REACT_APP_DATA_DOG_CLIENT_TOKEN &&
    datadogRum.init({
        applicationId: 'c30ff225-698e-4aa3-85f3-6998642bb45a',
        clientToken: process.env.REACT_APP_DATA_DOG_CLIENT_TOKEN,
        site: 'ap1.datadoghq.com',
        service: 'sellout-web-ui',
        env: process.env.REACT_APP_PUBLIC_DEPLOYMENT_ENV,
        // Specify a version number to identify the deployed version of your application in Datadog
        version: '1.0.0',
        sessionSampleRate: 100,
        sessionReplaySampleRate: 50,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
        allowedTracingUrls: [`${process.env.REACT_APP_API_URL}`],
    });

export default App;
