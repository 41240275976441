import { getSmoothStepPath, BaseEdge, EdgeLabelRenderer } from 'reactflow';
const Edges = ({ id, sourceX, sourceY, targetX, targetY, sourcePosition, targetPosition, label, data }) => {
    const [edgePath, labelX, labelY] = getSmoothStepPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    });

    const getTransform = (type = 'middle') => {
        const obj = {
            start: `translate(-50%, 0%) translate(${sourceX}px,${sourceY}px)`,
            middle: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            end: `translate(-50%, -100%) translate(${targetX - 50}px,${targetY}px)`,
        };

        return obj[type];
    };
    return (
        <>
            <BaseEdge
                id={id}
                path={edgePath}
                style={{ stroke: 'var(--main-color)', strokeWidth: '2px', position: 'relative', zIndex: 999 }}
            />
            <EdgeLabelRenderer>
                <div className="edge-label" style={{ transform: getTransform(data?.edgePosition) }}>
                    {label}
                </div>
            </EdgeLabelRenderer>
        </>
    );
};

export default Edges;
