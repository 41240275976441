import { create } from 'zustand';

//============================== UPLOAD STORE ====================

const state = {
    selectedFile: {},
};

export const useUploadStore = create((set) => ({
    ...state,
    onChangeUploadStore: (newState) => set((state) => ({ ...state, ...newState })),
    onResetUploadtore: () => set(() => ({ ...state })),
}));

//============================== HIStORY STORE ====================

const historyState = {};

export const useHistoryStore = create((set) => ({
    ...historyState,
    onChangeHistoryStore: (newState) => set((state) => ({ ...state, ...newState })),
    onResetHistoryStore: () => set(() => ({ ...historyState })),
}));

//============================== ERROR STORE ====================

const errorState = {
    errors: [],
    isLoadingErrorTable: false,
};

export const useErrorStore = create((set) => ({
    ...errorState,
    onChangeErrorStore: (newState) => set((state) => ({ ...state, ...newState })),
    onResetErroStore: () => set(() => ({ ...errorState })),
}));
