import { Fragment } from 'react';
import { Text, Input } from '@chakra-ui/react';
import { ConditionalComponent } from '@components/functionComponent';
import './styles.scss';

const FieldInput = ({ label, placeholder, type, autoComplete = '', onChange, isRequired, value }) => {
    return (
        <Fragment>
            <Text className="label">{label}</Text>
            <Input
                className={`field-input__container ${isRequired && 'field-required__button'}`}
                placeholder={placeholder}
                type={type}
                autoComplete={autoComplete}
                onChange={(e) => onChange(e.target.value)}
                value={value}
            />
            {/* //====================== REQUIRED TEXT =========================== */}

            <ConditionalComponent statement={isRequired}>
                <Text className="field-required__text">Field is required!</Text>
            </ConditionalComponent>
        </Fragment>
    );
};

export default FieldInput;
