import React from 'react';
import { Flex, Box, Text } from '@chakra-ui/react';
import { currencyFormatted } from '@pages/Achievement/function';
import './styles.scss';

const TableColumn = ({ data, tableList }) => {
    return (
        <Flex className={`table-column__${data.type} table-column table-column__container__achievement`}>
            {data?.type === 'all' ? (
                <>
                    <Box w="264px" className="column__item" p="8px 0">
                        <Text className="column-text__child">{data?.name}</Text>
                    </Box>
                    <Box flex={1} className="column__item" p="8px 0">
                        <Text className="column-text__child">
                            Rp {currencyFormatted({ value: Number(data?.value) })}
                        </Text>
                    </Box>
                </>
            ) : (
                <>
                    {tableList?.map((item, idx) => {
                        return (
                            <Box key={item.name} w={item.width} className="column__item" id={`column__item--${idx}`}>
                                {item.component({
                                    value: data[item.value],
                                    type: data.type,
                                })}
                            </Box>
                        );
                    })}
                </>
            )}
        </Flex>
    );
};

export default TableColumn;
