import { Flex, Box, Input } from '@chakra-ui/react';
import DatePicker from 'react-multi-date-picker';
import { isArray } from 'lodash';
import moment from 'moment';

import { CalendarIcon, CloseIcon } from '@assets/icons';
import { ConditionalComponent } from '@components/functionComponent';
import './styles.scss';

const FilterDate = ({ placeholder = '', onChange, isMulti, selected, onReset }) => {
    const filterDateSelected = isArray(selected) ? "Multiple" : selected ? moment(new Date(selected)).format('DD MMMM YYYY') : ''
    return (
        <Flex className="filter-date-container">
            <DatePicker
                onChange={onChange}
                multiple={isMulti}
                offsetX={-100}
                calendarPosition="bottom-right"
                value={selected}
                onOpenPickNewDate={false}
                render={(_, openCalendar) => (
                    <Box className="box-filter" onClick={openCalendar}>
                        <Input
                            className="search-input"
                            placeholder={placeholder}
                            value={filterDateSelected}
                            onChange={() => { }}
                        />
                        <Box className="search-icon">
                            <ConditionalComponent
                                statement={selected}
                                fallback={<CalendarIcon color="var(--main-color)" />}
                            >
                                <CloseIcon color="grey" onClick={onReset} />
                            </ConditionalComponent>
                        </Box>
                    </Box>
                )}
            />
        </Flex>
    );
};

export default FilterDate;
