import { Box, Text } from '@chakra-ui/react';
import './styles.scss';
import { IterationComponent } from '@components/functionComponent';
import listNavbar from '../../data/documentations.json';
import { useLocation, useNavigate } from 'react-router-dom';

const DocumentationNavbar = () => {
    const { navbar } = listNavbar;
    const navigate = useNavigate();
    const { search } = useLocation();

    return (
        <Box className="documentation-navbar">
            <IterationComponent
                arr={navbar}
                render={({ name, icon, path }) => (
                    <Box
                        className={`navbar-item ${search?.includes(path) && 'navbar-item__actived'} `}
                        onClick={() => navigate(`/documentation?pageName=${path}`)}
                    >
                        <Box dangerouslySetInnerHTML={{ __html: icon }} />
                        <Text className="navbar-text">{name}</Text>
                    </Box>
                )}
            />
        </Box>
    );
};

export default DocumentationNavbar;
