import { Flex, Box, Text, Skeleton } from '@chakra-ui/react';
import { ConditionalComponent, IterationComponent } from '@components/functionComponent';

import './styles.scss';



const Tabs = ({ className, options, isTabActive, onChangeTab, isLoading }) => (
    <Flex className={`tabs-container__popup ${className}`}>
        <ConditionalComponent
            statement={!isLoading}
            fallback={<IterationComponent arr={[1, 2, 3]} render={() => <Skeleton className="tab-item" />} />}
        >
            <IterationComponent
                arr={options}
                render={({ name, value, logo_active, logo_disabled, logo_nonactive, isActive }) => (
                    <Box
                        className={`tab-item ${isTabActive === value && 'tab-active'} ${!isActive && 'tab-inactive'}`}
                        onClick={() => isActive && onChangeTab({ value })}
                    >
                        <Box
                            dangerouslySetInnerHTML={{
                                __html: iconHandler({
                                    isActive,
                                    isSelected: isTabActive === value,
                                    logo_active,
                                    logo_disabled,
                                    logo_nonactive,
                                }),
                            }}
                        />
                        <Text className="tab-name">{name}</Text>
                    </Box>
                )}
            />
        </ConditionalComponent>
    </Flex>
);

export default Tabs;

//================================= ASSETS ====================================

const iconHandler = ({ isSelected, isActive, logo_active, logo_disabled, logo_nonactive }) => {
    if (isSelected) return logo_active;
    if (isActive) return logo_nonactive;

    return logo_disabled;
};