import { find } from 'lodash';

export const onCheckSelectedParentPath = ({ children, pathname, path }) => {
    if (children?.length) {
        return children?.filter(({ path }) => pathname === path)?.length > 0;
    }


    return path === pathname;
};

export const filterRouteList = (routeList, menuAccess) => {
    return routeList?.filter(({ showInSideBar, id }) => showInSideBar && find(menuAccess, { link: id })?.link);
};
