import { Button, Box, Text, Flex } from '@chakra-ui/react';
import { ChevronDownIcon, CopyIcon } from '@chakra-ui/icons';
import { useEffect, useState } from 'react';
import copy from 'copy-to-clipboard';

import { useUserInfo } from '@store/userInfo';
import { useSnackbarNotification } from '@store/snackbarNotification';
import { useDownloadNotification } from '@store/downloadNotification';
import { ConditionalComponent } from '@components/functionComponent';
import Badge from '@components/badge';

import { onDownloadFileError } from '@pages/UploadSellout/helpers/crud';
import './styles.scss';

export const BadgeStatus = ({ status }) => {
    const statusMap = {
        success: { title: 'Success', color: '#4B4B4B', backgroundColor: '#EAF6E9' },
        cancelled: { title: 'Cancelled', color: '#F24822', backgroundColor: '#FFC7C2' },
        failed: { title: 'Failed', color: '#F24822', backgroundColor: '#FFC7C2' },
        'unhandled file format': {
            title: 'Unhandled File Format',
            color: '#F24822',
            backgroundColor: '#FFC7C2',
        },
        uploaded: { title: 'Uploaded', color: '#457383', backgroundColor: '#dbedf3' },
        pending: { title: 'Pending', color: '#D47C00', backgroundColor: '#FFE8A3' },
        processing: { title: 'Processing', color: '#457383', backgroundColor: '#dbedf3' },
    };

    const statusInfo = statusMap[status];

    return <Badge {...statusInfo} />;
};

export const ActionErrorColumn = ({ value, values }) => {
    const { onChangeDownloadNotification } = useDownloadNotification();
    const { onChangeSnackbarNotification } = useSnackbarNotification();

    const copyToClipboard = () => {
        copy(
            `nama toko: ${values?.nama_outlet},\r\nemail: ${values?.email},\r\ntype upload: ${
                values?.upload_type_detail
            },\r\ndate upload: ${values?.created_at},\r\nerror status: 400,\r\nerror message: ${values?.line.substring(
                0,
                50
            )} - ${values?.description}`
        );
        onChangeSnackbarNotification({
            status: 'success',
            title: 'Berhasil',
            description: `Text berhasil di copy ke clipboard`,
            duration: 3,
        });
    };
    return (
        <Flex gap="12px">
            <Button
                className="column-download"
                onClick={() => onDownloadFileError({ path: value, onChangeDownloadNotification })}
            >
                Download
            </Button>

            <Button className="column-copy" onClick={copyToClipboard}>
                <CopyIcon fontSize="16px" />
                Copy
            </Button>
        </Flex>
    );
};

export const ErrorMessageColumn = ({ value, values }) => {
    const { onChangeDownloadNotification } = useDownloadNotification();
    const [isShowSeeMore, setIsShowSeeMore] = useState(false);

    useEffect(() => {
        const el = document.getElementById('error-column__upload-sellout');
        if (el?.clientHeight > 130) setIsShowSeeMore(true);
    }, []);

    return (
        <Box className="error-column">
            <Text className="column-text error-rows" id="error-column__upload-sellout">
                {value}
            </Text>
            <ConditionalComponent statement={isShowSeeMore}>
                <span onClick={() => onDownloadFileError({ path: values?.url_file, onChangeDownloadNotification })}>
                    Lihat selengkapnya <ChevronDownIcon fontSize="20px" />
                </span>
            </ConditionalComponent>
        </Box>
    );
};
