import { create } from 'zustand';

export const useAssetsStore = create((set) => ({
    brands: [],
    onChangeAssetsStore: (newState) => set((state) => ({ ...state, ...newState })),
    onResetAssetsStore: () =>
        set(() => ({
            brands: [],
        })),
}));
