import { Skeleton, Box, Flex } from '@chakra-ui/react';
import './styles.scss';

const HeadSkeleton = () => (
    <Flex className="head-skeleton__audit-trail">
        <Flex alignItems="center">
            <Box className="search-filter">
                <Skeleton
                    height="28px"
                    w="240px"
                    borderRadius="4px"
                    startColor="var(--main-color-light-active)"
                    endColor={`rgba(var(--main-color),0.5)`}
                    mr="8px"
                />
                <Skeleton
                    height="28px"
                    w="48px"
                    borderRadius="4px"
                    startColor="var(--main-color-light-active)"
                    endColor={`rgba(var(--main-color),0.5)`}
                />
            </Box>

            <Box className="filter">
                <Skeleton
                    height="28px"
                    w="84px"
                    borderRadius="4px"
                    startColor="var(--main-color-light-active)"
                    endColor={`rgba(var(--main-color),0.5)`}
                    mr="8px"
                />
                <Skeleton
                    height="28px"
                    w="28px"
                    borderRadius="4px"
                    startColor="var(--main-color-light-active)"
                    endColor={`rgba(var(--main-color),0.5)`}
                />
            </Box>
            <Box className="filter">
                <Skeleton
                    height="28px"
                    w="84px"
                    borderRadius="4px"
                    startColor="var(--main-color-light-active)"
                    endColor={`rgba(var(--main-color),0.5)`}
                    mr="8px"
                />
                <Skeleton
                    height="28px"
                    w="28px"
                    borderRadius="4px"
                    startColor="var(--main-color-light-active)"
                    endColor={`rgba(var(--main-color),0.5)`}
                />
            </Box>
        </Flex>
        <Flex alignItems="center">
            <Box className="add-button">
                <Skeleton
                    height="28px"
                    w="48px"
                    borderRadius="4px"
                    startColor="var(--main-color)"
                    endColor={`rgba(var(--main-color),0.5)`}
                    mr="8px"
                />
                <Skeleton
                    height="28px"
                    w="240px"
                    borderRadius="4px"
                    startColor="var(--main-color)"
                    endColor={`rgba(var(--main-color),0.5)`}
                />
            </Box>
            <Skeleton
                height="28px"
                w="16px"
                borderRadius="4px"
                startColor="var(--main-color)"
                endColor={`rgba(var(--main-color),0.5)`}
            />
        </Flex>
    </Flex>
);

export default HeadSkeleton;
