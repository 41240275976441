import { Box, Text } from '@chakra-ui/react';
import { Element } from '@components/functionComponent';
import './styles.scss';

const AddButton = ({ icon, text, onClick }) => {
    return (
        <Box className="add-button__container" onClick={onClick}>
            <Element value={icon} />
            <Text className="button-text">{text}</Text>
        </Box>
    );
};

export default AddButton;
