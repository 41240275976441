import { Box, Button, Text } from '@chakra-ui/react';
import { IconDesc } from './icons';
import './styles.scss';
import FloatingMenu from '@components/floatingMenu';
import { ConditionalComponent } from '@components/functionComponent';
import { useState } from 'react';

const Sort = ({ onChangeSort, selected }) => {
    const [isSorted, setIsSorted] = useState(false);
    const sortList = [
        <Box
            className={`sort-item ${selected === 'DESC' && 'sort-selected'}`}
            onClick={() => {
                onChangeSort('DESC');
                setIsSorted(true);
            }}
        >
            <Text>Terbaru</Text>
            <Box className="circle-active" />
        </Box>,
        <Box
            className={`sort-item ${selected === 'ASC' && 'sort-selected'}`}
            onClick={() => {
                onChangeSort('ASC');
                setIsSorted(true);
            }}
        >
            <Text>Terlama</Text>
            <Box className="circle-active" />
        </Box>,
    ];
    return (
        <Box className="sort-container">
            <ConditionalComponent statement={isSorted}>
                <Box className="sort-active" />
            </ConditionalComponent>
            <FloatingMenu
                isClickMenu
                list={sortList}
                buttonElement={
                    <Button className="sort-button">
                        <IconDesc />
                    </Button>
                }
            />
        </Box>
    );
};

export default Sort;
