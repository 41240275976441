import { Button, Box, Text } from '@chakra-ui/react';
import CountUp from 'react-countup';

import { ConditionalComponent } from '@components/functionComponent';
import './styles.scss';

const ButtonLoadingBar = ({ styles, className, children, isLoading, onClick }) => {
    return (
        <Box className="button-loading-bar__container">
            <ConditionalComponent statement={!isLoading}>
                <Button className={className} style={{ ...styles }} onClick={onClick}>
                    {children}
                </Button>
            </ConditionalComponent>
            <ConditionalComponent statement={isLoading}>
                <Button className={`button-loader ${className}`} style={{ ...styles }}>
                    <Box className="progress-button" />
                    <Text className="progress-text">
                        <CountUp end={99} duration={15} useEasing={false} />%
                    </Text>
                </Button>
            </ConditionalComponent>
        </Box>
    );
};

export default ButtonLoadingBar;
