import { Box } from '@chakra-ui/react';
import Upload from './sections/upload';
import Reminder from './sections/reminder';

import './styles.scss';

const UploadMapping = () => {
    return (
        <Box className="upload-sellout__container">
            <Reminder />
            <Upload />
        </Box>
    );
};

export default UploadMapping;
