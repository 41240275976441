import Cookies from 'universal-cookie';
import Axios from '@services/axios';
import { onRemoveAllCookies } from '@helpers/events';

export const onLogoutHandler = async ({ onResetRouteList, navigate, onChangeSnackbarNotification, onChangeLoader }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    try {
        onChangeLoader({ isLoadingPage: true });

        //============================ LOGOUT REQUEST ========================

        await Axios({
            url: '/api/logout',
            method: 'POST',
            headers: { Authorization: 'Bearer ' + userToken },
        });

        //============================ REMOVE COOKIES  ========================

        onRemoveAllCookies();

        onChangeSnackbarNotification({
            status: 'success',
            title: 'Logout berhasil',
            description: 'Harap tunggu, anda akan keluar',
            duration: 3,
        });

        //========================== RESET ROUTE TO LOGIN ONLY ===================

        onResetRouteList();

        //============================ NAVIGATE  ========================

        navigate('/login');

        onChangeLoader({ isLoadingPage: false });
    } catch (err) {
        console.log(err);
        const errorStatus = err?.response?.status;

        if (errorStatus === 401) {
            onRemoveAllCookies();

            setTimeout(() => {
                //========================== RESET ROUTE TO LOGIN ONLY ===================

                onResetRouteList();

                //============================ NAVIGATE  ========================

                navigate('/login');

                onChangeLoader({ isLoadingPage: false });
            }, 2000);

            return;
        }

        const errorMessage = err?.response?.data?.message || 'Something wrong!';

        onChangeSnackbarNotification({
            status: 'error',
            title: 'Logout gagal',
            description: errorMessage,
            duration: 5,
        });

        onChangeLoader({ isLoadingPage: false });
    }
};
