import { Skeleton } from '@chakra-ui/react';
import { ConditionalComponent } from '@components/functionComponent';

const SkeletonContainer = ({ isLoading, children, height, width, color }) => (
    <>
        <ConditionalComponent statement={isLoading}>
            <Skeleton w={width} h={height} startColor={color} endColor={`rgba(#${color},0.5)`} />
        </ConditionalComponent>
        <ConditionalComponent statement={!isLoading}>{children}</ConditionalComponent>
    </>
);

export default SkeletonContainer;
