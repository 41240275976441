import { Flex, Text } from '@chakra-ui/react';
import { SagitariusLogo } from '@assets/logo';

import './styles.scss';

const DocumentationHeader = () => (
    <Flex className="documentation-header__container">
        <Flex className="documentation-header__wrapper">
            <SagitariusLogo />
            <Text className="doc-text">Docs</Text>
        </Flex>
    </Flex>
);

export default DocumentationHeader; 
