import { create } from 'zustand';

const state = {
    status: null,
    errorMessages: null,
};

export const usePopUpNotification = create((set) => ({
    ...state,
    onChangePopUpNotification: (newState) => set((state) => ({ ...state, ...newState })),
    onResetPopUpNotification: () => set(() => ({ ...state })),
}));
