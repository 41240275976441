import { Box } from '@chakra-ui/react';

import { getTimeUploadDescription, getLeftIconPosition } from '@pages/UploadSellout/helpers/utils';
import { SeeAllIcon, ArrowConnectorIcon } from '../../assets/icons';

const TimeUploadDescription = ({ selectedTimeUploadType, timeUploadOptions }) => (
    <Box className="description-selected-time-upload__container">
        <ArrowConnectorIcon style={{ left: getLeftIconPosition({ type: selectedTimeUploadType }) }} />
        {getTimeUploadDescription({ selected: selectedTimeUploadType, options: timeUploadOptions })}
        <a href="https://s.id/info-sagita" target="_blank" rel="noreferrer">
            <span>
                Baca selengkapnya
                <SeeAllIcon />
            </span>
        </a>
    </Box>
);

export default TimeUploadDescription;
