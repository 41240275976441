import { InfoIcon, SuccessIcon, WarningIcon } from './icons';

export const getColor = ({ status }) => {
    const defaultColor = {
        borderColor: '#2C54BA',
        backgroundColor: '#EAEEF8',
        iconColor: '#2C54BA',
    };

    const obj = {
        info: {
            borderColor: '#2C54BA',
            backgroundColor: '#EAEEF8',
            iconColor: '#2C54BA',
        },
        error: {
            borderColor: '#E03138',
            backgroundColor: '#FCEAEB',
            iconColor: '#E03138',
        },
        failed: {
            borderColor: '#E03138',
            backgroundColor: '#FCEAEB',
            iconColor: '#E03138',
        },
        warning: {
            borderColor: '#FFC043',
            backgroundColor: '#FFF9EC',
            iconColor: '#FFC043',
        },
        success: {
            borderColor: '#2EA31F',
            backgroundColor: '#EAF6E9',
            iconColor: '#2EA31F',
        },
    };

    return obj[status] || defaultColor;
};

export const Icon = ({ color, status }) => {
    const obj = {
        info: <InfoIcon color={color} />,
        error: <InfoIcon color={color} />,
        failed: <InfoIcon color={color} />,
        warning: <WarningIcon />,
        success: <SuccessIcon />,
    };

    return obj[status] || <InfoIcon color={color} />;
};
