import Cookies from 'universal-cookie';
import { Box } from '@chakra-ui/react';

import TableTemplate from '@components/tableTemplate';
import { useAuditTrailFilterStore, useAuditTrailStore } from '@pages/AuditTrail/helpers/mainState';

import { tableListStore, TableListNotStore } from './const';
import './styles.scss';

const Table = () => {
    const cookies = new Cookies();
    const userRole = cookies.get('role');
    const tableList = userRole?.includes('super user') ? tableListStore : TableListNotStore;
    const { paginationInfo, isLoadingTable, trails } = useAuditTrailStore();
    const { onChangeAuditTrailFilterSelectedStore, page } = useAuditTrailFilterStore();

    const handlePageClick = ({ selected }) => onChangeAuditTrailFilterSelectedStore({ page: selected + 1 });


    return (
        <Box className="table-audit-trail__container">
            <TableTemplate
                tableList={tableList}
                data={trails}
                dataCount={paginationInfo?.dataCount}
                totalData={paginationInfo?.totalData}
                page={page}
                totalPage={paginationInfo?.totalPage}
                handlePageClick={handlePageClick}
                isPagination={true}
                isDynamicTable={false}
                isLoading={isLoadingTable}
                overflowTable="visible"
            />
        </Box>
    );
};

export default Table;
