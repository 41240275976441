import { Flex, Text, Box } from '@chakra-ui/react';

import FilterDropdown from '@components/filter/dropdown';
import { ConditionalComponent } from '@components/functionComponent';
import HeadSkeleton from '@pages/StockTrail/components/headSkeleton';
import {
    useFilterDCOptionStore,
    useStockTrailFilterStore,
    useStockTrailStore,
    useFilterCustomerOptionStore,
    useGroupOptionStore,
    useStatusOptionStore,
} from '@pages/StockTrail/helpers/mainState';
import { fetchDC, fetchCustomer, fetchStoreGroup, fetchStatus } from '@pages/StockTrail/helpers/crud';
import FilterSearch from '@components/filter/search';
import './styles.scss';
import Sort from '@components/filter/sort';
import FilterDate from '@components/filter/date';
import Cookies from 'universal-cookie';

const Head = () => {
    const cookies = new Cookies();
    const userRole = cookies.get('role');
    const { onChangeFilterDCOptionStore, dcOptions, isLoadingDCOption } = useFilterDCOptionStore();
    const {
        onChangeFilterCustomerOptionStore,
        customerOptions,
        isLoadingCustomerOption,
        searchCustomerQuery,
        onResetilterCustomerOptionStore,
    } = useFilterCustomerOptionStore();
    const {
        isLoadingStoreGroupOption,
        storeGroupOptions,
        searchStoreGroupQuery,
        onChangeFilterStoreGroupOptionStore,
        onResetilterStoreGroupOptionStore,
    } = useGroupOptionStore();
    const { isLoadingStatusOption, statusOptions, onChangeFilterStatusOptionStore } = useStatusOptionStore();

    const { onChangeStockTrailFilterSelectedStore, selectedSorting, dateUploadSelected } = useStockTrailFilterStore();
    const { isLoadingHead } = useStockTrailStore();

    const onFetchDC = () => fetchDC({ onChangeFilterDCOptionStore });
    const onFetchStatus = () => fetchStatus({ onChangeFilterStatusOptionStore });

    const onFetchCustomer = ({ searchQuery }) => fetchCustomer({ onChangeFilterCustomerOptionStore, searchQuery });
    const onFetchStoreGroup = ({ searchQuery }) =>
        fetchStoreGroup({ onChangeFilterStoreGroupOptionStore, searchQuery });
    return (
        <Box className="section-head__stock-trail">
            <Text className="title">Stock</Text>
            <ConditionalComponent statement={!isLoadingHead} fallback={<HeadSkeleton />}>
                <Flex alignItems="center" justifyContent="space-between">
                    {/* //==================== LEFT SECTION ==================== */}

                    <Flex alignItems="center">
                        <FilterDate
                            onReset={() => onChangeStockTrailFilterSelectedStore({ dateUploadSelected: null, page: 1 })}
                            onChange={(date) =>
                                onChangeStockTrailFilterSelectedStore({ dateUploadSelected: new Date(date), page: 1 })
                            }
                            selected={dateUploadSelected}
                            placeholder="Date Upload"
                        />
                        <ConditionalComponent statement={userRole === 'National Leaders'}>
                            <FilterDropdown
                                placeholder="DC"
                                options={dcOptions}
                                maxFilter={3}
                                onFetchData={onFetchDC}
                                isLoading={isLoadingDCOption}
                                filterId="filter-dc__stock-trail"
                                onSelect={({ selected }) =>
                                    onChangeStockTrailFilterSelectedStore({ selectedDC: selected, page: 1 })
                                }
                            />
                        </ConditionalComponent>
                        <ConditionalComponent
                            statement={userRole === 'Area Leaders' || userRole === 'National Leaders'}
                        >
                            <FilterSearch
                                placeholder="Group"
                                options={storeGroupOptions}
                                maxFilter={3}
                                searchQuery={searchStoreGroupQuery}
                                onClose={onResetilterStoreGroupOptionStore}
                                onSearchQuery={(value) =>
                                    onChangeFilterStoreGroupOptionStore({ searchStoreGroupQuery: value })
                                }
                                onFetchData={({ searchQuery }) => onFetchStoreGroup({ searchQuery })}
                                isLoading={isLoadingStoreGroupOption}
                                filterId="filter-store-group__stock-trail"
                                onSelect={({ selected }) =>
                                    onChangeStockTrailFilterSelectedStore({ selectedStoreGroup: selected, page: 1 })
                                }
                            />

                            <FilterSearch
                                placeholder="Store"
                                options={customerOptions}
                                maxFilter={3}
                                searchQuery={searchCustomerQuery}
                                onClose={onResetilterCustomerOptionStore}
                                onSearchQuery={(value) =>
                                    onChangeFilterCustomerOptionStore({ searchCustomerQuery: value })
                                }
                                onFetchData={({ searchQuery }) => onFetchCustomer({ searchQuery })}
                                isLoading={isLoadingCustomerOption}
                                filterId="filter-customer__stock-trail"
                                onSelect={({ selected }) =>
                                    onChangeStockTrailFilterSelectedStore({ selectedStore: selected, page: 1 })
                                }
                            />
                        </ConditionalComponent>

                        <FilterDropdown
                            isMultiple={false}
                            placeholder="Status"
                            options={statusOptions}
                            maxFilter={1}
                            onFetchData={onFetchStatus}
                            isLoading={isLoadingStatusOption}
                            filterId="filter-status__stock-trail"
                            onSelect={({ selected }) =>
                                onChangeStockTrailFilterSelectedStore({ selectedStatus: selected, page: 1 })
                            }
                        />
                    </Flex>

                    {/* //==================== RIGHT SECTION ==================== */}

                    <Flex alignItems="center">
                        <Sort
                            selected={selectedSorting}
                            onChangeSort={(value) => onChangeStockTrailFilterSelectedStore({ selectedSorting: value })}
                        />
                    </Flex>
                </Flex>
            </ConditionalComponent>
        </Box>
    );
};

export default Head;
