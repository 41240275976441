import { Box, Button, Text } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { useCallback } from 'react';
import './styles.scss';
import { useAdditionalFlow, useDataFlow, useViewPosition } from '../../helpers/mainState';
import { ConditionalComponent } from '@components/functionComponent';
import { EdgeIcon, GroupIcon, NodeIcon, NodeSource, NodeTarget, NoteIcon } from '../../icons';
import { nodes } from 'slate';
import { onChangeCursor } from '../../helpers/events';
import OutsideClickHandler from 'react-outside-click-handler';

const ButtonAdd = ({ setNodes }) => {
    const { onChangeAdditionalFlow, isShowAddType } = useAdditionalFlow();

    return (
        <Box className="button-add-flow__container">
            {/* //=================== ADD TYPE ============ */}
            <ConditionalComponent statement={isShowAddType}>
                <OutsideClickHandler onOutsideClick={() => onChangeAdditionalFlow({ isShowAddType: false })}>
                    <Box className="add-type__container">
                        <Text
                            className="add-type__item"
                            onClick={() => {
                                onChangeAdditionalFlow({ addType: 'default-note' });
                                onChangeCursor('crosshair');
                            }}
                        >
                            <NoteIcon color="black" />
                            Note
                        </Text>

                        <Text
                            className="add-type__item"
                            onClick={() => {
                                onChangeAdditionalFlow({ addType: 'node' });
                                onChangeCursor('crosshair');
                            }}
                        >
                            <NodeIcon color="black" />
                            Node
                        </Text>
                        <Text
                            className="add-type__item"
                            onClick={() => {
                                onChangeAdditionalFlow({ addType: 'node-source' });
                                onChangeCursor('crosshair');
                            }}
                        >
                            <NodeSource color="black" />
                            Node Input
                        </Text>
                        <Text
                            className="add-type__item"
                            onClick={() => {
                                onChangeAdditionalFlow({ addType: 'node-target' });
                                onChangeCursor('crosshair');
                            }}
                        >
                            <NodeTarget color="black" />
                            Node Output
                        </Text>
                        <Text
                            className="add-type__item"
                            onClick={() => {
                                onChangeAdditionalFlow({ addType: 'group' });
                                onChangeCursor('crosshair');
                            }}
                        >
                            <GroupIcon color="black" />
                            Group
                        </Text>
                    </Box>
                </OutsideClickHandler>
            </ConditionalComponent>
            {/* //=================== ADD BUTTON ============ */}
            <Button className="button-add__element" onClick={() => onChangeAdditionalFlow({ isShowAddType: true })}>
                Tambah <AddIcon />
            </Button>
        </Box>
    );
};

export default ButtonAdd;
