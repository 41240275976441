export const DeleteIcon = () => (
    <svg width="200" height="200" viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
        {/* <circle cx="75" cy="75Z" r="75" fill="var(--main-color-light-hover)" /> */}
        <path
            d="M53.125 54.5835V104.167H96.875V54.5835H53.125Z"
            stroke="black"
            strokeWidth="5"
            strokeLinejoin="round"
        />
        <path
            d="M69.1667 69.1665V88.1248"
            stroke="black"
            strokeWidth="5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M80.8333 69.1665V88.1248"
            stroke="black"
            strokeWidth="5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M45.8333 54.5835H104.167"
            stroke="black"
            strokeWidth="5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M63.3333 54.5835L68.1298 45.8335H81.9666L86.6667 54.5835H63.3333Z"
            stroke="black"
            strokeWidth="5"
            strokeLinejoin="round"
        />
    </svg>
);

export const WarningIcon = () => (
    <svg
        className="warning-icon"
        width="100"
        height="100"
        viewBox="0 0 8 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="4" cy="12" r="0.5" fill="#CCD2E3" stroke="black" />
        <path
            d="M4 10V8.58114C4 7.6369 4.60421 6.7986 5.5 6.5V6.5C6.39579 6.2014 7 5.3631 7 4.41886V3.90569C7 2.30092 5.69908 1 4.09431 1H4C2.34315 1 1 2.34315 1 4V4"
            stroke="black"
            strokeWidth="2"
        />
    </svg>
);