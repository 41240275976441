import { ChevronDownIcon } from '@pages/UploadMapping/assets/icons';
import { Button, Box } from '@chakra-ui/react';
import FloatingMenu from '@components/floatingMenu';
import { onDownloadTemplate } from '@pages/UploadMapping/helpers/crud';
import { useDownloadNotification } from '@store/downloadNotification';

const DownloadMenu = () => {
    const { onChangeDownloadNotification } = useDownloadNotification();
    const listElement = [
        <Box
            className="download-menu__item"
            onClick={() => onDownloadTemplate({ templateType: 'empty-template', onChangeDownloadNotification })}
        >
            Template Kosong
        </Box>,
        <Box
            className="download-menu__item"
            onClick={() => onDownloadTemplate({ templateType: 'default-template', onChangeDownloadNotification })}
        >
            Template Berisi Produk
        </Box>,
    ];
    return (
        <Box className="download-menu__container">
            <FloatingMenu
                list={listElement}
                isClickMenu
                className="floating-menu__download__upload"
                buttonElement={
                    <Button className="download-menu__button">
                        Download template
                        <ChevronDownIcon />
                    </Button>
                }
            />
        </Box>
    );
};

export default DownloadMenu;
