import { find } from 'lodash';

export const fetchGlobalStyles = () => {
    process.env.REACT_APP_BASE_CODE === 'development'
        ? require('../@styles/development-styles')
        : require('../@styles/production-styles');
};

export const debounce = (func, timeout = 300) => {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            func.apply(this, args);
        }, timeout);
    };
};

export const onCheckInputFile = ({ file, maxSize, accessAllowed, onChangeSnackbarNotification }) => {
    // eslint-disable-next-line

    const size = file?.size;
    const fileSplitName = file?.name.split('.');
    const fileType = fileSplitName[fileSplitName?.length - 1];
    maxSize = maxSize * 1000000;

    if (size && size > maxSize) {
        onChangeSnackbarNotification({
            status: 'error',
            title: 'Failed!',
            description: `Maximal file ${maxSize / 1000000}MB!`,
            duration: 3,
        });

        return 'not-valid';
    }

    if (accessAllowed && !accessAllowed?.includes(fileType)) {
        onChangeSnackbarNotification({
            status: 'error',
            title: 'Failed!',
            description: `File ${fileType} not support!`,
            duration: 3,
        });
        return 'not-valid';
    }
};

export const filteringRouteByMenuAccess = (route, menuAccess) => {
    return route?.filter(({ id, type }) => find(menuAccess, { link: id })?.link || type === '*');
};


export const numToArr = (num) => {
    const arr = [];
    for (let i = 0; i < num; i++) {
        arr.push(i);
    }
    return arr;
};

