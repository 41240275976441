import Cookies from 'universal-cookie';
import Axios from '@services/axios';
// import { isArray } from 'lodash';
import { onTransformedHistory } from '../sections/history/utils';
import { saveAs } from 'file-saver';
import { getDownloadTemplateEndpoint, getUploadDateHandler, getUploadEndpoint } from './utils';
import moment from 'moment';

export const onDownloadTemplate = async ({ templateType, onChangeDownloadNotification }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    const endpoint = getDownloadTemplateEndpoint({ templateType });

    const filename = `${templateType}.xlsx`;

    onChangeDownloadNotification({
        status: 'download',
        title: 'Downloading',
        description: `Harap tunggu, sedang mendownload file "${filename}"`,
    });

    try {
        const { data } = await Axios({
            url: endpoint,
            method: 'GET',
            responseType: 'blob',
            headers: { Authorization: 'Bearer ' + userToken },
        });

        const blob = new Blob([data], { type: 'text/xlsx' });
        saveAs(blob, `${filename}`);

        onChangeDownloadNotification({
            status: 'success',
            title: 'Success',
            description: `Download "${filename}" berhasil`,
        });
    } catch (err) {
        console.log(err);
        onChangeDownloadNotification({
            status: 'error',
            title: 'Failed',
            description: `Download file template gagal`,
        });
    }
};

export const onUploadFile = async ({
    popUpStore,
    onChangePopUpStore,
    onResetPopUpStore,
    onClose,
    onChangePopUpNotification,
    onRefetchUploadData,
    isCanUpload,
    userInfo,
}) => {
    if (!isCanUpload) return;

    const cookies = new Cookies();
    const userToken = cookies.get('userToken');
    let isErrorSendAutoFormat = false;

    const { selectedTimeUploadType, selectedTimeUploadValue, selectedFile, selectedFormatUploadType } = popUpStore;

    onChangePopUpStore({ isUploading: true });

    const start_date = getUploadDateHandler({
        type: selectedTimeUploadType,
        value: selectedTimeUploadValue,
        category: 'start_date',
    });
    const end_date = getUploadDateHandler({
        type: selectedTimeUploadType,
        value: selectedTimeUploadValue,
        category: 'end_date',
    });

    try {
        const url = getUploadEndpoint({ type: selectedFormatUploadType });

        // const brands = getSelectedBrandName({ selectedBrand });

        const formData = new FormData();

        formData.append('file', selectedFile);
        formData.append('status', selectedTimeUploadType);
        formData.append('start_date', start_date);
        formData.append('end_date', end_date);
        // formData.append('brands', `[${brands}]`);

        const { data } = await Axios({
            url: `/api/v1/${url}`,
            method: 'POST',
            headers: { Authorization: 'Bearer ' + userToken },
            data: formData,
        });

        //====================== SEND TRANSLATE ====================

        if (selectedFormatUploadType === 'Auto Format') {
            try {
                await Axios({
                    url: `/api/v1/sellout/translate-send-sellout`,
                    method: 'POST',
                    headers: { Authorization: 'Bearer ' + userToken },
                    data: {
                        payload: data?.payload,
                    },
                });
            } catch (error) {
                isErrorSendAutoFormat = true;

                throw error;
            }
        }

        onChangePopUpNotification({ status: 'success' });
    } catch (err) {
        console.log(err);
        let errorMessages = err?.response?.data?.message || 'something wrong';
        const errorStatus = err?.response?.status;

        if (errorStatus === 500 && selectedFormatUploadType === 'Auto Format' && isErrorSendAutoFormat) {
            errorMessages = 'file yang anda upload belum dapat di auto format oleh server';
        }

        onChangePopUpNotification({
            errorMessages,
            status: 'failed',
            additionalInfo: {
                email: userInfo?.email,
                typeUpload: `${selectedTimeUploadType} - ${selectedFormatUploadType}`,
                storeName: userInfo?.nama_outlet,
                dateUpload: `${moment().format('DD MMMM YYYY HH:mm')} WIB`,
                errorStatus,
            },
        });
    } finally {
        onChangePopUpStore({ isUploading: false });
        onResetPopUpStore();
        onClose();
        onRefetchUploadData();
    }
};

// ================== FETCH BRANDS ====================

export const fetchBrands = async () => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    try {
        const { data } = await Axios({
            url: `/api/v1/brand/list`,
            method: 'GET',
            headers: { Authorization: 'Bearer ' + userToken },
        });

        return data?.data;
    } catch (error) {
        console.log(error);

        return [];
    }
};

export const fetchUploadTypes = async () => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    try {
        const { data } = await Axios({
            url: `/api/v1/declaration/type`,
            method: 'GET',
            headers: { Authorization: 'Bearer ' + userToken },
        });

        return data?.data;
    } catch (error) {
        console.log(error);

        return [];
    }
};

export const fetchTimeUpload = async () => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    try {
        const { data } = await Axios({
            url: `/api/v1/declaration/time`,
            method: 'GET',
            headers: { Authorization: 'Bearer ' + userToken },
        });

        return data?.data;
    } catch (error) {
        console.log(error);

        return [];
    }
};

export const fetchBlockedUpload = async () => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    try {
        const { data } = await Axios({
            url: `/api/v1/sellout/blocked-period`,
            method: 'GET',
            headers: { Authorization: 'Bearer ' + userToken },
        });

        return data;
    } catch (error) {
        console.log(error);

        return [];
    }
};

//================================ FETCH ASSETS ===================

export const fetchAssets = async ({ onChangePopupAssetStore }) => {
    const [brandOptions, uploadTypeOptions, timeUploadOptions, blockedUpload] = await Promise.all([
        fetchBrands(),
        fetchUploadTypes(),
        fetchTimeUpload(),
        fetchBlockedUpload(),
    ]);
    onChangePopupAssetStore({
        brandOptions,
        timeUploadOptions,
        uploadTypeOptions,
        blockedUpload,
        isLoadingAsset: false,
    });
};

//================================ FETCH HISTORY AUDIT TRAIL ===================
export const fetchHistory = async ({ onChangeHistoryStore }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    onChangeHistoryStore({ isLoadingHistoryTable: true });

    try {
        const { data } = await Axios({
            method: 'POST',
            url: `/api/v1/sellout/upload-history`,
            headers: { Authorization: 'Bearer ' + userToken },
        });
        const histories = data?.data;

        const transformedHistory = onTransformedHistory({ histories });

        onChangeHistoryStore({
            history: transformedHistory,
            isLoadingHistoryTable: false,
        });
    } catch (error) {
        console.log(error);
        onChangeHistoryStore({ history: [], isLoadingHistoryTable: false });
    }
};

//================================ FETCH ERROR UPLOAD ===================

export const fetchError = async ({ onChangeErrorStore }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    onChangeErrorStore({ isLoadingErrorTable: true });

    try {
        const fetchedError = await Axios({
            method: 'GET',
            url: `/api/v1/sellout/failed-row`,
            headers: { Authorization: 'Bearer ' + userToken },
        });
        const errors = fetchedError?.data?.data;

        const transformedError = errors.map((error, index) => ({
            ...error,
            no: index + 1,
        }));

        onChangeErrorStore({ errors: transformedError, isLoadingErrorTable: false });
    } catch (error) {
        console.log(error);
        onChangeErrorStore({ errors: [], isLoadingErrorTable: false });
    }
};

export const onDownloadFileError = async ({ path, onChangeDownloadNotification }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    onChangeDownloadNotification({
        status: 'download',
        title: 'Downloading',
        description: `Harap tunggu, sedang mendownload file template`,
    });

    try {
        const { data } = await Axios({
            method: 'POST',
            url: '/api/download-file',
            data: { path },
            responseType: 'blob',
            headers: { Authorization: `Bearer ${userToken}` },
        });
        // eslint-disable-next-line
        const [_, filename] = path.split('/');

        const blob = new Blob([data], { type: 'text/xlsx' });
        saveAs(blob, `${filename}.xlsx`);

        onChangeDownloadNotification({
            status: 'success',
            title: 'Success',
            description: `Download "${filename}" berhasil`,
        });
    } catch (error) {
        console.log(error);

        onChangeDownloadNotification({
            status: 'error',
            title: 'Failed',
            description: `Download file gagal`,
        });
    }
};
