import { Box, Spinner } from '@chakra-ui/react';
import './styles.scss';

const Loader = () => {
    return (
        <Box className="loader-only__container">
            <Spinner size="lg" color="var(--main-color)" />
        </Box>
    );
};

export default Loader;
