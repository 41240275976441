import { Box, CloseButton, Flex, Text } from '@chakra-ui/react';
import './styles.scss';
import { IconLeftBottomCorner, IconLeftTopCorner, IconRightBottomCorner, IconRightTopCorner } from './icons';
import FileInput from '@components/fileInput';
import { ConditionalComponent } from '@components/functionComponent';

const UploadFullZone = ({ onSelectFile, onClose, accessAllowed, maxSize, closeButton, placeholder }) => {
    const onChangeFile = ({ selectedFile, checkedStatus }) => {
        onClose();

        if (checkedStatus === 'not-valid') return;

        onSelectFile(selectedFile);
    };

    const onCloseHandler = (e) => {
        e.preventDefault();
        onClose();
    };

    return (
        <Box className="upload-full-zone__container">
            <FileInput
                accessAllowed={accessAllowed}
                maxSize={maxSize}
                onChange={({ checkedStatus, selectedFile }) => onChangeFile({ checkedStatus, selectedFile })}
            />
            <Flex justifyContent="space-between" flexDirection="column" h="100%">
                <Flex w="100%" justifyContent="space-between" className="boundary-container">
                    <IconLeftTopCorner />
                    <IconRightTopCorner />
                </Flex>
                <Text className="text">{placeholder || 'Drop file anywhere'}</Text>
                <Flex w="100%" justifyContent="space-between" className="boundary-container">
                    <IconLeftBottomCorner />
                    <IconRightBottomCorner />
                </Flex>

                <ConditionalComponent statement={closeButton}>
                    <Box className="close-button" onClick={onCloseHandler}>
                        <CloseButton color="red" fontSize="14px" />
                    </Box>
                </ConditionalComponent>
            </Flex>
        </Box>
    );
};

export default UploadFullZone;
