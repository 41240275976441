import { Flex, Text, Image, Box } from '@chakra-ui/react';
import logo from './forbidden-icon.svg';
import searchItem from './search-icon.svg';

import './styles.scss';

const EmptyState = ({ text, description }) => (
    <Flex className="empty-state-global__container">
        <Box className="icon">
            <Image src={logo} className='empty-image'/>
            <Image src={searchItem} className="search" />
        </Box>
        <Text className="empty-state__text">{text}</Text>
        <Text className="empty-state__desc">{description}</Text>
    </Flex>
);

export default EmptyState;
