export const ChevronLeftIcon = () => (
    <svg width="24" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_378_2624)">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.94002 13.8048C7.65912 13.5163 7.50134 13.1253 7.50134 12.7176C7.50134 12.3099 7.65912 11.9189 7.94002 11.6304L13.596 5.82734C13.8774 5.53887 14.259 5.37686 14.6569 5.37695C14.8539 5.377 15.0489 5.41684 15.2309 5.49421C15.4129 5.57158 15.5783 5.68495 15.7175 5.82785C15.8568 5.97076 15.9672 6.1404 16.0426 6.32709C16.1179 6.51377 16.1567 6.71386 16.1567 6.91591C16.1566 7.11796 16.1178 7.31802 16.0423 7.50467C15.9669 7.69133 15.8564 7.86091 15.717 8.00375L11.122 12.7176L15.718 17.4314C15.8614 17.5733 15.9757 17.743 16.0544 17.9307C16.1331 18.1183 16.1746 18.3202 16.1764 18.5244C16.1782 18.7287 16.1404 18.9313 16.065 19.1204C15.9897 19.3095 15.8784 19.4814 15.7376 19.6259C15.5968 19.7704 15.4294 19.8847 15.2451 19.9622C15.0608 20.0396 14.8633 20.0786 14.6641 20.0769C14.465 20.0753 14.2681 20.0329 14.0851 19.9524C13.902 19.8718 13.7365 19.7547 13.598 19.6079L7.94002 13.8048Z"
                fill="#545454"
            />
        </g>
        <defs>
            <clipPath id="clip0_378_2624">
                <rect width="24" height="24.6154" fill="white" transform="translate(0 0.409912)" />
            </clipPath>
        </defs>
    </svg>
);

export const ChevronRightIcon = () => (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_378_2625)">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.06 11.6048C16.3409 11.8933 16.4987 12.2843 16.4987 12.692C16.4987 13.0997 16.3409 13.4907 16.06 13.7792L10.404 19.5822C10.1226 19.8707 9.741 20.0327 9.34314 20.0326C8.94528 20.0325 8.56376 19.8703 8.2825 19.5817C8.00123 19.2931 7.84327 18.9017 7.84337 18.4937C7.84346 18.0856 8.0016 17.6943 8.283 17.4058L12.879 12.692L8.283 7.97814C8.00962 7.68811 7.85825 7.29957 7.86148 6.89619C7.86471 6.4928 8.02229 6.10686 8.30027 5.82148C8.57825 5.5361 8.9544 5.37412 9.34769 5.37043C9.74099 5.36673 10.12 5.52162 10.403 5.80173L16.06 11.6048Z"
                fill="#545454"
            />
        </g>
        <defs>
            <clipPath id="clip0_378_2625">
                <rect width="24" height="24.6154" fill="white" transform="translate(0 0.384277)" />
            </clipPath>
        </defs>
    </svg>
);

export const CheckPointIcon = () => (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_488_748" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="25">
            <path
                d="M12 22.6921C13.3135 22.6938 14.6143 22.436 15.8278 21.9333C17.0413 21.4306 18.1435 20.6931 19.071 19.7631C20.001 18.8356 20.7385 17.7334 21.2412 16.5199C21.7438 15.3065 22.0017 14.0056 22 12.6921C22.0017 11.3787 21.7438 10.0778 21.2411 8.86436C20.7385 7.65088 20.001 6.5487 19.071 5.62115C18.1435 4.69116 17.0413 3.95365 15.8278 3.45099C14.6143 2.94833 13.3135 2.69045 12 2.69215C10.6865 2.69047 9.3857 2.94837 8.17222 3.45103C6.95875 3.95368 5.85656 4.69118 4.92901 5.62115C3.99904 6.5487 3.26154 7.65088 2.75889 8.86436C2.25623 10.0778 1.99833 11.3787 2.00001 12.6921C1.99831 14.0056 2.25619 15.3065 2.75885 16.5199C3.26151 17.7334 3.99902 18.8356 4.92901 19.7631C5.85656 20.6931 6.95875 21.4306 8.17222 21.9333C9.3857 22.4359 10.6865 22.6938 12 22.6921Z"
                fill="white"
                stroke="white"
                strokeWidth="4"
                strokeLinejoin="round"
            />
            <path
                d="M8 12.6921L11 15.6921L17 9.69214"
                stroke="black"
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </mask>
        <g mask="url(#mask0_488_748)">
            <path d="M0 0.692139H24V24.6921H0V0.692139Z" fill="#2EA31F" />
        </g>
    </svg>
);

export const CurrentPointIcon = () => (
    <svg width="44" height="45" viewBox="0 0 44 45" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M22 4.35889C18.374 4.35889 14.8294 5.43412 11.8145 7.44861C8.79964 9.4631 6.44981 12.3264 5.06221 15.6764C3.6746 19.0263 3.31154 22.7126 4.01893 26.2689C4.72633 29.8252 6.47241 33.0919 9.03637 35.6558C11.6003 38.2198 14.867 39.9659 18.4233 40.6733C21.9797 41.3807 25.6659 41.0176 29.0159 39.63C32.3658 38.2424 35.2291 35.8926 37.2436 32.8777C39.2581 29.8628 40.3333 26.3182 40.3333 22.6922C40.3333 20.2847 39.8591 17.9007 38.9378 15.6764C38.0165 13.4521 36.666 11.431 34.9636 9.7286C33.2612 8.02619 31.2402 6.67576 29.0159 5.75443C26.7916 4.83309 24.4076 4.35889 22 4.35889ZM22 37.3589C19.0992 37.3589 16.2636 36.4987 13.8516 34.8871C11.4397 33.2755 9.55985 30.9849 8.44977 28.3049C7.33968 25.6249 7.04923 22.6759 7.61515 19.8309C8.18106 16.9858 9.57793 14.3725 11.6291 12.3213C13.6803 10.2702 16.2936 8.87329 19.1387 8.30737C21.9837 7.74145 24.9327 8.0319 27.6127 9.14199C30.2927 10.2521 32.5833 12.1319 34.1949 14.5439C35.8065 16.9558 36.6667 19.7914 36.6667 22.6922C36.6667 26.5821 35.1214 30.3126 32.3709 33.0631C29.6204 35.8137 25.8898 37.3589 22 37.3589Z"
            fill="#D68C7E"
        />
    </svg>
);

export const NextPointIcon = () => (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12 2.69214C10.0222 2.69214 8.08879 3.27863 6.4443 4.37744C4.79981 5.47626 3.51809 7.03804 2.76121 8.8653C2.00433 10.6926 1.8063 12.7032 2.19215 14.643C2.578 16.5829 3.53041 18.3647 4.92894 19.7632C6.32746 21.1617 8.10929 22.1141 10.0491 22.5C11.9889 22.8858 13.9996 22.6878 15.8268 21.9309C17.6541 21.1741 19.2159 19.8923 20.3147 18.2478C21.4135 16.6033 22 14.67 22 12.6921C22 11.3789 21.7413 10.0786 21.2388 8.8653C20.7363 7.65205 19.9997 6.54966 19.0711 5.62107C18.1425 4.69249 17.0401 3.95589 15.8268 3.45334C14.6136 2.9508 13.3132 2.69214 12 2.69214ZM12 20.6921C10.4178 20.6921 8.87104 20.2229 7.55544 19.3439C6.23985 18.4648 5.21447 17.2154 4.60897 15.7536C4.00347 14.2918 3.84504 12.6833 4.15372 11.1314C4.4624 9.57957 5.22433 8.1541 6.34315 7.03528C7.46197 5.91646 8.88743 5.15454 10.4393 4.84586C11.9911 4.53717 13.5997 4.6956 15.0615 5.3011C16.5233 5.9066 17.7727 6.93198 18.6518 8.24758C19.5308 9.56317 20 11.1099 20 12.6921C20 14.8139 19.1572 16.8487 17.6569 18.349C16.1566 19.8493 14.1217 20.6921 12 20.6921Z"
            fill="#4B4B4B"
        />
    </svg>
);

export const SkipPointIcon = () => (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_392_4156)">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 24.6919C15.1826 24.6919 18.2348 23.4276 20.4853 21.1772C22.7357 18.9267 24 15.8745 24 12.6919C24 9.5093 22.7357 6.45705 20.4853 4.20661C18.2348 1.95618 15.1826 0.691895 12 0.691895C8.8174 0.691895 5.76515 1.95618 3.51472 4.20661C1.26428 6.45705 0 9.5093 0 12.6919C0 15.8745 1.26428 18.9267 3.51472 21.1772C5.76515 23.4276 8.8174 24.6919 12 24.6919ZM16.7657 17.4576C16.5246 17.6984 16.1979 17.8336 15.8571 17.8336C15.5164 17.8336 15.1896 17.6984 14.9486 17.4576L12 14.509L9.05143 17.4576C8.93372 17.5839 8.79178 17.6852 8.63407 17.7555C8.47635 17.8258 8.3061 17.8636 8.13347 17.8666C7.96083 17.8697 7.78936 17.8379 7.62926 17.7732C7.46917 17.7086 7.32374 17.6123 7.20165 17.4902C7.07956 17.3682 6.98331 17.2227 6.91865 17.0626C6.85398 16.9025 6.82223 16.7311 6.82527 16.5584C6.82832 16.3858 6.8661 16.2155 6.93638 16.0578C7.00665 15.9001 7.10796 15.7582 7.23429 15.6405L10.1829 12.6919L7.23429 9.74332C7.00718 9.49959 6.88354 9.17723 6.88941 8.84414C6.89529 8.51105 7.03023 8.19325 7.26579 7.95769C7.50136 7.72212 7.81916 7.58719 8.15225 7.58131C8.48533 7.57543 8.8077 7.69907 9.05143 7.92618L12 10.8748L14.9486 7.92618C15.0663 7.79986 15.2082 7.69854 15.3659 7.62827C15.5236 7.558 15.6939 7.52021 15.8665 7.51717C16.0392 7.51412 16.2106 7.54588 16.3707 7.61054C16.5308 7.67521 16.6763 7.77145 16.7984 7.89354C16.9204 8.01563 17.0167 8.16106 17.0814 8.32116C17.146 8.48125 17.1778 8.65273 17.1747 8.82536C17.1717 8.998 17.1339 9.16825 17.0636 9.32596C16.9934 9.48367 16.892 9.62562 16.7657 9.74332L13.8171 12.6919L16.7657 15.6405C17.0065 15.8815 17.1417 16.2083 17.1417 16.549C17.1417 16.8898 17.0065 17.2165 16.7657 17.4576Z"
                fill="#E03138"
            />
        </g>
        <defs>
            <clipPath id="clip0_392_4156">
                <rect width="24" height="24" fill="white" transform="translate(0 0.691895)" />
            </clipPath>
        </defs>
    </svg>
);

export const ChevronDownIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M18 9L12 15L6 9"
            stroke="var(--main-color)"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const ChevronTopIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6.5 15L12.5 9L18.5 15"
            stroke="#D68C7E"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const IconCamera = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5 5C4.20435 5 3.44129 5.31607 2.87868 5.87868C2.31607 6.44129 2 7.20435 2 8V16C2 16.7956 2.31607 17.5587 2.87868 18.1213C3.44129 18.6839 4.20435 19 5 19H15C15.7956 19 16.5587 18.6839 17.1213 18.1213C17.6839 17.5587 18 16.7956 18 16V14.414L20.293 16.707C20.4329 16.8468 20.611 16.942 20.805 16.9806C20.9989 17.0192 21.2 16.9993 21.3827 16.9237C21.5654 16.848 21.7215 16.7199 21.8314 16.5555C21.9413 16.391 22 16.1978 22 16V8C22 7.80225 21.9413 7.60895 21.8314 7.44454C21.7215 7.28013 21.5654 7.15199 21.3827 7.07632C21.2 7.00065 20.9989 6.98085 20.805 7.01942C20.611 7.05798 20.4329 7.15319 20.293 7.293L18 9.586V8C18 7.20435 17.6839 6.44129 17.1213 5.87868C16.5587 5.31607 15.7956 5 15 5H5Z"
            fill="#2D7876"
        />
    </svg>
);

export const UploadIcon = () => (
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M25 20.8334V39.5834M25 20.8334L31.25 27.0834M25 20.8334L18.75 27.0834M36.4583 31.25C39.6229 31.25 41.6667 28.6854 41.6667 25.5209C41.6666 24.268 41.2558 23.0497 40.4972 22.0525C39.7386 21.0554 38.6741 20.3344 37.4667 20C37.2809 17.6635 36.3125 15.4579 34.7181 13.7398C33.1237 12.0217 30.9964 10.8916 28.6802 10.5322C26.364 10.1727 23.9943 10.6049 21.9542 11.759C19.914 12.913 18.3227 14.7214 17.4375 16.8917C15.5738 16.3751 13.5812 16.62 11.8981 17.5725C10.2149 18.525 8.97913 20.1072 8.46251 21.9709C7.94589 23.8346 8.19078 25.8272 9.14331 27.5103C10.0958 29.1934 11.678 30.4292 13.5417 30.9459"
            stroke="#868686"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const IconCopy = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M5.41675 5.17951V3.25488C5.41675 2.60767 5.94141 2.08301 6.58862 2.08301H16.7449C17.3921 2.08301 17.9167 2.60767 17.9167 3.25488V13.4111C17.9167 14.0583 17.3921 14.583 16.7449 14.583H14.7985"
            stroke="#005B99"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M13.4114 5.41699H3.25513C2.60792 5.41699 2.08325 5.94166 2.08325 6.58887V16.7451C2.08325 17.3923 2.60792 17.917 3.25513 17.917H13.4114C14.0586 17.917 14.5833 17.3923 14.5833 16.7451V6.58887C14.5833 5.94166 14.0586 5.41699 13.4114 5.41699Z"
            stroke="#005B99"
            strokeWidth="2"
            strokeLinejoin="round"
        />
    </svg>
);