import { Flex, Box, Text } from '@chakra-ui/react';
import { ReminderIllustration } from '@pages/UploadSellout/assets/illustration';

import './styles.scss';

const Reminder = () => {
    return (
        <Flex className="reminder-container__upload-sellout">
            <ReminderIllustration />
            <Box className="reminder-information__container">
                <Text className="title">Yuk, upload mapping</Text>
                <Text className="description">
                    Lengkapi kelengkapan data mapping product kamu melalui <span>Upload Mapping</span> . Terimakasih
                    banyak atas kolaborasinya
                </Text>
            </Box>
        </Flex>
    );
};

export default Reminder;
