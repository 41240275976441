import { create } from 'zustand';

export const useLoginPassword = create((set) => ({
    username: '',
    password: '',
    isLoading: false,
    isOpenPassword: false,

    onChangeLoginPasswordStore: (newState) => set((state) => ({ ...state, ...newState })),
    onLoginLoginPasswordStore: () => {
        set(() => ({
            username: '',
            password: '',
            isLoading: false,
        }));
    },
}));
