//========================== LIBRARY ===========================

import { Box, Flex, Text } from '@chakra-ui/react';

//========================== GLOBAL ASSETS ===========================

import UploadFullZone from '@components/uploadFullZone';
import { UploadIcon } from '@assets/icons';
import FileInput from '@components/fileInput';
import { useDragFile } from '@store/dragFile';
import { ConditionalComponent } from '@components/functionComponent';

//========================== LOCAL ASSETS ===========================

import PopUpNotification from '@pages/UploadSellout/components/popUpNotification';
import TutorialButton from '@pages/UploadSellout/components/tutorialButton';
import DownloadMenu from '@pages/UploadSellout/components/downloadMenu';
import { useUploadStore } from '@pages/UploadSellout/helpers/mainState';
import UploadPopUp from '@pages/UploadSellout/components/uploadPopUp';

import './styles.scss';

const Upload = () => {
    const { isDragFile, onChangeDragFile } = useDragFile();
    const { onChangeUploadStore, selectedFile } = useUploadStore();

    const onChangeFile = ({ selectedFile, checkedStatus }) => {
        if (checkedStatus === 'not-valid') return;
        onChangeUploadStore({ selectedFile });
    };

    return (
        <Box className="upload-container__upload-sellout">
            {/* //========================== HEAD =========================== */}

            <Text className="title">Upload Sellout</Text>
            <Flex className="head">
                <DownloadMenu />
                <TutorialButton />
            </Flex>

            {/* //========================== BODY =========================== */}

            <Flex className="body">
                <Box className={`upload-zone__container ${selectedFile?.name && 'zone-actived'}`}>
                    <UploadIcon color={false ? '#588669' : '#868686'} />
                    <Text className="upload-text">{selectedFile?.name || 'Choose file to upload'}</Text>
                    <FileInput
                        accessAllowed={'.xlsx,.csv,.xls,.pdf'}
                        maxSize={3}
                        onChange={({ selectedFile, checkedStatus }) => onChangeFile({ selectedFile, checkedStatus })}
                    />
                </Box>
            </Flex>

            {/* //========================== ADDITIONAL ASSETS =========================== */}

            <ConditionalComponent statement={selectedFile?.name}>
                <UploadPopUp selectedFile={selectedFile} onClose={() => onChangeUploadStore({ selectedFile: {} })} />
            </ConditionalComponent>

            <PopUpNotification />
            <ConditionalComponent statement={isDragFile}>
                <UploadFullZone
                    maxSize={3}
                    accessAllowed={'.xlsx,.csv,.xls,.pdf'}
                    onSelectFile={(file) => onChangeUploadStore({ selectedFile: file })}
                    onClose={() => onChangeDragFile({ isDragFile: false })}
                />
            </ConditionalComponent>
        </Box>
    );
};

export default Upload;
