import { create } from 'zustand';

const state = {
    trails: [],
    //==================== PAGINATION ================== //
    paginationInfo: {
        dataCount: 0,
        totalData: 0,
        totalPage: 0,
    },
    isLoadingTable: true,
    isLoadingHead: false,
};

export const useStockTrailStore = create((set) => ({
    ...state,
    onChangeStockTrailStore: (newState) => set((state) => ({ ...state, ...newState })),
    onResetStockTrailStore: () => set(() => ({ ...state })),
}));

//========================================= FILTER =========================

const filterSelectedState = {
    selectedDC: [],
    selectedStore: [],
    selectedStatus: null,
    selectedStoreGroup: [],
    selectedUploadType: null,
    dateUploadSelected: null,
    searchQuery: '',
    page: 1,
    selectedSorting: 'DESC',
};

export const useStockTrailFilterStore = create((set) => ({
    ...filterSelectedState,
    onChangeStockTrailFilterSelectedStore: (newState) => set((state) => ({ ...state, ...newState })),
    onResetStockTrailFilterSelectedStore: () => set(() => ({ ...filterSelectedState })),
}));

//========================================= DC =========================
const dcOptionState = {
    isLoadingDCOption: false,
    dcOptions: [],
    searchDCQuery: '',
};

export const useFilterDCOptionStore = create((set) => ({
    ...dcOptionState,
    onChangeFilterDCOptionStore: (newState) => set((state) => ({ ...state, ...newState })),
    onResetilterDCOptionStore: () => set(() => ({ ...dcOptionState })),
}));

//========================================= CUSTOMERS =========================

const customerOptionState = {
    isLoadingCustomerOption: false,
    customerOptions: [],
    searchCustomerQuery: '',
    pageCustomer: 1,
};

export const useFilterCustomerOptionStore = create((set) => ({
    ...customerOptionState,
    onChangeFilterCustomerOptionStore: (newState) => set((state) => ({ ...state, ...newState })),
    onResetilterCustomerOptionStore: () => set(() => ({ ...customerOptionState })),
}));

//========================================= STORE GROUP =========================
const storeGroupOptionState = {
    isLoadingStoreGroupOption: false,
    storeGroupOptions: [],
    searchStoreGroupQuery: '',
};

export const useGroupOptionStore = create((set) => ({
    ...storeGroupOptionState,
    onChangeFilterStoreGroupOptionStore: (newState) => set((state) => ({ ...state, ...newState })),
    onResetilterStoreGroupOptionStore: () => set(() => ({ ...storeGroupOptionState })),
}));

//========================================= STATUS LIST =========================
const statusOptionState = {
    isLoadingStatusOption: false,
    statusOptions: [],
    searchStatusQuery: '',
};

export const useStatusOptionStore = create((set) => ({
    ...statusOptionState,
    onChangeFilterStatusOptionStore: (newState) => set((state) => ({ ...state, ...newState })),
    onResetilterStatusOptionStore: () => set(() => ({ ...statusOptionState })),
}));

//========================================= UPLOAD TYPE LIST =========================
const uploadTypeOptions = {
    isLoadingUploadTypeOption: false,
    uploadTypeOptions: [],
    searchUploadTypeQuery: '',
};

export const useUploadTypeStore = create((set) => ({
    ...uploadTypeOptions,
    onChangeUploadTypeOptionStore: (newState) => set((state) => ({ ...state, ...newState })),
    onResetilterUploadTypeOptionStore: () => set(() => ({ ...uploadTypeOptions })),
}));
