import { create } from 'zustand';

//============================== UPLOAD STORE ====================

const state = {
    selectedFile: {},
};

export const useUploadStore = create((set) => ({
    ...state,
    onChangeUploadStore: (newState) => set((state) => ({ ...state, ...newState })),
    onResetUploadtore: () => set(() => ({ ...state })),
}));
