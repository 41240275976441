//========================== LIBRARY ===========================

import { Box } from '@chakra-ui/react';
import { useEffect, createContext } from 'react';

//========================== GLOBAL ASSETS ===========================

import { ConditionalComponent } from '@components/functionComponent';

//========================== LOCAL ASSETS ===========================

import { fetchError, fetchHistory } from './helpers/crud';
import { useErrorStore, useHistoryStore } from './helpers/mainState';
import Reminder from './sections/reminder';
import Upload from './sections/upload';
import History from './sections/history';
import Error from './sections/error';

import './styles.scss';

export const PageContext = createContext();

const UploadSellout = () => {
    const { onChangeHistoryStore, history, isLoadingHistoryTable } = useHistoryStore();
    const { onChangeErrorStore, errors, isLoadingErrorTable } = useErrorStore();

    const onFetchUploadData = () => {
        fetchHistory({ onChangeHistoryStore });
        fetchError({ onChangeErrorStore });
    };

    useEffect(() => {
        onFetchUploadData();
        // eslint-disable-next-line
    }, []);

    return (
        <PageContext.Provider value={{ onFetchUploadData }}>
            <Box className="upload-sellout__container">
                <Reminder />
                <Upload />
                <ConditionalComponent statement={errors?.length}>
                    <Error errors={errors} isLoading={isLoadingErrorTable} />
                </ConditionalComponent>
                <History history={history} isLoading={isLoadingHistoryTable} />
            </Box>
        </PageContext.Provider>
    );
};

export default UploadSellout;
