import { create } from 'zustand';
import { routeListUnauth } from '@routes';

//============ LOGIN ONLY INIT ROUTE ======================

const state = {
    routes: [...routeListUnauth],
};

export const useRouteList = create((set) => ({
    ...state,
    onChangeRouteList: (newState) => set((state) => ({ ...state, ...newState })),
    onResetRouteList: () => set(() => ({ ...state })),
}));
