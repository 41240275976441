import { Box } from '@chakra-ui/react';
import { useEffect, createContext } from 'react';

import { useSelectedFilterStore, useStoreConfigStore } from './helpers/mainState';
import { fetchStoreConfig } from './helpers/crud';
import Table from './sections/table';
import Head from './sections/head';
import './styles.scss';

export const PageContext = createContext();

const StoreConfig = () => {
    const { onChangeStoreConfigStore, onResetStoreConfigStore } = useStoreConfigStore();

    const selectedFilterStore = useSelectedFilterStore();

    const onFetchStoreConfig = () => fetchStoreConfig({ onChangeStoreConfigStore, selectedFilterStore });

    useEffect(() => {
        onFetchStoreConfig();
        // eslint-disable-next-line
    }, [selectedFilterStore]);

    useEffect(() => {
        return () => {
            onResetStoreConfigStore();
            selectedFilterStore?.onResetFilterSelectedStore();
        };
        // eslint-disable-next-line
    }, []);

    return (
        <PageContext.Provider value={{ onFetchStoreConfig }}>
            <Box className="store-config__container">
                <Head onFetchStoreConfig={onFetchStoreConfig} />
                <Table />
            </Box>
        </PageContext.Provider>
    );
};

export default StoreConfig;
