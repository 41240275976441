import { Flex, Box, Input } from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import { debounce } from '@helpers/utils';
import './styles.scss';

const Search = ({ placeholder = 'Cari nama atau email', onSearch }) => {
    const onSeacrhHandler = debounce(({ query }) => onSearch({ query }));

    return (
        <Flex className="search-container">
            <Input
                className="search-input"
                placeholder={placeholder}
                onChange={(e) => onSeacrhHandler({ query: e.target.value })}
            />
            <Box className="search-icon">
                <SearchIcon />
            </Box>
        </Flex>
    );
};

export default Search;
