export const ImageError = () => (
    <svg width="220" height="220" viewBox="0 0 118 118" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_2531_11746)">
            <path
                d="M78.0001 82H18.0001C13.4051 82 9.66675 78.2617 9.66675 73.6667V20.3333C9.66675 15.7383 13.4051 12 18.0001 12H78.0001C82.5951 12 86.3334 15.7383 86.3334 20.3333V73.6667C86.3334 78.2617 82.5951 82 78.0001 82ZM18.0001 15.3333C15.2434 15.3333 13.0001 17.5767 13.0001 20.3333V73.6667C13.0001 76.4233 15.2434 78.6667 18.0001 78.6667H78.0001C80.7567 78.6667 83.0001 76.4233 83.0001 73.6667V20.3333C83.0001 17.5767 80.7567 15.3333 78.0001 15.3333H18.0001Z"
                fill="#3C3C3C"
            />
            <path
                d="M61.3317 92.0003H34.665C34.13 92.0003 33.625 91.7437 33.3117 91.3087C32.9983 90.8737 32.9133 90.3137 33.0833 89.807L36.4183 79.807C36.6467 79.1253 37.2833 78.667 38 78.667H58C58.7183 78.667 59.355 79.1253 59.5817 79.807L62.9117 89.807C63.0833 90.317 62.9967 90.8753 62.6833 91.3087C62.37 91.7437 61.8667 92.0003 61.3317 92.0003ZM36.9767 88.667H59.0183L56.7983 82.0003H39.2017L36.9767 88.667Z"
                fill="#3C3C3C"
            />
            <path
                d="M64.6667 92.0001H31.3334C30.4134 92.0001 29.6667 91.2551 29.6667 90.3334C29.6667 89.4118 30.4134 88.6668 31.3334 88.6668H64.6667C65.5884 88.6668 66.3334 89.4118 66.3334 90.3334C66.3334 91.2551 65.5884 92.0001 64.6667 92.0001ZM84.6667 72.0001H11.3334C10.4134 72.0001 9.66675 71.2551 9.66675 70.3334C9.66675 69.4118 10.4134 68.6668 11.3334 68.6668H84.6667C85.5884 68.6668 86.3334 69.4118 86.3334 70.3334C86.3334 71.2551 85.5884 72.0001 84.6667 72.0001ZM39.6667 35.3334C39.2401 35.3334 38.8134 35.1701 38.4884 34.8451L31.8217 28.1784C31.1701 27.5268 31.1701 26.4734 31.8217 25.8218C32.4734 25.1701 33.5267 25.1701 34.1784 25.8218L40.8451 32.4884C41.4967 33.1401 41.4967 34.1934 40.8451 34.8451C40.5201 35.1701 40.0934 35.3334 39.6667 35.3334Z"
                fill="#3C3C3C"
            />
            <path
                d="M33.0001 35.3334C32.5734 35.3334 32.1468 35.1701 31.8218 34.8451C31.1701 34.1934 31.1701 33.1401 31.8218 32.4884L38.4884 25.8218C39.1401 25.1701 40.1934 25.1701 40.8451 25.8218C41.4968 26.4734 41.4968 27.5268 40.8451 28.1784L34.1784 34.8451C33.8534 35.1701 33.4268 35.3334 33.0001 35.3334ZM63.0001 35.3334C62.5734 35.3334 62.1468 35.1701 61.8218 34.8451L55.1551 28.1784C54.5034 27.5268 54.5034 26.4734 55.1551 25.8218C55.8068 25.1701 56.8601 25.1701 57.5118 25.8218L64.1784 32.4884C64.8301 33.1401 64.8301 34.1934 64.1784 34.8451C63.8534 35.1701 63.4268 35.3334 63.0001 35.3334Z"
                fill="#3C3C3C"
            />
            <path
                d="M56.3333 35.3334C55.9067 35.3334 55.48 35.1701 55.155 34.8451C54.5033 34.1934 54.5033 33.1401 55.155 32.4884L61.8217 25.8218C62.4733 25.1701 63.5267 25.1701 64.1783 25.8218C64.83 26.4734 64.83 27.5268 64.1783 28.1784L57.5117 34.8451C57.1867 35.1701 56.76 35.3334 56.3333 35.3334ZM61.3317 52.0001H34.6667C33.7467 52.0001 33 51.2551 33 50.3334C33 49.4118 33.7467 48.6668 34.6667 48.6668H61.3317C62.2533 48.6668 62.9983 49.4118 62.9983 50.3334C62.9983 51.2551 62.2533 52.0001 61.3317 52.0001Z"
                fill="#3C3C3C"
            />
            <path
                d="M58.0001 58.667H51.3334C50.4117 58.667 49.6667 57.922 49.6667 57.0003V50.3337C49.6667 49.412 50.4117 48.667 51.3334 48.667H58.0001C58.9217 48.667 59.6667 49.412 59.6667 50.3337V57.0003C59.6667 57.922 58.9217 58.667 58.0001 58.667ZM53.0001 55.3337H56.3334V52.0003H53.0001V55.3337Z"
                fill="#3C3C3C"
            />
        </g>
        <path
            className="path-warning"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M88.5333 64.5895C87.3871 62.3087 85.0526 60.8691 82.4999 60.8691C79.9471 60.8691 77.6126 62.3087 76.4664 64.5895C71.9194 73.6375 65.7619 85.8896 61.512 94.346C60.46 96.4394 60.5682 98.9283 61.7978 100.922C63.0274 102.916 65.2027 104.131 67.5454 104.131H97.4543C99.797 104.131 101.972 102.916 103.202 100.922C104.432 98.9283 104.54 96.4394 103.488 94.346C99.2378 85.8896 93.0803 73.6375 88.5333 64.5895Z"
            fill="#FFEB00"
        />
        <path
            className="path-warning"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M90.4991 63.6015L105.453 93.3579C106.848 96.1331 106.705 99.433 105.074 102.077C103.444 104.72 100.56 106.33 97.4542 106.33H67.5457C64.4396 106.33 61.5556 104.72 59.9254 102.077C58.2952 99.433 58.1518 96.1331 59.5465 93.3579C63.7963 84.9015 69.9537 72.6493 74.5007 63.6015C76.0205 60.5774 79.1156 58.6689 82.4999 58.6689C85.8843 58.6689 88.9794 60.5774 90.4991 63.6015ZM86.5676 65.5772C85.7949 64.0395 84.221 63.0689 82.4999 63.0689C80.7789 63.0689 79.205 64.0395 78.4323 65.5772C73.8852 74.6251 67.7277 86.8772 63.478 95.3336C62.7687 96.7449 62.8416 98.4229 63.6706 99.7673C64.4995 101.112 65.9662 101.93 67.5457 101.93H97.4542C99.0337 101.93 100.5 101.112 101.329 99.7673C102.158 98.4229 102.231 96.7449 101.522 95.3336L86.5676 65.5772ZM80.2999 95.8751V95.6685C80.2999 94.4544 81.2858 93.4685 82.4999 93.4685C83.7141 93.4685 84.6999 94.4544 84.6999 95.6685V95.8751C84.6999 97.0893 83.7141 98.0751 82.4999 98.0751C81.2858 98.0751 80.2999 97.0893 80.2999 95.8751ZM84.6999 71.5614V89.4191C84.6999 90.6334 83.7141 91.6191 82.4999 91.6191C81.2858 91.6191 80.2999 90.6334 80.2999 89.4191V71.5614C80.2999 70.3472 81.2858 69.3614 82.4999 69.3614C83.7141 69.3614 84.6999 70.3472 84.6999 71.5614Z"
            fill="#3C3C3C"
        />
        <defs>
            <clipPath id="clip0_2531_11746">
                <rect width="80" height="80" fill="white" transform="translate(8 12)" />
            </clipPath>
        </defs>
    </svg>
);

export const ReloadIcon = () => (
    <svg
        style={{ marginLeft: '8px' }}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M14 15L10 19L14 23" stroke="#CCD2E3" strokeWidth="2" />
        <path
            d="M5.93782 15.5C5.16735 14.1655 4.85875 12.6141 5.05989 11.0863C5.26102 9.55856 5.96064 8.13986 7.05025 7.05025C8.13986 5.96064 9.55856 5.26102 11.0863 5.05989C12.6141 4.85875 14.1655 5.16735 15.5 5.93782C16.8345 6.70829 17.8775 7.89757 18.4672 9.32122C19.0568 10.7449 19.1603 12.3233 18.7615 13.8117C18.3627 15.3002 17.4838 16.6154 16.2613 17.5535C15.0388 18.4915 13.5409 19 12 19"
            stroke="#CCD2E3"
            strokeWidth="2"
            strokeLinecap="round"
        />
    </svg>
);
