export const initialState = {
    isOpen: false,
    selectedFilter: [],
    query: '',
    filterList: [],
    onInputMode: false,
    isLoading: true,
};

export const dummyList = [1, 2, 3, 4, 5, 6, 7, 8, 9];
