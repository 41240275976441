import { useEffect } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';

import { ConditionalComponent } from '@components/functionComponent';
import { useDownloadNotification } from '@store/downloadNotification';

import { InfoIcon, CloseIcon } from './icons';
import { getColor } from './const';

import './styles.scss';

const DownloadNotification = () => {
    const { status, title, description, onResetDownloadNotification } = useDownloadNotification();

    const { borderColor, backgroundColor, iconColor } = getColor({ status });

    useEffect(() => {
        setTimeout(() => {
            if (status !== 'download') onResetDownloadNotification();
        }, 3000);
        // eslint-disable-next-line
    }, [status]);

    return (
        <ConditionalComponent statement={!!status}>
            <Box className="download-notification__container">
                <Flex className="box" bg={backgroundColor} border={`1px solid ${borderColor}`}>
                    <InfoIcon color={iconColor} />
                    <Box className="info-container">
                        <Text className="title">{title}</Text>
                        <Text className="description">{description}</Text>
                    </Box>
                    <CloseIcon color={iconColor} onClick={onResetDownloadNotification} />
                </Flex>
            </Box>
        </ConditionalComponent>
    );
};

export default DownloadNotification;
