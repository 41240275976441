import { useState } from 'react';
import { Box, Flex, Text, Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { ChevronLeftIcon, DownloadIcon } from '@chakra-ui/icons';

import UploadFullZone from '@components/uploadFullZone';
import { useDragFile } from '@store/dragFile';
import { UploadIcon } from '@assets/icons';
import { useSnackbarNotification } from '@store/snackbarNotification';
import { usePopUp } from '@store/popup';
import { ConditionalComponent } from '@components/functionComponent';
import { onUploadFile, onDownloadTemplateFile } from '@pages/UserManagement/helpers/crud';
import { useDownloadNotification } from '@store/downloadNotification';
import './styles.scss';
import FileInput from '@components/fileInput';

const Upload = () => {
    const navigate = useNavigate();
    const { isDragFile, onChangeDragFile } = useDragFile();
    const { onChangeSnackbarNotification } = useSnackbarNotification();
    const { onChangeDownloadNotification } = useDownloadNotification();
    const { onChangePopUp } = usePopUp();
    const [selectedFile, setSelectedFile] = useState(null);

    const onChangeFile = ({ file, checkedStatus }) => {
        if (checkedStatus === 'not-valid') return;
        setSelectedFile({ file });
    };

    return (
        <Box className="upload-zone__usermanagement fade-animation">
            <Flex className="head">
                <Flex gap="16px" alignItems="center">
                    <Box className="icon-back" onClick={() => navigate('/user-management')}>
                        <ChevronLeftIcon color="var(--main-color-dark)" fontSize="24px" />
                    </Box>
                    <Text className="title" onClick={() => navigate('/user-management')}>
                        Upload User
                    </Text>
                </Flex>
                <Flex
                    className="download-button"
                    onClick={() => onDownloadTemplateFile({ onChangeDownloadNotification })}
                >
                    <Text className="download-text">Download template</Text>
                    <DownloadIcon color="white" />
                </Flex>
            </Flex>
            <Flex className="body">
                <Box className={`upload-zone__container ${selectedFile && 'selected-file__container'}`}>
                    <UploadIcon color={selectedFile ? 'var(--main-color)' : '#868686'} />
                    <Text className="button-text">{selectedFile ? selectedFile?.name : 'Choose file to upload'}</Text>
                    <FileInput
                        maxSize={3}
                        accessAllowed={'.csv'}
                        onChange={({ selectedFile, checkedStatus }) => onChangeFile({ selectedFile, checkedStatus })}
                    />
                </Box>
                <Button
                    _hover={{ background: 'blue' }}
                    isDisabled={!selectedFile?.name}
                    className="submit"
                    onClick={() =>
                        onUploadFile({ onChangePopUp, selectedFile, onChangeSnackbarNotification, navigate })
                    }
                >
                    Upload
                </Button>
            </Flex>

            <ConditionalComponent statement={isDragFile}>
                <UploadFullZone
                    maxSize={3}
                    accessAllowed={'.csv'}
                    onSelectFile={(file) => setSelectedFile(file)}
                    onClose={() => onChangeDragFile({ isDragFile: false })}
                />
            </ConditionalComponent>
        </Box>
    );
};

export default Upload;
