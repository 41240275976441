import { create } from 'zustand';

const popUpAsset = {
    selectedTimeUploadType: '',
    selectedTimeUploadValue: new Date(),
    // selectedBrand: [],
    selectedFormatUploadType: {},
    isTermsChecked: false,
    isUploading: false,
    isBlockNotification: true,
};

export const usePopUpStore = create((set) => ({
    ...popUpAsset,
    onChangePopUpStore: (newState) => set((state) => ({ ...state, ...newState })),
    onResetPopUpStore: () => set(() => ({ ...popUpAsset })),
}));

const assetState = {
    brandOptions: [],
    timeUploadOptions: [],
    uploadTypeOptions: [],
    blockedUpload: {},
    isLoadingAsset: true,
};

export const usePopUpAssetStore = create((set) => ({
    ...assetState,
    onChangePopupAssetStore: (newState) => set((state) => ({ ...state, ...newState })),
    onResetPopUpAssetStore: () => set(() => ({ ...assetState })),
}));
