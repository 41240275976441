import Edges from './edges';
import group from './group';
import Nodes from './nodes';
import Note from './notes';

export const nodeTypes = {
    defaultNode: Nodes,
    defaultGroup: group,
    defaultNote: Note,
};

export const edgeTypes = {
    defaultEdge: Edges,
};
