import { useNavigate } from 'react-router-dom';
import { Box, Flex, Button, Input, Text } from '@chakra-ui/react';

import { useSnackbarNotification } from '@store/snackbarNotification';
import { ConditionalComponent } from '@components/functionComponent';
import { CloseEyeIcon, OpenEyeIcon } from '@assets/icons';
import { useRouteList } from '@store/routes';

import { onLoginHandler } from './helpers/crud';
import { LoginPasswordImageIllustration } from './assets/illustrations';
import { onValidationPassword } from './helpers/events';
import { useLoginPassword } from './helpers/mainState';

import './styles.scss';

const Login = () => {
    const navigate = useNavigate();
    const { onChangeRouteList } = useRouteList();

    const { username, password, onChangeLoginPasswordStore, isLoading, isOpenPassword } = useLoginPassword();
    const { onChangeSnackbarNotification } = useSnackbarNotification();

    const isValid = onValidationPassword({ username, password });

    return (
        <Box className="login-password__container">
            <Flex className="box-container">
                <Box className="left-section">
                    <LoginPasswordImageIllustration />
                </Box>

                <Box className="right-section">
                    <Text className="title">Login</Text>
                    <Text className="desc">Welcome to sagitarius app</Text>

                    <Box className="input-container">
                        <Text className="label">Email</Text>
                        <Box position="relative">
                            <Input
                                className="input"
                                onChange={(e) => onChangeLoginPasswordStore({ username: e.target.value })}
                                onKeyUp={(event) =>
                                    event.key === 'Enter' &&
                                    onLoginHandler({
                                        username,
                                        password,
                                        onChangeSnackbarNotification,
                                        navigate,
                                        onChangeLoginPasswordStore,
                                        isValid,
                                        onChangeRouteList,
                                    })
                                }
                            />
                        </Box>
                    </Box>

                    <Box className="input-container" mb="12px !important">
                        <Text className="label">Password</Text>
                        <Box position="relative">
                            <Input
                                className="input"
                                name="password"
                                type={isOpenPassword ? 'text' : 'password'}
                                onChange={(e) => onChangeLoginPasswordStore({ password: e.target.value })}
                                onKeyUp={(event) =>
                                    event.key === 'Enter' &&
                                    onLoginHandler({
                                        username,
                                        password,
                                        onChangeSnackbarNotification,
                                        navigate,
                                        onChangeLoginPasswordStore,
                                        isValid,
                                        onChangeRouteList,
                                    })
                                }
                            />
                            <ConditionalComponent statement={isOpenPassword}>
                                <OpenEyeIcon onClick={() => onChangeLoginPasswordStore({ isOpenPassword: false })} />
                            </ConditionalComponent>
                            <ConditionalComponent statement={!isOpenPassword}>
                                <CloseEyeIcon onClick={() => onChangeLoginPasswordStore({ isOpenPassword: true })} />
                            </ConditionalComponent>
                        </Box>
                    </Box>

                    <Button
                        isLoading={isLoading}
                        className={`button-submit`}
                        onClick={() =>
                            onLoginHandler({
                                username,
                                password,
                                onChangeSnackbarNotification,
                                navigate,
                                isValid,
                                onChangeLoginPasswordStore,
                                onChangeRouteList,
                            })
                        }
                    >
                        Masuk
                    </Button>
                </Box>
            </Flex>
        </Box>
    );
};

export default Login;
