import OutsideClickHandler from 'react-outside-click-handler';
import { Box, useDisclosure } from '@chakra-ui/react';
import { ConditionalComponent, IterationComponent } from '@components/functionComponent';

import './styles.scss';

const FloatingMenu = ({ buttonElement, list, className }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <OutsideClickHandler onOutsideClick={onClose}>
            <Box className="floating-menu__container">
                <Box className={`${className} button-trigger`} onClick={onOpen}>
                    {buttonElement}
                </Box>
                <ConditionalComponent statement={isOpen}>
                    <Box className="menu-list__container fade-animation-bottom">
                        <IterationComponent
                            arr={list}
                            render={(item) => <Box className="menu-item__floating-button">{item}</Box>}
                        />
                    </Box>
                </ConditionalComponent>
            </Box>
        </OutsideClickHandler>
    );
};

export default FloatingMenu;
