const onGetClientScreen = () => {
    let result = 'max';
    const pageWidth = document.body.clientWidth;

    if (pageWidth > 1610) result = 'wideScreen';
    else if (pageWidth <= 1610 && pageWidth > 1380) result = 'mediumScreen';
    else result = 'smallScreen';

    return result;
};

export default onGetClientScreen;
