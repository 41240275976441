import { onCancelAuditTrail, onDownloadFile, onProcessingFile } from '@pages/AuditTrail/helpers/crud';

//==================================== ON DOWNLOAD FILE ==================================

export const onClickDownloadFile = ({
    onChangePopUp,
    onResetPopUp,
    onChangeDownloadNotification,
    onChangeSnackbarNotification,
    refetchAuditTrail,
    values,
}) => {
    const isUploaded = values?.status === 'uploaded';

    if (isUploaded) {
        onChangePopUp({
            status: 'confirmation',
            title: 'Mulai Proses Data',
            description: 'Apakah anda akan Download saja atau mengubah status ke Processing',
            cancelOptions: { name: 'Download saja' },
            submitOptions: { name: 'Download & proses' },
            onSubmit: async () => {
                await onProcessingFile({
                    id: values?.id,
                    status: 'processing',
                    refetchAuditTrail,
                    onResetPopUp,
                    onChangePopUp,
                    onChangeSnackbarNotification,
                });

                onDownloadFile({
                    type: `original-file`,
                    path: values?.original_filename,
                    onChangeDownloadNotification,
                });
            },
            onClose: () => {
                onDownloadFile({
                    type: `original-file`,
                    path: values?.original_filename,
                    onChangeDownloadNotification,
                });
            },
        });
    } else {
        onDownloadFile({
            type: `original file`,
            path: values?.original_filename,
            onChangeDownloadNotification,
        });
    }
};

//==================================== ON CANCEL ==================================

export const onCancelAuditTrailHandler = ({
    onChangePopUp,
    refetchAuditTrail,
    onChangeSnackbarNotification,
    onResetPopUp,
    values,
}) => {
    onChangePopUp({
        status: 'confirmation',
        description: 'Harap pastikan bahwa Anda sudah mempertimbangkan dengan baik sebelum membatalkan data.',
        title: 'Apakah Anda yakin ingin membatalkan data yang sudah dimasukkan?',
        onSubmit: () =>
            onCancelAuditTrail({
                id: values?.id,
                refetchAuditTrail,
                onResetPopUp,
                onChangePopUp,
                onChangeSnackbarNotification,
            }),
    });
};

//==================================== ON FAILED ==================================

export const onFailedAuditTrailHandler = ({
    onChangePopUp,
    refetchAuditTrail,
    onChangeSnackbarNotification,
    onResetPopUp,
    values,
}) => {
    onChangePopUp({
        status: 'confirmation',
        description: 'Harap pastikan bahwa Anda sudah mempertimbangkan dengan baik sebelum mengubah data.',
        title: 'Apakah Anda yakin akan mengagalkan data ini??',
        onSubmit: () =>
            onProcessingFile({
                id: values?.id,
                status: 'failed',
                refetchAuditTrail,
                onResetPopUp,
                onChangePopUp,
                onChangeSnackbarNotification,
            }),
    });
};

//==================================== ON SUCCESS ==================================

export const onSuccessAuditTrailHandler = ({
    onChangePopUp,
    refetchAuditTrail,
    onChangeSnackbarNotification,
    onResetPopUp,
    values,
    selectedFile,
}) => {
    onChangePopUp({
        status: 'confirmation',
        description: 'Harap pastikan bahwa Anda sudah mempertimbangkan dengan baik sebelum mengubah data.',
        title: 'Apakah anda yakin sudah memastikan data ini berhasil di proses?',
        onSubmit: () =>
            onProcessingFile({
                id: values?.id,
                status: 'success',
                refetchAuditTrail,
                onResetPopUp,
                file: selectedFile,
                onChangePopUp,
                onChangeSnackbarNotification,
            }),
    });
};

//==================================== ON PROCESSING ==================================

export const onProcessingAuditTrailHandler = ({
    onChangePopUp,
    refetchAuditTrail,
    onChangeSnackbarNotification,
    onResetPopUp,
    values,
}) => {
    onChangePopUp({
        status: 'confirmation',
        description: 'Harap pastikan bahwa Anda sudah mempertimbangkan dengan baik sebelum mengubah data.',
        title: 'Apakah Anda yakin akan processing data ini?',
        onSubmit: () =>
            onProcessingFile({
                id: values?.id,
                status: 'processing',
                refetchAuditTrail,
                onResetPopUp,
                onChangePopUp,
                onChangeSnackbarNotification,
            }),
    });
};

//==================================== ON RESEND ==================================

export const onResendAuditTrailHandler = ({
    onChangePopUp,
    refetchAuditTrail,
    onChangeSnackbarNotification,
    onResetPopUp,
    values,
}) => {
    onChangePopUp({
        status: 'confirmation',
        description: 'Harap pastikan bahwa Anda sudah mempertimbangkan dengan baik sebelum mengubah data.',
        title: 'Apakah Anda yakin mengirim ulang data?',
        onSubmit: () =>
            onProcessingFile({
                id: values?.id,
                status: 'processing',
                refetchAuditTrail,
                onResetPopUp,
                onChangePopUp,
                onChangeSnackbarNotification,
            }),
    });
};