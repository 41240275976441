export const onShowCustomerField = ({ selectedRole }) => {
    const listSelectedRole = ['Store Owner'];
    const filteringSelectedRole = selectedRole?.filter((item) => listSelectedRole.includes(item?.name));

    return filteringSelectedRole?.length > 0;
};

export const onShowDCField = ({ selectedRole }) => {
    const listSelectedRole = ['Area Admin', 'Area Leaders'];
    const filteringSelectedRole = selectedRole?.filter((item) => listSelectedRole.includes(item?.name));

    return filteringSelectedRole?.length > 0;
};

export const onShowUserField = ({ selectedRole }) => {
    const listSelectedRole = ['user'];
    const filteringSelectedRole = selectedRole?.filter((item) => listSelectedRole.includes(item?.name));

    return filteringSelectedRole?.length > 0;
};

export const onFormValidation = ({ addEditState, onChangeSnackbarNotification, selectedAccess, isEditPage }) => {
    const { selectedDC, selectedRole, selectedCustomer, username, password, email, confirmPassword } = addEditState;
    const isShowCustomerField = onShowCustomerField({ selectedRole });
    const isShowDCField = onShowDCField({ selectedRole });
    const isShowUserField = onShowUserField({ selectedRole })


    let result = true;

    if (confirmPassword !== password) {
        onChangeSnackbarNotification({
            status: 'error',
            title: 'Failed',
            description: 'Password dan Confirm password harus sama!',
            duration: 2,
        });

        return;
    }




    if (isEditPage) {
        if (!username) result = false;
        if (isShowUserField && selectedAccess?.length < 1) result = false;
    } else {
        if (username && password && confirmPassword && email && selectedRole?.length) {
            if (isShowDCField && selectedDC?.length < 1) result = false;
            if (isShowCustomerField && selectedCustomer?.length < 1) result = false;
            if (isShowUserField && selectedAccess?.length < 1) result = false
        } else result = false;
    }


    if (!result) {
        onChangeSnackbarNotification({
            status: 'error',
            title: 'Failed',
            description: 'All field required!',
            duration: 2,
        });
    }

    return result;
};
