export const onChangeCursor = (type) => {
    var elementPane = document.getElementsByClassName('react-flow__pane')[0];
    var elementFlow = document.getElementsByClassName('react-flow__renderer')[0];

    if (type === 'crosshair') {
        elementFlow.classList.add('cursor-crosshair');
        elementPane.classList.add('cursor-crosshair');
    } else {
        elementFlow.classList.remove('cursor-crosshair');
        elementPane.classList.remove('cursor-crosshair');
    }
};
