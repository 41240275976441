import { create } from 'zustand';
import moment from 'moment';

export const useMultipleSelectStore = create((set) => ({
    selected: [],
    isOpen: false,
    onChangeMultipleSelect: (newState) => set((state) => ({ ...state, ...newState })),
}));

export const useOptionsType = () => [
    {
        id: 'sellout',
        name: `Sell-Out ${moment().year() - 1}`,
        value: `Sell-Out ${moment().year() - 1}`,
    },
    {
        id: 'sellin',
        name: `Sell-In ${moment().year() - 1}`,
        value: `Sell-In ${moment().year() - 1}`,
    },
    {
        id: 'stock',
        name: `Stock ${moment().year() - 1}`,
        value: `Stock ${moment().year() - 1}`,
    },
];
