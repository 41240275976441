import { isEmpty } from 'lodash';

export const onValidationPassword = ({ password, username }) => {
    return !isEmpty(password) && !isEmpty(username);
};

export const onCheckingUppercase = ({ newPassword }) => {
    for (let index = 0; index < newPassword?.length; index++) {
        const str = newPassword[index];
        if (str === str.toUpperCase() && str !== str.toLowerCase()) return true;
    }
};

export const onCheckingSpecialChar = ({ newPassword }) => {
    // eslint-disable-next-line
    const format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    return format.test(newPassword);
};
