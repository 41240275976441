import { create } from 'zustand';

const state = { isLoadingSkeleton: true };

export const useSkeletonPage = create((set) => ({
    ...state,
    onChangeSkeletonPage: () => {
        setTimeout(() => {
            set(() => ({ isLoadingSkeleton: false }));
        }, 1000);
    },
    onResetSkeletonPage: () => set(() => ({ ...state })),
}));
