import Axios from '@services/axios';
import Cookies from 'universal-cookie';

export const onUpdateDocs = async ({
    value,
    title,
    onChangeSnackbarNotification,
    onRefetchDocs,
    onChangeState,
    type,
}) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    onChangeState({ isLoading: true, isEdit: false });

    try {
        await Axios({
            method: 'PUT',
            url: `/api/docs/${type}`,
            headers: { Authorization: 'Bearer ' + userToken },
            data: {
                data: {
                    title,
                    contents: value,
                },
            },
        });

        onChangeSnackbarNotification({
            status: 'success',
            title: 'Success',
            description: `Update documentation ${title} berhasil`,
            duration: 2,
        });

        onRefetchDocs();

    } catch (error) {
        console.log(error);
        const errorMessage = error?.response?.data?.message || 'Something wrong!';

        onChangeSnackbarNotification({
            status: 'error',
            title: 'Error',
            description: errorMessage,
            duration: 2,
        });
    }
};

export const onFetchDocs = async ({ onChangeState, type }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    try {
        const { data } = await Axios({
            method: 'GET',
            url: `/api/docs/${type}`,
            headers: { Authorization: 'Bearer ' + userToken },
        });

        onChangeState({ docs: data?.data, isLoading: false, isEdit: false });
    } catch (error) {
        console.log(error);
        onChangeState({ docs: {}, isLoading: false, isEdit: false });
    }
};
