import { Box, Text, Flex, Table, Td, Th, Tr, Tbody, Thead } from '@chakra-ui/react';
import ReactPaginate from 'react-paginate';
import { isEmpty } from 'lodash';
import EmptyState from '@components/emptyState';
import { ConditionalComponent, IterationComponent } from '@components/functionComponent';
import { ChevronRightIcon, ChevronLeftIcon } from '@assets/icons';

import './styles.scss';
import { Fragment, useEffect, useState } from 'react';
import { TableHeadSkeleton, TableColumnSkeleton, TextFooterSkeleton, PaginationSkeleton } from './components/skeleton';

const TableTemplate = ({
    tableList,
    data,
    totalPage,
    page,
    handlePageClick,
    isDynamicTable,
    dataCount,
    totalData,
    isPagination,
    overflowTable = 'hidden',
    withHeader,
    headerComponenet,
    isLoading,
}) => {
    const [isLoadingTableHead, setIsLoadingTableHead] = useState(true);
    const [isLoadingFooterText, setIsLoadingFooterText] = useState(true);
    const [isLoadingPagination, setIsLoadingPagination] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setIsLoadingTableHead(false);
        }, 600);

        setTimeout(() => {
            setIsLoadingFooterText(false);
        }, 1300);

        setTimeout(() => {
            setIsLoadingPagination(false);
        }, 900);
    }, []);

    return (
        <Box className="table-template__container">
            <Box className="table-container" overflow={overflowTable} maxH={isLoading ? '622px' : 'unset'}>
                <ConditionalComponent statement={withHeader}>{headerComponenet}</ConditionalComponent>
                <Table w={isDynamicTable ? 'max-content' : '100%'}>
                    {/** --------------------------  TABLE HEAD -------------------------- */}

                    <ConditionalComponent statement={data?.length > 0 && !isLoading}>
                        <Thead>
                            <Tr className="row-head__container">
                                <IterationComponent
                                    arr={tableList}
                                    render={({ name, width, isDivider }) => (
                                        <Fragment>
                                            <ConditionalComponent statement={!isLoadingTableHead}>
                                                <Th
                                                    className={`table-head__item ${isDivider && 'divider'}`}
                                                    width={`${width}px`}
                                                    maxWidth={`${width}px`}
                                                    key={name}
                                                >
                                                    {name}
                                                </Th>
                                            </ConditionalComponent>
                                        </Fragment>
                                    )}
                                />
                            </Tr>
                        </Thead>

                    </ConditionalComponent>

                    {/* //============================== TABLE HEAD SKELETON ============= */}

                    <ConditionalComponent statement={isLoadingTableHead || isLoading}>
                        <TableHeadSkeleton tableList={tableList} />
                    </ConditionalComponent>

                    {/** --------------------------  TABLE COLUMN-------------------------- */}

                    <ConditionalComponent statement={data?.length > 0 && !isLoading}>
                        <Tbody>
                            <IterationComponent
                                arr={data}
                                render={(values, index) => (
                                    <Tr className="row-column__container">
                                        <IterationComponent
                                            arr={tableList}
                                            render={(item) => (
                                                <Td className={`table-column__item ${item.isDivider && 'divider'}`} maxWidth={item?.width}>
                                                    {item.component({ value: values[item.value], values, index })}
                                                </Td>
                                            )}
                                        />
                                    </Tr>
                                )}
                            />
                        </Tbody>

                    </ConditionalComponent>

                    <ConditionalComponent statement={isLoading}>
                        <TableColumnSkeleton tableList={tableList} />
                    </ConditionalComponent>
                </Table>

                {/** --------------------------  EMPTY STATE -------------------------- */}
                <ConditionalComponent statement={isEmpty(data) && !isLoading}>
                    <EmptyState
                        text={'Data tidak ditemukan'}
                        description={'Harap melakukan dengan keyword atau filter lainnya.'}
                    />
                </ConditionalComponent>
            </Box>
            <ConditionalComponent statement={isPagination}>
                <Box className="section-pagination__container">
                    <ConditionalComponent statement={isLoadingFooterText}>
                        <TextFooterSkeleton />
                    </ConditionalComponent>

                    <ConditionalComponent statement={!isLoadingFooterText}>
                        <Text className="showing-text">
                            Menampilkan {dataCount} dari {totalData}
                        </Text>
                    </ConditionalComponent>

                    <Flex className="pagination-container">
                        <ConditionalComponent statement={isLoadingPagination}>
                            <PaginationSkeleton />
                        </ConditionalComponent>

                        <ConditionalComponent statement={!isLoadingPagination}>
                            <ReactPaginate
                                nextLabel={<ChevronRightIcon />}
                                onPageChange={handlePageClick}
                                pageRangeDisplayed={3}
                                marginPagesDisplayed={1}
                                pageCount={totalPage}
                                forcePage={page - 1}
                                previousLabel={<ChevronLeftIcon />}
                            />
                        </ConditionalComponent>
                    </Flex>
                </Box>
            </ConditionalComponent>
        </Box>
    );
};

export default TableTemplate;
