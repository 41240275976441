import { find } from 'lodash';

export const updateSelectedFilter = ({
    selectedFilter,
    onChangeSnackbarNotification,
    maxFilter,
    onChangeState,
    onSelect,
    item,
}) => {
    let result = [];

    const isExist = find(selectedFilter, { id: item?.id });

    if (isExist) {
        result = selectedFilter?.filter((filterItem) => filterItem?.id !== item?.id);
    } else {
        //================= SELECTED MAXIMAL FILTER =============

        if (selectedFilter?.length >= maxFilter) {
            onChangeSnackbarNotification({
                status: 'error',
                title: 'Tidak dapat menambahkan filter!',
                description: `Hanya dapat memilih maximal ${maxFilter} filter`,
                duration: 2,
            });

            return;
        }

        result = [...selectedFilter, item];
    }

    onChangeState({ selectedFilter: result });
    onSelect({ selected: result });
};

export const searchQuery = ({ query, onChangeState, options }) => {
    const fuzzballScore = 75;

    const fuzzball = require('fuzzball');
    if (query?.length) {
        const temp = [...options];
        const result = temp?.filter(({ name }) => fuzzball.partial_ratio(query, name) > fuzzballScore);
        onChangeState({ filterList: [...result], query });
    } else {
        onChangeState({ filterList: [...options], query });
    }
};
