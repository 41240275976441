export const templateList = [
    {
        id: 'cs',
        name: 'CS',
    },
    {
        id: 'mds&is',
        name: 'MDS & IS',
    },
];

export const statusTransferOptions = [
    {
        name: 'Completed',
        value: 'completed',
    },
    {
        name: 'Pending',
        value: 'pending',
    },
    {
        name: 'Started',
        value: 'started',
    },
];

export const nonActiveOption = {
    label: 'Nonaktif',
    name: 'Nonaktif',
    value: null,
};
