import { Flex, Box, Text } from '@chakra-ui/react';
import { IterationComponent } from '@components/functionComponent';
import { dummyProgressList } from './const';

import './styles.scss';
import { CheckIcon, CloseIcon } from '@chakra-ui/icons';
import { onFetchReplenishment } from '@pages/StoreConfig/helpers/crud';
import { useState, useRef, useEffect } from 'react';

const ReplenishmentProgressModal = ({ onClose, id }) => {
    const intervalRef = useRef(null);
    const [replenishment, setReplenisment] = useState({});

    const { progress_stage, is_done } = replenishment;

    const fetchReplenisment = () => onFetchReplenishment({ setReplenisment, id });

    useEffect(() => {
        intervalRef.current = setInterval(() => fetchReplenisment(), 2000);

        return () => clearInterval(intervalRef.current);
    }, []);

    useEffect(() => {
        if (is_done) clearInterval(intervalRef.current);
    }, [is_done]);

    return (
        <Flex className="replenisment-progress__modal fade-animation-top">
            <Box className="overlay" onClick={onClose} />
            <Flex className="modal-content">
                <Flex className="head">
                    <Text className="modal-title">Force Run</Text>
                    <CloseIcon fontSize="12px" cursor="pointer" onClick={onClose} />
                </Flex>
                <Flex className="step-container">
                    <IterationComponent
                        arr={progress_stage?.length ? progress_stage : dummyProgressList}
                        render={({ name, status }, idx) => (
                            <Box className={`step-item  step-item__${status}`}>
                                <Box className="progress-bar" display={idx === 0 && 'none'}>
                                    <Box className="progress-content"></Box>
                                </Box>
                                <Box className="circle-item">
                                    <CheckIcon color="white" className="icon-success icon" />
                                    <CloseIcon color="white" className="icon-failed icon" />

                                    <Box className="process-container icon-inprogress icon">
                                        <Box className="blue-progress circle1" />
                                        <Box className="blue-progress circle2" />
                                        <Box className="blue-progress circle3" />
                                    </Box>

                                    <Box className="process-container icon-todo icon">
                                        <Box className="grey-progress" />
                                    </Box>
                                </Box>

                                <Text className="step-label">STEP {idx + 1}</Text>
                                <Text className="step-name">{name}</Text>
                                <Text className="step-status">{status}</Text>
                            </Box>
                        )}
                    />
                </Flex>
            </Flex>
        </Flex>
    );
};

export default ReplenishmentProgressModal;
