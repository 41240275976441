import Cookies from 'universal-cookie';
import queryString from 'query-string';
import moment from 'moment';
import { saveAs } from 'file-saver';

import Axios from '@services/axios';
import { onFormValidation } from './utils';


//=========================== FETCH DATA & ASSETS ========================

export const fetchUserManagement = async ({
    onChangeUserManagementStore,
    page,
    searchQuery,
    selectedRole,
    selectedDC,
}) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    onChangeUserManagementStore({ isLoadingTable: true, users: [] });

    const role_name = selectedRole && selectedRole[0]?.name;
    const dc_names = selectedDC && `[${selectedDC?.map((item) => item?.name)}]`;
    const searchparam = searchQuery && `email.${searchQuery},email.${searchQuery}`;

    const filterStringify = queryString.stringify(
        {
            page: page,
            limit: 7,
            searchparam,
            role_name,
            nama_dc: dc_names,
        },
        { skipEmptyString: true, arrayFormat: 'separator' }
    );

    try {
        const { data } = await Axios({
            url: `/api/v1/user/partner-user?${filterStringify}`,
            method: 'GET',
            headers: { Authorization: 'Bearer ' + userToken },
        });

        const { total_data, page_data, total_page } = data?.data;

        onChangeUserManagementStore({
            users: page_data,
            isLoadingTable: false,
            paginationInfo: {
                dataCount: page_data?.length,
                totalData: total_data,
                totalPage: total_page,
            },
        });
    } catch (error) {
        console.log(error);
        onChangeUserManagementStore({ users: [], isLoadingTable: false });
    }
};

export const fetchDC = async ({ onChangeFilterDCOptionStore = () => null, searchQuery }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    onChangeFilterDCOptionStore({ isLoadingDCOption: true, dcOptions: [] });

    const filterStringify = queryString.stringify(
        {
            page: 1,
            limit: 1000,
            searchparam: searchQuery ? `dc_name.${searchQuery}` : '',
        },
        { skipEmptyString: true, arrayFormat: 'separator' }
    );

    try {
        const { data } = await Axios({
            url: `/api/v1/dc/list?${filterStringify}`,
            method: 'GET',
            headers: { Authorization: 'Bearer ' + userToken },
        });

        let result = data?.data?.page_data;

        result = result?.map((item) => {
            return {
                ...item,
                name: item?.dc_name,
            };
        });

        onChangeFilterDCOptionStore({ dcOptions: result, isLoadingDCOption: false });

        return result;
    } catch (error) {
        console.log(error);
        onChangeFilterDCOptionStore({ isLoadingDCOption: false, dcOptions: [] });
    }
};

export const fetchRole = async ({ onChangeFilterRoleOptionStore = () => null, searchQuery }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    onChangeFilterRoleOptionStore({ isLoadingRoleOption: true, roleOptions: [] });

    const filterStringify = queryString.stringify(
        {
            page: 1,
            limit: 20,
            searchparam: searchQuery && `name.${searchQuery}`,
        },
        { skipEmptyString: true, arrayFormat: 'separator' }
    );

    try {
        const { data } = await Axios({
            url: `/api/v1/user/role-available?${filterStringify}`,
            method: 'GET',
            headers: { Authorization: 'Bearer ' + userToken },
        });

        let roleUser = data?.data?.roleUser;
        let roleAccess = data?.data?.roleAccess

        roleUser = roleUser?.map((item) => {
            return {
                ...item,
                id: item?.role_id,
            };
        });

        roleAccess = roleAccess?.map((item) => {
            return {
                id: item?.access_allowed_id,
                name: item?.link?.replace("_", " ")
            }
        })



        onChangeFilterRoleOptionStore({ roleOptions: roleUser, isLoadingRoleOption: false, roleAccessOptions: roleAccess });

    } catch (error) {
        console.log(error);
        onChangeFilterRoleOptionStore({ isLoadingRoleOption: false, roleOptions: [], roleAccessOptions: [] });
    }
};

export const fetchCustomer = async ({ onChangeFilterCustomerOptionStore = () => null, searchQuery }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    onChangeFilterCustomerOptionStore({ isLoadingCustomerOption: true, customerOptions: [] });

    const filterStringify = queryString.stringify(
        {
            page: 1,
            limit: 30,
            searchparam: searchQuery ? `nama_outlet.${searchQuery},customer_id.${searchQuery}` : '',
            dc: `[]`,
        },
        { skipEmptyString: true, arrayFormat: 'separator' }
    );

    try {
        const { data } = await Axios({
            url: `/api/v1/user/customer-dc?${filterStringify}`,
            method: 'GET',
            headers: { Authorization: 'Bearer ' + userToken },
        });

        let result = data?.data?.page_data;

        result = result?.map((item) => {
            return {
                store_name: item?.nama_outlet,
                name: `${item?.nama_outlet} (${item?.customer_id})`,
                id: item?.customer_id,
            };
        });

        onChangeFilterCustomerOptionStore({ customerOptions: result, isLoadingCustomerOption: false });

        return result;
    } catch (error) {
        console.log(error);
        onChangeFilterCustomerOptionStore({ isLoadingCustomerOption: false, customerOptions: [] });
    }
};

//======== ON DOWNLOAD FILE USERMANAGEMENT BASED ON FILTER ================



export const onDownloadUsermanagement = async ({ onChangeDownloadNotification, status }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');
    const fileName = `${moment().format('DD MMM YYYY')}-Usermanagement`;

    //======== DISABLED CLICK WHEN DOWNLOADING FILE ================

    if (status === 'download') return;

    onChangeDownloadNotification({
        status: 'download',
        title: 'Downloading',
        description: 'Harap tunggu, sedang mendownload usermanagement',
    });

    try {
        const { data } = await Axios({
            url: `/api/v1/user/partner-user/export`,
            method: 'POST',
            headers: { Authorization: 'Bearer ' + userToken },
        });

        const blob = new Blob([data], { type: 'text/csv' });
        saveAs(blob, `${fileName}.csv`);

        onChangeDownloadNotification({
            status: 'success',
            title: 'Success',
            description: 'Download usermanagement berhasil',
        });
    } catch (error) {
        console.log(error);

        onChangeDownloadNotification({
            status: 'error',
            title: 'Failed',
            description: 'Download usermanagement gagal',
        });
    }
};

//=========================== SUBMIT USERMANAGEMENT FORM ========================

export const onSubmitForm = async ({
    onChangeAddEditStore,
    onChangeSnackbarNotification,
    addEditState,
    onResetAddEditStore,
    navigate,
    isEditPage,
    userId,
}) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    onChangeAddEditStore({ hasSubmit: true });

    const { email, username, password, selectedCustomer, selectedDC, selectedRole, selectedAccess } = addEditState;

    const isPassValidation = onFormValidation({ isEditPage, addEditState, onChangeSnackbarNotification, selectedAccess });

    if (!isPassValidation) return;

    onChangeAddEditStore({ hasSubmit: true, isLoadingSubmit: true });

    //======================= IF SELECTED DC NOT EMPTY , CUSTOMER ID = SELECTED DC, IF NOT CUSTOMER ID = SELECTED CUSTOMER (REVAMP LATER) ===============

    const customer_id = selectedDC?.length ? selectedDC[0]?.id : selectedCustomer[0]?.id;
    const role_id = selectedRole[0]?.id;
    const access_allowed_id = selectedAccess?.length ? selectedAccess?.map((item) => item?.id) : []

    let payload = {
        name: username,
        password,
        access_allowed_id,
    };

    if (!isEditPage) {
        payload = {
            ...payload,
            email,
            customer_id,
            role_id,
            access_allowed_id,
        };
    }

    try {
        await Axios({
            url: `/api/v1/user/${isEditPage ? userId : 'store'}`,
            method: isEditPage ? 'PUT' : 'POST',
            headers: { Authorization: 'Bearer ' + userToken },
            data: payload,
        });

        onResetAddEditStore();

        onChangeSnackbarNotification({
            status: 'success',
            title: `${isEditPage ? 'Update' : 'Create'} user success`,
            description: 'Data user berhasil disimpan',
            duration: 4,
        });

        navigate('/user-management');
    } catch (error) {
        console.log(error);

        const errorMessage = error?.response?.data?.message;

        onChangeAddEditStore({ isLoadingSubmit: false });

        onChangeSnackbarNotification({
            status: 'error',
            title: `${isEditPage ? 'Update' : 'Create'} user failed`,
            description: errorMessage || 'Something wrong!',
            duration: 4,
        });
    }
};

//====================================== REMOVE USER ===========================

export const onRemoveUser = async ({
    id,
    refetchUserManagement,
    onChangeSnackbarNotification,
    onResetPopUp,
    onChangePopUp,
    onChangeUserManagementStore,
}) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    try {
        onChangePopUp({ isLoading: true });

        onChangeUserManagementStore({ isLoadingHead: true });

        await Axios({
            method: 'DELETE',
            url: `/api/v1/user/${id}`,
            headers: { Authorization: 'Bearer ' + userToken },
        });

        onResetPopUp();

        onChangeSnackbarNotification({
            status: 'success',
            title: 'Remove user success',
            description: 'Berhasil menghapus user',
            duration: 4,
        });

        await refetchUserManagement();
    } catch (err) {
        console.log(err);

        const errorMessage = err?.response?.data?.message;

        onChangePopUp({ isLoading: false });

        onChangeSnackbarNotification({
            status: 'error',
            title: 'Remove user failed',
            description: errorMessage || 'Something wrong!',
            duration: 4,
        });
    } finally {
        onChangeUserManagementStore({ isLoadingHead: false });
    }
};

//====================================== DETAIL USER ============================

export const fetchDetailUser = async ({ id, uniqueEmail, onChangeAddEditStore, onChangeLoader, onChangeSnackbarNotification }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    try {
        onChangeLoader({ isLoadingPage: true });

        const { data } = await Axios({
            method: 'GET',
            url: `/api/v1/user/partner-user?email=${uniqueEmail}`,
            headers: { Authorization: 'Bearer ' + userToken },
        });

        let selectedData = data?.data?.page_data[0];

        onChangeAddEditStore({
            username: selectedData?.name,
            selectedRole: [{
                id,
                name: selectedData?.role_name,
            }],
        });

    } catch (error) {
        console.log(error);
        onChangeSnackbarNotification({
            status: 'error',
            title: 'Gagal mendapatkan data!',
            description: 'Tolong periksa jaringan anda!',
            duration: 5,
        });
    } finally {
        onChangeLoader({ isLoadingPage: false });
    }
};

//====================================== UPLOAD FILE ============================

export const onUploadFile = async ({ onChangePopUp, selectedFile, onChangeSnackbarNotification, onClose }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    try {
        onChangePopUp({ isLoading: true });

        const formData = new FormData();

        formData.append('file', selectedFile);

        await Axios({
            url: `/api/v1/user/partner-user/import`,
            method: 'POST',
            headers: { Authorization: 'Bearer ' + userToken },
            data: formData,
        });

        onChangeSnackbarNotification({
            status: 'success',
            title: 'Success',
            description: 'Berhasil upload file',
            duration: 5,
            detailMessage: [],
            alwaysShow: false,
        });
    } catch (error) {
        console.log(error);

        const errorMessage = error?.response?.data?.error;

        onChangeSnackbarNotification({
            status: 'error',
            title: 'Failed',
            description: 'Gagal upload file',
            detailMessage: errorMessage,
            alwaysShow: true,
            duration: 5,
        });
    } finally {
        onClose();
    }
};

//====================================== DOWNLOAD TEMPLATE FILE ============================

export const onDownloadTemplateFile = async ({ onChangeDownloadNotification, status }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');
    const fileName = 'sagitarius_template_usermanagement';

    if (status === 'download') return;

    onChangeDownloadNotification({
        status: 'download-template',
        title: 'Downloading...',
        description: 'Sedang mendownload file template',
    });

    try {
        const { data } = await Axios({
            method: 'GET',
            url: `/api/v1/user/template-user`,
            responseType: 'blob',
            headers: {
                Authorization: `Bearer ${userToken}`,
            },
        });

        const blob = new Blob([data], { type: 'text/csv' });

        saveAs(blob, `${fileName}.csv`);

        onChangeDownloadNotification({
            status: 'success',
            title: 'Success',
            description: 'Berhasil mendownload file template',
        });
    } catch (error) {
        onChangeDownloadNotification({
            status: 'error',
            title: 'Error',
            description: 'Gagal mendownload file template',
        });
    }
};