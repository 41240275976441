import { Box, Flex, Text, Button } from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import moment from 'moment';

import { useSnackbarNotification } from '@store/snackbarNotification';
import { useDownloadNotification } from '@store/downloadNotification';
import { ConditionalComponent } from '@components/functionComponent';
import EmptyState from '@components/emptyState';

import { useConfigAssetDetail, useConfigDetail } from '@pages/StoreConfig/helpers/mainState';
import { fetchAssetDetail, fetchStoreInfo, onDownloadHistory, onSubmit } from '@pages/StoreConfig/helpers/crud';
import { onChangeSetting } from '@pages/StoreConfig/helpers/event';
import { CheckboxComponent, InputComponent, TextComponent } from '@pages/StoreConfig/components/detailElement';
import Replenisment from '@pages/StoreConfig/components/replenishment';
import Dropdown from '@pages/StoreConfig/components/dropdown';
import { ChevronLeftIcon } from '@assets/icons';

import { nonActiveOption, statusTransferOptions } from './const';
import './styles.scss';

const StoreConfigDetail = () => {
    const navigate = useNavigate();
    const { onChangeDownloadNotification } = useDownloadNotification();
    const { onChangeSnackbarNotification } = useSnackbarNotification();
    const assetsStore = useConfigAssetDetail();
    const [isBackgroundProgress, setIsBackgroundProgress] = useState(false);
    const store = useConfigDetail();
    const { id } = useParams();

    const storeInfo = store?.storeInfo;

    const onFetchData = () => {
        fetchStoreInfo({ onChangeState: store?.onChangeConfigDetail, id });
        fetchAssetDetail({ onChangeAssetState: assetsStore?.onChangeConfigAssetDetail });
    };

    useEffect(() => {
        if (id) onFetchData();

        return () => {
            store?.onResetConfigDetail();
            assetsStore?.onResetConfigAssetDetail();
        };
    }, [id]);

    return (
        <Box className="store-config-detail__container">
            {/* //======================= LEFT SECTION ========================= */}

            <Box className="left-section">
                <Flex className="head">
                    <Text className="title" onClick={() => navigate('/store-config/iseller')}>
                        <ChevronLeftIcon />
                        Setting
                    </Text>

                    {/* //======================= TEMPLATE OPTIONS ========================= */}

                    <ConditionalComponent statement={storeInfo?.system_type?.name}>
                        <Box className="dropdown-item__head">
                            <Dropdown
                                store={store}
                                options={assetsStore?.templateList}
                                selectedItem={storeInfo?.template_upload?.name}
                                fieldName="template_upload"
                                onForceChange={(value) => {
                                    store?.onChangeConfigDetail({
                                        storeInfo: {
                                            ...storeInfo,
                                            ...value?.predefined_selected_config,
                                            template_upload: value,
                                        },
                                    });
                                }}
                            />
                        </Box>
                    </ConditionalComponent>
                </Flex>

                <ConditionalComponent
                    statement={storeInfo?.system_type?.name === 'iSeller' || store?.isLoading}
                    fallback={<EmptyState text="Not Found" description="Pilih sistem toko terlebih dahulu" />}
                >
                    <Flex className="body">
                        {/* //======================= INTEGRASI ========================= */}

                        <Box className="section-item">
                            <Text className="label">Integrasi</Text>
                            <Flex className="checkbox-item">
                                <Text className="checkbox-label">Status</Text>
                                <CheckboxComponent
                                    onChangeSetting={onChangeSetting}
                                    storeInfo={storeInfo}
                                    store={store}
                                    fieldName="is_active"
                                    isTemplateType
                                />
                            </Flex>

                            <Flex className="checkbox-item">
                                <Text className="checkbox-label">Get order</Text>
                                <CheckboxComponent
                                    onChangeSetting={onChangeSetting}
                                    storeInfo={storeInfo}
                                    store={store}
                                    fieldName="is_get_order"
                                    isTemplateType
                                />
                            </Flex>

                            <Flex className="input-item">
                                <Text className="input-label">URL iseller</Text>
                                <InputComponent
                                    placeholder="Input URL"
                                    onChangeSetting={onChangeSetting}
                                    fieldName="iseller_url"
                                    store={store}
                                    storeInfo={storeInfo}
                                />
                            </Flex>
                        </Box>

                        {/* //======================= SELLOUT ========================= */}

                        <Box className="section-item">
                            <Text className="label">Sellout</Text>
                            <Flex className="checkbox-item">
                                <Text className="checkbox-label">Customer ID desuai iseller</Text>
                                <CheckboxComponent
                                    onChangeSetting={onChangeSetting}
                                    storeInfo={storeInfo}
                                    store={store}
                                    fieldName="is_trusted_iseller"
                                    isTemplateType
                                />
                            </Flex>

                            <Flex className="checkbox-item">
                                <Text className="checkbox-label">Sync bivi dashboard</Text>

                                <CheckboxComponent
                                    onChangeSetting={onChangeSetting}
                                    storeInfo={storeInfo}
                                    store={store}
                                    fieldName="is_sync_bivi_dashboard"
                                    isTemplateType
                                />
                            </Flex>

                            <Flex className="dropdown-item">
                                <Text className="dropdown-label">Tutup input bivi</Text>
                                {console.log(assetsStore?.systemList)}
                                <Dropdown
                                    store={store}
                                    options={[nonActiveOption, ...assetsStore?.systemList]}
                                    selectedItem={storeInfo?.tag?.name}
                                    fieldName="tag"
                                />
                            </Flex>
                        </Box>

                        {/* //======================= REPLENISMENT ========================= */}

                        <Replenisment
                            id={id}
                            setIsBackgroundProgress={setIsBackgroundProgress}
                            isBackgroundProgress={isBackgroundProgress}
                        />

                        {/* //======================= TRANSFER IN ========================= */}

                        <Box className="section-item">
                            <Text className="label">Tranfer in</Text>

                            <Flex className="checkbox-item">
                                <Text className="checkbox-label">Transfer in aktif</Text>
                                <CheckboxComponent
                                    onChangeSetting={onChangeSetting}
                                    storeInfo={storeInfo}
                                    store={store}
                                    fieldName="is_sync_transfer_in"
                                    isTemplateType
                                />
                            </Flex>

                            <Flex className="checkbox-item">
                                <Text className="checkbox-label">Toko consigment</Text>

                                <CheckboxComponent
                                    onChangeSetting={onChangeSetting}
                                    storeInfo={storeInfo}
                                    store={store}
                                    fieldName="is_sync_consignment"
                                />
                            </Flex>

                            <ConditionalComponent statement={storeInfo?.is_sync_consignment}>
                                <Flex className="input-item">
                                    <Text className="input-label">Customer ID consigment</Text>
                                    <InputComponent
                                        placeholder="Input ID"
                                        onChangeSetting={onChangeSetting}
                                        fieldName="customer_id_konsi"
                                        store={store}
                                        storeInfo={storeInfo}
                                        isTemplateType
                                    />
                                </Flex>
                            </ConditionalComponent>

                            <Flex className="dropdown-item">
                                <Text className="dropdown-label">Status transfer</Text>
                                <Dropdown
                                    store={store}
                                    options={statusTransferOptions}
                                    selectedItem={storeInfo?.transfer_status?.name}
                                    fieldName="transfer_status"
                                    isTemplateType
                                />
                            </Flex>
                        </Box>

                        {/* //======================= MASTER DATA ========================= */}

                        <Box className="section-item">
                            <Text className="label">Master data</Text>

                            <Flex className="text-item">
                                <Text className="text-label">Harga produk</Text>
                                <Text className="text-value">{storeInfo?.het_price_name?.name}</Text>
                            </Flex>

                            <Flex className="text-item">
                                <Text className="text-label">Harga biodef</Text>
                                <Text className="text-value"> {storeInfo?.lp_price_name?.name}</Text>
                            </Flex>
                        </Box>

                        {/* //======================= CREATE PRODUCT ========================= */}

                        <Box className="section-item">
                            <Text className="label">Create Product</Text>

                            <Flex className="checkbox-item">
                                <Text className="checkbox-label">Create produk</Text>

                                <CheckboxComponent
                                    onChangeSetting={onChangeSetting}
                                    storeInfo={storeInfo}
                                    store={store}
                                    fieldName="is_sync_create_product"
                                    isTemplateType
                                />
                            </Flex>

                            <Flex className="checkbox-item">
                                <Text className="checkbox-label">Create dengan harga</Text>
                                <CheckboxComponent
                                    onChangeSetting={onChangeSetting}
                                    storeInfo={storeInfo}
                                    store={store}
                                    fieldName="is_create_product_with_price"
                                    isTemplateType
                                />
                            </Flex>

                            <Flex className="checkbox-item">
                                <Text className="checkbox-label">Create dengan deskripsi</Text>
                                <CheckboxComponent
                                    onChangeSetting={onChangeSetting}
                                    storeInfo={storeInfo}
                                    store={store}
                                    fieldName="is_create_product_with_description"
                                    isTemplateType
                                />
                            </Flex>

                            <Flex className="checkbox-item">
                                <Text className="checkbox-label">Tetap jual dengan stok habis</Text>

                                <CheckboxComponent
                                    onChangeSetting={onChangeSetting}
                                    storeInfo={storeInfo}
                                    store={store}
                                    fieldName="is_create_product_continue_selling_when_soldout"
                                    isTemplateType
                                />
                            </Flex>

                            <Flex className="checkbox-item">
                                <Text className="checkbox-label">Create attribute produk</Text>
                                <CheckboxComponent
                                    onChangeSetting={onChangeSetting}
                                    storeInfo={storeInfo}
                                    store={store}
                                    fieldName="is_create_product_with_attribute"
                                    isTemplateType
                                />
                            </Flex>
                        </Box>

                        {/* //======================= UPDATE PRODUCT ========================= */}

                        <Box className="section-item">
                            <Text className="label">Update Product</Text>

                            <Flex className="checkbox-item">
                                <Text className="checkbox-label">Update produk</Text>
                                <CheckboxComponent
                                    onChangeSetting={onChangeSetting}
                                    storeInfo={storeInfo}
                                    store={store}
                                    fieldName="is_sync_update_product"
                                    isTemplateType
                                />
                            </Flex>

                            <Flex className="checkbox-item">
                                <Text className="checkbox-label">Update dengan harga</Text>
                                <CheckboxComponent
                                    onChangeSetting={onChangeSetting}
                                    storeInfo={storeInfo}
                                    store={store}
                                    fieldName="is_update_product_with_price"
                                    isTemplateType
                                />
                            </Flex>

                            <Flex className="checkbox-item">
                                <Text className="checkbox-label">Update nama</Text>
                                <CheckboxComponent
                                    onChangeSetting={onChangeSetting}
                                    storeInfo={storeInfo}
                                    store={store}
                                    fieldName="is_update_product_with_name"
                                    isTemplateType
                                />
                            </Flex>

                            <Flex className="checkbox-item">
                                <Text className="checkbox-label">Update deskripsi</Text>
                                <CheckboxComponent
                                    onChangeSetting={onChangeSetting}
                                    storeInfo={storeInfo}
                                    store={store}
                                    fieldName="is_update_product_with_description"
                                    isTemplateType
                                />
                            </Flex>

                            <Flex className="checkbox-item">
                                <Text className="checkbox-label">Tetap jual dengan stok habis</Text>
                                <CheckboxComponent
                                    onChangeSetting={onChangeSetting}
                                    storeInfo={storeInfo}
                                    store={store}
                                    fieldName="is_update_product_continue_selling_when_soldout"
                                    isTemplateType
                                />
                            </Flex>

                            <Flex className="checkbox-item">
                                <Text className="checkbox-label">Update attribute produk</Text>
                                <CheckboxComponent
                                    onChangeSetting={onChangeSetting}
                                    storeInfo={storeInfo}
                                    store={store}
                                    fieldName="is_update_product_with_attribute"
                                    isTemplateType
                                />
                            </Flex>
                        </Box>
                    </Flex>
                </ConditionalComponent>
            </Box>

            {/* //======================= RIGHT SECTION ========================= */}

            <Box className="right-section">
                <Text className="label">Informasi Toko</Text>

                <Flex className="text-item">
                    <Text className="text-label">Nama outlet</Text>
                    <TextComponent value={storeInfo?.nama_outlet} store={store} />
                </Flex>

                <Flex className="text-item">
                    <Text className="text-label">Customer ID</Text>
                    <TextComponent value={storeInfo?.customer_id} store={store} />
                </Flex>

                <Flex className="text-item">
                    <Text className="text-label">Store group</Text>
                    <TextComponent value={storeInfo?.group} store={store} />
                </Flex>

                <Flex className="text-item">
                    <Text className="text-label">Kode tipe outlet</Text>
                    <TextComponent value={storeInfo?.kode_tipe_outlet} store={store} />
                </Flex>

                <Flex className="dropdown-item">
                    <Text className="dropdown-label">Tipe sistem toko</Text>
                    <Dropdown
                        store={store}
                        options={assetsStore?.systemList}
                        selectedItem={storeInfo?.system_type?.name}
                        fieldName="system_type"
                    />
                </Flex>

                <Flex className="text-item">
                    <Text className="text-label">Store DC</Text>
                    <TextComponent value={storeInfo?.nama_dc} store={store} />
                </Flex>

                <Flex className="text-item">
                    <Text className="text-label">Store area</Text>
                    <TextComponent value={storeInfo?.sales_area} store={store} />
                </Flex>
                <Flex className="text-item">
                    <Text className="text-label">Last edit time</Text>
                    <TextComponent
                        value={moment(storeInfo?.latestUpdate?.created_at).format('DD MMMM YYYY hh:mm')}
                        store={store}
                    />
                </Flex>

                <Flex className="text-item">
                    <Text className="text-label">Last edited by</Text>
                    <TextComponent
                        value={`${storeInfo?.latestUpdate?.name || ''} - ${storeInfo?.latestUpdate?.email || ''}`}
                        store={store}
                    />
                </Flex>

                <Flex className="actions">
                    <Button
                        className="history-button"
                        onClick={() => {
                            onDownloadHistory({ id, onChangeDownloadNotification });
                        }}
                    >
                        History
                    </Button>
                    <Button
                        className="save-button"
                        isLoading={store?.isLoading}
                        onClick={() =>
                            onSubmit({
                                storeInfo,
                                isBackgroundProgress,
                                onChangeState: store?.onChangeConfigDetail,
                                onChangeSnackbarNotification,
                                onFetchData,
                                id,
                            })
                        }
                    >
                        Save
                    </Button>
                </Flex>
            </Box>
        </Box>
    );
};

export default StoreConfigDetail;
