import { Button, Box } from '@chakra-ui/react';
import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import { modules, formats } from './const';
import './styles.scss';

import 'react-quill/dist/quill.snow.css'; // Import the styles

const Editor = ({ currentValue, onSave }) => {
    const [value, setValue] = useState(currentValue);

    return (
        <Box className="richtext-editor__container">
            <ReactQuill value={value} modules={modules} onChange={setValue} formats={formats} />
            <Button className="button-container" onClick={() => onSave({ value })}>
                Save
            </Button>
        </Box>
    );
};

export default Editor;
