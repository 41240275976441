import { Box } from '@chakra-ui/react';
import './styles.scss';
import { SagitariusLogo } from '@assets/logo';
import { useEffect, useState } from 'react';
const SplashScreen = () => {
    const [isSplashScreen, setIsSplashScreen] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setIsSplashScreen(false);
        }, 4000);
    }, []);

    return (
        <Box className={`splash-screen ${!isSplashScreen && 'hide-splash-screen'}`}>
            <Box className="box">
                <SagitariusLogo />
                <Box className="progress-bar__container">
                    <Box className="progress" />
                </Box>
            </Box>
        </Box>
    );
};

export default SplashScreen;
