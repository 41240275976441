import { Box, Text } from '@chakra-ui/react';
import { memo } from 'react';

const Note = ({ data }) => {
    return (
        <Box className="flow-notes__container" onClick={() => data?.onSelectElement(data)}>
            <Text>{data.label}</Text>
        </Box>
    );
};

export default memo(Note);
