import { Box, Flex, Text, Input } from '@chakra-ui/react';
import { CloseIcon, EditIcon } from '@assets/icons';
import { ColorPalleteIcon, IdIcon, NodeIcon, TypeIcon } from '../../icons';
import { ChevronLeftIcon, CopyIcon } from '@chakra-ui/icons';
import OutsideClickHandler from 'react-outside-click-handler';
import { useEffect, useState } from 'react';
import { SketchPicker } from 'react-color';
import copy from 'copy-to-clipboard';

import './styles.scss';
import { ConditionalComponent, IterationComponent } from '@components/functionComponent';
import { useSnackbarNotification } from '@store/snackbarNotification';
import { find } from 'lodash';

const FloatingDetail = ({ selectedElement, onHide, setNodes, nodes, handleOnRemoveNode, setEdges, edges }) => {
    const [isEditTitle, setIsEditTitle] = useState(false);
    const { onChangeSnackbarNotification } = useSnackbarNotification();
    const [openColorPallete, setOpenColorPallete] = useState(null);
    const [label, setLabel] = useState(null);
    const [selectedParent, setSelectedParent] = useState();
    const [isOpen, setIsOpen] = useState(false);
    const [selectedBackground, setSelectedBackground] = useState();
    const [selectedColor, setSelectedColor] = useState();
    const [selectedBorderColor, setSelectedBorderColor] = useState();
    const [selectedEdgePosition, setSelectedEdgePositon] = useState();

    useEffect(() => {
        setSelectedParent(selectedElement?.parentNode);
        setSelectedBackground(selectedElement?.background);
        setSelectedColor(selectedElement?.color);
        setSelectedBorderColor(selectedElement?.borderColor);
        setSelectedEdgePositon(selectedElement?.data?.edgePosition);
    }, [selectedElement]);

    const handleOnParentNode = () => {
        if (selectedElement?.type.includes('Edge')) {
            setEdges((eds) =>
                eds.map((edge) => {
                    if (edge?.id === selectedElement?.id) {
                        edge.label = label;
                        edge.data = {
                            edgePosition: selectedEdgePosition || 'middle',
                        };
                    }

                    return edge;
                })
            );
        } else {
            setNodes((nds) =>
                nds.map((node) => {
                    if (node.id === selectedElement?.id) {
                        if (selectedParent?.id && selectedParent?.data?.id !== node?.data?.parentNode?.id) {
                            node.position = { x: 16, y: 16 };
                        }

                        if (selectedParent?.data?.label === '-') {
                            node.parentNode = null;
                            node.data.parentNode = null;
                        } else {
                            node.parentNode = selectedParent?.data?.id;
                            node.data.parentNode = selectedParent;
                        }

                        node.data.label = label;
                        node.data.background = selectedBackground;
                        node.data.color = selectedColor;
                        node.data.borderColor = selectedBorderColor;
                    }

                    return node;
                })
            );
        }

        const elementPane = document.getElementsByClassName('react-flow__pane')[0];

        elementPane.click();

        onHide();
    };

    useEffect(() => {
        if (selectedElement?.id) {
            setSelectedParent(selectedElement?.parentNode);
            setLabel(selectedElement?.label);
        }
    }, [selectedElement]);
    return (
        <OutsideClickHandler onOutsideClick={onHide}>
            <Box
                className={`${
                    selectedElement && 'right-side__floating-container__show'
                } right-side__floating-container`}
            >
                <Flex mb="24px" alignItems="center" gap="6px" width="max-content" cursor="pointer" onClick={onHide}>
                    <ChevronLeftIcon fontSize="16px" color="var(--main-color)" />
                    <Text className="hide-menu">Hide</Text>
                </Flex>
                <Flex className="head">
                    <ConditionalComponent
                        statement={!isEditTitle}
                        fallback={
                            <OutsideClickHandler onOutsideClick={() => setIsEditTitle(false)}>
                                <Input value={label} onChange={(e) => setLabel(e.target.value)} />
                            </OutsideClickHandler>
                        }
                    >
                        <Text className="title">{label} </Text>
                    </ConditionalComponent>
                    <Box cursor="pointer" onClick={() => setIsEditTitle(true)}>
                        <ConditionalComponent
                            statement={!isEditTitle}
                            fallback={<CloseIcon color="var(--main-color)" />}
                        >
                            <EditIcon />
                        </ConditionalComponent>
                    </Box>
                </Flex>
                <Flex className="body">
                    <Flex className="item">
                        <Text className="label">
                            <IdIcon />
                            ID
                        </Text>
                        <Flex alignItems="center">
                            <Text className="name id-column" color="#1d1d1d">
                                <CopyIcon
                                    cursor="pointer"
                                    fontSize="14px"
                                    color="var(--main-color)"
                                    onClick={() => {
                                        copy(selectedElement?.id);
                                        onChangeSnackbarNotification({
                                            status: 'success',
                                            title: 'Berhasil',
                                            description: `Text berhasil di copy ke clipboard`,
                                            duration: 3,
                                        });
                                    }}
                                />
                                {selectedElement?.id}
                            </Text>
                        </Flex>
                    </Flex>

                    <ConditionalComponent statement={selectedElement?.type.includes('Edge')}>
                        <Flex className="item">
                            <Text className="label">
                                <TypeIcon />
                                Position
                            </Text>
                            <Text className="name" color="#1d1d1d" cursor="pointer" onClick={() => setIsOpen(true)}>
                                {selectedEdgePosition || 'middle'}
                            </Text>
                            <ConditionalComponent statement={isOpen}>
                                <Box className="select-parent__container">
                                    <OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
                                        <IterationComponent
                                            arr={['s    tart', 'middle', 'end']}
                                            render={(value) => (
                                                <Text
                                                    className="opiton-item"
                                                    onClick={() => {
                                                        setIsOpen(false);
                                                        setSelectedEdgePositon(value);
                                                    }}
                                                >
                                                    {value}
                                                </Text>
                                            )}
                                        />
                                    </OutsideClickHandler>
                                </Box>
                            </ConditionalComponent>
                        </Flex>
                    </ConditionalComponent>

                    <ConditionalComponent statement={!selectedElement?.type.includes('Edge')}>
                        <Flex className="item">
                            <Text className="label">
                                <TypeIcon />
                                Type
                            </Text>
                            <Text className="name" color="#1d1d1d">
                                {selectedElement?.type}
                            </Text>
                        </Flex>

                        <Flex className="item">
                            <Text className="label">
                                <ColorPalleteIcon />
                                Background
                            </Text>
                            <Box
                                className="color"
                                background={selectedBackground ? selectedBackground?.hex : 'var(--main-color-light)'}
                                onClick={() => setOpenColorPallete('selectedBackground')}
                            ></Box>

                            <ConditionalComponent statement={openColorPallete === 'selectedBackground'}>
                                <OutsideClickHandler onOutsideClick={() => setOpenColorPallete(false)}>
                                    <SketchPicker
                                        onChange={(color) => setSelectedBackground(color)}
                                        color={selectedBackground}
                                    />
                                </OutsideClickHandler>
                            </ConditionalComponent>
                        </Flex>

                        <Flex className="item">
                            <Text className="label">
                                <ColorPalleteIcon />
                                Text Color
                            </Text>
                            <Box
                                className="color"
                                background={selectedColor ? selectedColor?.hex : 'var(--main-color-dark)'}
                                onClick={() => setOpenColorPallete('selectedColor')}
                            ></Box>

                            <ConditionalComponent statement={openColorPallete === 'selectedColor'}>
                                <OutsideClickHandler onOutsideClick={() => setOpenColorPallete(false)}>
                                    <SketchPicker onChange={(color) => setSelectedColor(color)} color={selectedColor} />
                                </OutsideClickHandler>
                            </ConditionalComponent>
                        </Flex>

                        <Flex className="item">
                            <Text className="label">
                                <ColorPalleteIcon />
                                Border Color
                            </Text>
                            <Box
                                className="color"
                                background={selectedBorderColor ? selectedBorderColor?.hex : 'var(--main-color-dark)'}
                                onClick={() => setOpenColorPallete('selectedBorderColor')}
                            ></Box>

                            <ConditionalComponent statement={openColorPallete === 'selectedBorderColor'}>
                                <OutsideClickHandler onOutsideClick={() => setOpenColorPallete(false)}>
                                    <SketchPicker
                                        onChange={(color) => setSelectedBorderColor(color)}
                                        color={selectedBorderColor}
                                    />
                                </OutsideClickHandler>
                            </ConditionalComponent>
                        </Flex>

                        <Flex className="item">
                            <Text className="label">
                                <NodeIcon />
                                Parent
                            </Text>

                            <Text className="name" color="#1d1d1d" cursor="pointer" onClick={() => setIsOpen(true)}>
                                {selectedParent?.data?.label || 'Choose parent'}
                            </Text>
                            <ConditionalComponent statement={isOpen}>
                                <Box className="select-parent__container">
                                    <OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
                                        <ConditionalComponent
                                            statement={
                                                nodes?.filter(
                                                    (item) =>
                                                        item?.data?.type === 'Group' ||
                                                        item?.data?.id !== selectedElement?.id
                                                )?.length
                                            }
                                            fallback={<Text className="not-found">Not Found</Text>}
                                        >
                                            <IterationComponent
                                                arr={[
                                                    ...nodes?.filter(
                                                        (item) =>
                                                            item?.data?.type === 'Group' &&
                                                            item?.data?.id !== selectedElement?.id
                                                    ),
                                                    {
                                                        data: {
                                                            label: '-',
                                                            id: null,
                                                        },
                                                    },
                                                ]}
                                                render={(node) => (
                                                    <Text
                                                        className="opiton-item"
                                                        onClick={() => {
                                                            setSelectedParent(node);
                                                            setIsOpen(false);
                                                        }}
                                                    >
                                                        {node?.data?.label}
                                                    </Text>
                                                )}
                                            />
                                        </ConditionalComponent>
                                    </OutsideClickHandler>
                                </Box>
                            </ConditionalComponent>
                        </Flex>
                    </ConditionalComponent>
                </Flex>
                <Flex className="footer">
                    <Text className="save-item" onClick={() => handleOnParentNode()}>
                        Save Changes
                    </Text>
                    {/* <DeleteIcon /> */}
                    <Text className="delete-item" onClick={() => handleOnRemoveNode(selectedElement?.id)}>
                        Remove Elements
                    </Text>
                </Flex>
            </Box>
        </OutsideClickHandler>
    );
};

export default FloatingDetail;
