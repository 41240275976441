import { useEffect } from 'react';
import Cookies from 'universal-cookie';
import { Box, Image, Text } from '@chakra-ui/react';
import { redirectToPage } from '@helpers/events';
import { isEmpty } from 'lodash';

import logo from './forbidden-icon.svg';
import searchItem from './search-icon.svg';
import './styles.scss';
import { useNavigate } from 'react-router-dom';

const ForbiddenPage = () => {
    const cookies = new Cookies();
    const navigate = useNavigate();

    useEffect(() => {
        const menuAccess = cookies.get('menuAccess');
        if (!isEmpty(menuAccess)) redirectToPage({ menuAccess, navigate });
    }, []);
    return (
        <Box className="forbidden-page__container">
            <Box className="icon">
                <Image src={logo} className="empty-image" />
                <Image src={searchItem} className="search" />
            </Box>
            <Text className="desc">Page ini tidak tersedia</Text>
        </Box>
    );
};

export default ForbiddenPage;
