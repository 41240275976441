export const onTransformedHistory = ({ histories }) => {
    return histories?.map((history) => ({
        date_uploaded: history?.date_uploaded,
        name: history?.name,
        type_date: history?.type_date,
        status: history?.status,
        upload_type: history?.upload_type
            ? `${history.upload_type} - ${history?.detail_upload_type ?? 'none'}`
            : undefined,
    }));
};
