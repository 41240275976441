import { Flex, Button, Box, Text, Divider } from '@chakra-ui/react';
import Badge from '@components/badge';
import FloatingMenu from '@components/floatingMenu';
import './styles.scss';
import { ConditionalComponent } from '@components/functionComponent';
import moment from 'moment';
import FloatingList from '@components/floatingList';
import { useState, useContext } from 'react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { usePopUp } from '@store/popup';
import { useDownloadNotification } from '@store/downloadNotification';
import { PageContext } from '@pages/StockTrail';
import { useSnackbarNotification } from '@store/snackbarNotification';
import {
    onCancelStockTrailHandler,
    onClickDownloadFile,
    onFailedStockTrailHandler,
    onProcessingStockTrailHandler,
    onResendStockTrailHandler,
    onSuccessStockTrailHandler,
} from '@pages/StockTrail/helpers/events';
import { onDownloadFile } from '@pages/StockTrail/helpers/crud';
import UploadZone from '@components/uploadZone';

export const BadgeStatus = ({ status }) => {
    const statusMap = {
        success: { title: 'Success', color: '#4B4B4B', backgroundColor: '#EAF6E9' },
        cancelled: { title: 'Cancelled', color: '#F24822', backgroundColor: '#FFC7C2' },
        failed: { title: 'Failed', color: '#F24822', backgroundColor: '#FFC7C2' },
        'unhandled file format': {
            title: 'Unhandled File Format',
            color: '#F24822',
            backgroundColor: '#FFC7C2',
        },
        uploaded: { title: 'Uploaded', color: '#457383', backgroundColor: '#dbedf3' },
        pending: { title: 'Pending', color: '#D47C00', backgroundColor: '#FFE8A3' },
        processing: { title: 'Processing', color: '#457383', backgroundColor: '#dbedf3' },
    };

    const statusInfo = statusMap[status];

    return <Badge {...statusInfo} />;
};

export const DateSellout = ({ value }) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <Box className="date-sellout-column__stock-trail">
            <Text>{value ? moment(value[0]).format('DD MMM YYYY') : '-'}</Text>
            <ConditionalComponent statement={value?.length > 1}>
                <Text className="see-more__button" onClick={() => setIsOpen(true)}>
                    More
                    <ChevronDownIcon fontSize="sm" />
                </Text>
                <ConditionalComponent statement={isOpen}>
                    <FloatingList
                        arr={value}
                        renderElement={(item, idx) => (
                            <Text className="date-item">{`${idx + 1}. ${moment(item).format('DD MMM YYYY')}`}</Text>
                        )}
                        onClose={() => setIsOpen(false)}
                    />
                </ConditionalComponent>
            </ConditionalComponent>
        </Box>
    );
};

export const QtySellout = ({ value }) => {
    const [isOpen, setIsOpen] = useState(false);

    const qty = value || [];

    let arr = Object.keys(qty)?.map((key) => {
        return {
            ...value[key],
            name: key,
        };
    });

    arr = arr?.filter((item) => item?.name !== 'total');

    return (
        <Box className="qty-sellout-column__stock-trail">
            <Text>{arr?.length > 0 ? `${arr[0]?.name} ${arr[0]?.sku} SKU, ${arr[0]?.qty} quantity` : '-'}</Text>

            <ConditionalComponent statement={arr?.length > 1}>
                <Text className="see-more__button" onClick={() => setIsOpen(true)}>
                    More
                    <ChevronDownIcon fontSize="sm" />
                </Text>
                <ConditionalComponent statement={isOpen}>
                    <FloatingList
                        arr={arr}
                        renderElement={(item, idx) => (
                            <Text className="qty-item">{`${idx + 1}. ${item?.name} ${item?.sku} SKU, ${
                                item?.qty
                            } quantity`}</Text>
                        )}
                        onClose={() => setIsOpen(false)}
                    />
                </ConditionalComponent>
            </ConditionalComponent>
        </Box>
    );
};

export const ActionsComponent = ({ values }) => {
    const [isOpenUpload, setIsOpenUpload] = useState(false);
    const { onChangePopUp, onResetPopUp } = usePopUp();
    const { onChangeDownloadNotification } = useDownloadNotification();
    const { onChangeSnackbarNotification } = useSnackbarNotification();
    const { refetchStockTrail } = useContext(PageContext);

    const listActionMenu = [
        <ConditionalComponent statement={values?.meta_data?.is_download_original_file}>
            <Box
                className="action-menu"
                onClick={() =>
                    onClickDownloadFile({
                        onChangePopUp,
                        onResetPopUp,
                        onChangeDownloadNotification,
                        onChangeSnackbarNotification,
                        refetchStockTrail,
                        values,
                    })
                }
            >
                <Text> Download File</Text>
            </Box>
        </ConditionalComponent>,
        <ConditionalComponent statement={values?.meta_data?.is_download_processed_file}>
            <Box
                className="action-menu"
                onClick={() =>
                    onDownloadFile({
                        type: `processed-file`,
                        path: values?.processed_filename,
                        onChangeDownloadNotification,
                    })
                }
            >
                <Text> Download Processed</Text>
            </Box>
        </ConditionalComponent>,
        <ConditionalComponent statement={values?.meta_data?.is_send_cancel}>
            <Box
                className="action-menu"
                onClick={() =>
                    onCancelStockTrailHandler({
                        onChangePopUp,
                        refetchStockTrail,
                        onChangeSnackbarNotification,
                        onResetPopUp,
                        values,
                    })
                }
            >
                <Text> Cancel</Text>
            </Box>
        </ConditionalComponent>,
        <ConditionalComponent statement={values?.meta_data?.is_send_pending}>
            <Box
                className="action-menu"
                onClick={() =>
                    onResendStockTrailHandler({
                        onChangePopUp,
                        refetchStockTrail,
                        onChangeSnackbarNotification,
                        onResetPopUp,
                        values,
                    })
                }
            >
                <Text> Resend</Text>
            </Box>
        </ConditionalComponent>,
        <ConditionalComponent
            statement={
                values?.meta_data?.is_can_send_processing ||
                values?.meta_data?.is_can_send_failed ||
                values?.meta_data?.is_can_send_success
            }
        >
            <Divider borderBottomColor="#bbadad" />
        </ConditionalComponent>,
        <ConditionalComponent statement={values?.meta_data?.is_can_send_processing}>
            <Box
                className="action-menu"
                onClick={() =>
                    onProcessingStockTrailHandler({
                        onChangePopUp,
                        refetchStockTrail,
                        onChangeSnackbarNotification,
                        onResetPopUp,
                        values,
                    })
                }
            >
                <Text> Processing</Text>
            </Box>
        </ConditionalComponent>,
        <ConditionalComponent statement={values?.meta_data?.is_can_send_failed}>
            <Box
                className="action-menu"
                onClick={() =>
                    onFailedStockTrailHandler({
                        onChangePopUp,
                        refetchStockTrail,
                        onChangeSnackbarNotification,
                        onResetPopUp,
                        values,
                    })
                }
            >
                <Text> Failed</Text>
            </Box>
        </ConditionalComponent>,
        <ConditionalComponent statement={values?.meta_data?.is_can_send_success}>
            <Box className="action-menu" onClick={() => setIsOpenUpload(true)}>
                <Text> Success</Text>
            </Box>
        </ConditionalComponent>,
    ];

    return (
        <Flex gap="16px" className="actions-container__stock-trail">
            <FloatingMenu
                className="dotted-icon"
                isClickMenu={true}
                list={listActionMenu}
                buttonElement={<Button className="action-button">Actions</Button>}
            />
            <ConditionalComponent statement={isOpenUpload}>
                <UploadZone
                    title="Upload File"
                    onClose={() => setIsOpenUpload(false)}
                    fileTypeAllowed={['csv', 'xlsx', 'pdf']}
                    onUploadFile={(selectedFile) =>
                        onSuccessStockTrailHandler({
                            onChangePopUp,
                            refetchStockTrail,
                            selectedFile,
                            onChangeSnackbarNotification,
                            onResetPopUp,
                            values,
                        })
                    }
                />
            </ConditionalComponent>
        </Flex>
    );
};
