import { Img } from '@chakra-ui/react';
import sagitariusLogoProduction from './production-logo.png';
import sagitariusSmallLogoProduction from './production-logo-small.png';

export const PeopleIcon = () => (
    <svg
        className="people-icon"
        width="32"
        height="32"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"
            fill="#437499"
            fillOpacity="0.25"
        />
        <circle cx="12" cy="10" r="4" fill="white" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.221 18.2462C18.2791 18.3426 18.2614 18.466 18.1796 18.5432C16.5675 20.0662 14.3928 21 12.0001 21C9.60734 21 7.4327 20.0663 5.82063 18.5433C5.73883 18.466 5.72107 18.3427 5.77924 18.2463C6.94343 16.318 9.29221 15 12.0001 15C14.708 15 17.0568 16.3179 18.221 18.2462Z"
            fill="white"
        />
    </svg>
);

export const SagitariusLogo = () => <Img width="141px" src={sagitariusLogoProduction} />;
export const SagitariusLogoSmall = () => <Img width="20px" src={sagitariusSmallLogoProduction} />;
