import { TableColumnSkeleton, TableHeadSkeleton } from '@components/tableTemplate/components/skeleton';
import { Box, Flex, Skeleton, Table } from '@chakra-ui/react';
import { ChevronDownIcon } from '@assets/icons';

const TableSkeleton = () => {
    return (
        <Box className="table-dashboard__skeleton">
            <Skeleton
                height="38px"
                w="160px"
                mb="36px"
                borderRadius="8px"
                startColor="var(--main-color-light-active)"
                endColor={`rgba(var(--main-color-light-active),0.5)`}
            />

            <Flex alignItems="center" mb="24px" gap="16px">
                <Box className="filter-search">
                    <Skeleton
                        height="32px"
                        w="100px"
                        borderRadius="8px"
                        startColor="var(--main-color-light-active)"
                        endColor={`rgba(var(--main-color-light-active),0.5)`}
                    />

                    <Skeleton
                        height="32px"
                        w="48px"
                        borderRadius="6px"
                        startColor="var(--main-color-light-active)"
                        endColor={`rgba(var(--main-color-light-active),0.5)`}
                    />
                </Box>

                <Box className="filter-dropdown">
                    <Skeleton
                        height="28px"
                        w="100px"
                        borderRadius="8px"
                        startColor="var(--main-color-light-active)"
                        endColor={`rgba(var(--main-color-light-active),0.5)`}
                    />
                    <ChevronDownIcon color="var(--main-color)" />
                </Box>

                <Box className="filter-dropdown">
                    <Skeleton
                        height="28px"
                        w="100px"
                        borderRadius="8px"
                        startColor="var(--main-color-light-active)"
                        endColor={`rgba(var(--main-color-light-active),0.5)`}
                    />
                    <ChevronDownIcon color="var(--main-color)" />
                </Box>
            </Flex>
            <Table>
                <TableHeadSkeleton tableList={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]} />
                <TableColumnSkeleton tableList={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]} />
            </Table>
        </Box>
    );
};

export default TableSkeleton;
