import Axios from '@services/axios';
import Cookies from 'universal-cookie';
import routeList from '@routes';
import { filteringRouteByMenuAccess } from '@helpers/utils';
import { redirectToPage } from '@helpers/events';

export const onFetchProfile = async ({
    navigate,
    pathname,
    onChangeRouteList,
    token,
    setIsLoadingUserProfile,
    onChangeUserInfo,
}) => {
    try {
        const cookies = new Cookies();
        setIsLoadingUserProfile(true);

        const { data } = await Axios({
            url: '/api/v1/store/info',
            method: 'GET',
            headers: { Authorization: 'Bearer ' + token },
        });

        const { isDefaultPassword, menuAccess, customer } = data;

        onChangeUserInfo({ userInfo: customer });

        onChangeRouteList({ routes: filteringRouteByMenuAccess([...routeList], menuAccess) });

        cookies.set('menuAccess', JSON.stringify(menuAccess), { path: '/' });


        //============================ NAVIGATE ========================

        if (isDefaultPassword) navigate('/reset-password');
        else if (pathname === '/reset-password') {
            redirectToPage({ menuAccess, navigate })
        }
    } catch (error) {
        console.log(error);
        navigate('/error-page');
    } finally {
        setIsLoadingUserProfile(false);
    }
};
