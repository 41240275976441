import { create } from 'zustand';

export const useExpandNavbar = create((set) => ({
    isExpandNavbar: true,
    onChangeExpandNavbar: (newState) => set((state) => ({ ...state, ...newState })),
    onResetExpandNavbar: () =>
        set(() => ({
            isExpandNavbar: true,
        })),
}));
