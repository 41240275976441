import { isArray } from 'lodash';

export const getDetailLineErrorMessage = (errorMessages) => {
    if (!isArray(errorMessages)) return errorMessages;

    const errorLine = errorMessages?.map(({ line }) => {
        return line?.substring(line.indexOf(':') + 1, line?.length)?.replace(' ', '');
    });

    let result = '';

    errorLine?.forEach((num, idx) => {
        result += idx === errorLine?.length - 1 ? num : `${num}, `;
    });

    return result;
};
