//========================== LIBRARY ===========================

import { Box, Flex, Text } from '@chakra-ui/react';
import TableTemplate from '@components/tableTemplate';

//========================== LOCAL ASSETS ===========================

import { tableList } from './const';
import './styles.scss';

const Error = ({ errors, isLoading }) => {
    return (
        <Box className="error__upload-sellout" id="error__upload-sellout">
            {/* //==================== HEAD =========================== */}

            <Flex className="head">
                <Text className="title">Error</Text>
            </Flex>

            {/* //==================== BODY =========================== */}

            <Flex className="body">
                <TableTemplate
                    tableList={tableList}
                    data={errors}
                    isLoading={isLoading}
                    dataCount={null}
                    totalData={null}
                    page={null}
                    totalPage={null}
                    handlePageClick={null}
                    isPagination={false}
                    isDynamicTable={false}
                />
                <Box className="gradation"></Box>
            </Flex>
        </Box>
    );
};

export default Error;
