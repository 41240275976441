export const onValidationPassword = ({ isMatchPasssword, isLongChar, isSpecialChar, isUppercaseChar }) => {
    return isMatchPasssword && isUppercaseChar && isLongChar && isSpecialChar;
};

export const onCheckingUppercase = ({ password }) => {
    for (let index = 0; index < password?.length; index++) {
        const str = password[index];
        if (str === str.toUpperCase() && str !== str.toLowerCase()) return true;
    }
};

export const onCheckingSpecialChar = ({ password }) => {
    // eslint-disable-next-line
    const format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    return format.test(password);
};
