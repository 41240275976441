import { Modal, ModalOverlay, ModalContent } from '@chakra-ui/react';

const ModalTemplate = ({ isOpen, onClose, children, width, modalContentCustomStyle }) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay onClick={onClose} />
            <ModalContent style={{ ...modalContentCustomStyle }} width={width} maxWidth={'none'}>
                {children}
            </ModalContent>
        </Modal>
    );
};

export default ModalTemplate;
