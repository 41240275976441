import { Box, Flex, Text } from '@chakra-ui/react';
import DownloadMenu from '@pages/UploadMapping/components/downloadMenu';
import TutorialButton from '@pages/UploadMapping/components/tutorialButton';
import { UploadIcon } from '@assets/icons';

import { useDragFile } from '@store/dragFile';
import UploadFullZone from '@components/uploadFullZone';
import { ConditionalComponent } from '@components/functionComponent';
import { useUploadStore } from '@pages/UploadMapping/helpers/mainState';
import FileInput from '@components/fileInput';
import { usePopUp } from '@store/popup';
import { onUploadFile } from '@pages/UploadMapping/helpers/crud';
import { useSnackbarNotification } from '@store/snackbarNotification';

import './styles.scss';

const Upload = () => {
    const { isDragFile, onChangeDragFile } = useDragFile();
    const { onChangePopUp, onResetPopUp } = usePopUp();
    const { onChangeUploadStore, selectedFile, onResetUploadtore } = useUploadStore();
    const { onChangeSnackbarNotification } = useSnackbarNotification();

    const onChangeFile = ({ selectedFile, checkedStatus }) => {
        if (checkedStatus === 'not-valid') return;

        onChangeUploadStore({ selectedFile });

        onChangePopUp({
            status: 'submit',
            title: 'Apakah anda yakin?',
            description: 'Data yang sudah diupload akan masuk kedalam database',
            onSubmit: () => {
                onUploadFile({
                    selectedFile,
                    onChangeSnackbarNotification,
                    onClose: () => onResetPopUp(),
                    onLoadingSubmit: () => onChangePopUp({ isLoading: true }),
                    onResetUploadtore: () => onResetUploadtore(),
                });
            },
            onClose: () => onResetUploadtore(),
        });
    };

    return (
        <Box className="upload-container__upload-sellout">
            <ConditionalComponent statement={isDragFile}>
                <UploadFullZone
                    maxSize={3}
                    accessAllowed={'.xlsx,.csv,.xls'}
                    onSelectFile={(selectedFile) => onChangeFile({ selectedFile })}
                    onClose={() => onChangeDragFile({ isDragFile: false })}
                />
            </ConditionalComponent>
            <Text className="title">Upload Mapping</Text>
            <Flex className="head">
                <DownloadMenu />
                <TutorialButton />
            </Flex>
            <Flex className="body">
                <Box className={`upload-zone__container ${selectedFile?.name && 'zone-actived'}`}>
                    <UploadIcon color={false ? '#588669' : '#868686'} />
                    <Text className="upload-text">{selectedFile?.name || 'Choose file to upload'}</Text>
                    <FileInput
                        maxSize={3}
                        accessAllowed={'.xlsx,.csv,.xls'}
                        onChange={({ selectedFile, checkedStatus }) => onChangeFile({ selectedFile, checkedStatus })}
                    />
                </Box>
            </Flex>
        </Box>
    );
};

export default Upload;
