import { Box, Button, Flex } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import { Text } from '@components/text';
import { LogoutIcon } from '@components/header/icons';

import { ImageError, ReloadIcon } from './images';
import { onRefreshPage, onLogout } from './functions';
import './styles.scss';
import { useEffect } from 'react';

const ErrorPage = ({ error }) => {
    const navigate = useNavigate();

    // useEffect(() => {
    //     window.location.href = '/audit-trail';
    // }, []);

    return (
        <Box className="error-page__container">
            <ImageError />

            <Text className="desc">
                Terjadi kesalahan sistem{' '}
                <span className="error-text">{error?.error?.toString() || 'Internal server error'}</span>, harap klik{' '}
                <span className="span-button" onClick={() => onRefreshPage(navigate)}>
                    button reload
                </span>{' '}
                untuk memuat ulang halaman atau <span>logout</span>
            </Text>

            <Flex gap="16px">
                <Button
                    onClick={() => {
                        window.location.href = '/audit-trail';
                    }}
                >
                    Reload
                    <ReloadIcon />
                </Button>

                <Button onClick={() => onLogout({ navigate })} gap="8px">
                    Logout
                    <LogoutIcon color="white" />
                </Button>
            </Flex>
        </Box>
    );
};

export default ErrorPage;
