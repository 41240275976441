export const ArrowLeftIcon = ({ onClick }) => (
    <svg
        className="arrow-left"
        onClick={onClick}
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M9 13.5L4.5 9L9 4.5"
            stroke="#005B99"
            strokeWidth="1.25"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M13.5 13.5L9 9L13.5 4.5"
            stroke="#005B99"
            strokeWidth="1.25"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const ArrowRightIcon = ({ onClick }) => (
    <svg onClick={onClick} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M4.5 4.5L9 9L4.5 13.5"
            stroke="var(--main-color)"
            strokeWidth="1.25"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M9 4.5L13.5 9L9 13.5"
            stroke="var(--main-color)"
            strokeWidth="1.25"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const ChevronDownIcon = () => (
    <svg
        className="chevron-down__icon"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M15 7.5L10 12.5L5 7.5"
            stroke="var(--main-color)"
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
