import React, { useEffect, useState } from 'react';
import { Box, Text, Flex } from '@chakra-ui/layout';
import SectionTable from './sections/sectionTable';
import { tableList, tableActual } from './const';

import { fetchAchievementHandler } from './function';

import { Spinner } from '@chakra-ui/react';
import './style.scss';

export const AchievementContext = React.createContext();

const MainDashboard = () => {
    const [data, setData] = useState([]);
    const [actualData, setActualData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetchAchievementHandler({ setData, setActualData, setIsLoading });
    }, []);

    return (
        <Box className={`achievement-dashboard__wrapper`}>
            <Box className={`achievement__container `}>
                {!isLoading && data?.length === 0 && actualData?.length === 0 && (
                    <Flex h="100%" justify="center" align="center">
                        <Text className="title-label">
                            Halaman Informasi Pencapaian sedang dalam tahap Pengerjaan dan Validasi. Kami mohon maaf
                            atas ketidaknyamanan ini, informasi terkait penyelesaian validasi akan diinformasikan dalam
                            beberapa waktu ke depan
                        </Text>
                    </Flex>
                )}
                {!isLoading && data?.length > 0 && (
                    <>
                        <SectionTable data={data} tableList={tableList} />
                    </>
                )}
                {!isLoading && actualData?.length > 0 && (
                    <>
                        <Text className="title" mt="24px" textAlign="left" w="100%">
                            Pencapaian PSF 2023
                        </Text>
                        <SectionTable data={actualData} tableList={tableActual} />
                    </>
                )}
                {isLoading && (
                    <Box className="spinner-container">
                        <Spinner color="var(--main-color)" />
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default MainDashboard;
