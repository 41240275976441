import Cookies from 'universal-cookie';
import { useEffect } from 'react';
import { Flex, Box, Text } from '@chakra-ui/react';

import { PeopleIcon } from '@assets/logo';
import FloatingMenu from '@components/floatingMenu';
import { clickProfileList } from './const';
import SkeletonContainer from '@components/skeletonContainer';
import { useSkeletonPage } from '@store/skeletonPage';

import './styles.scss';
import { ChevronDownIcon } from '@chakra-ui/icons';
import moment from 'moment';


const Header = () => {
    const cookies = new Cookies();
    const { onChangeSkeletonPage, isLoadingSkeleton } = useSkeletonPage();
    const username = cookies.get('username') || '-';
    const role = cookies.get('role') || '-';

    useEffect(() => {
        onChangeSkeletonPage();
        // eslint-disable-next-line
    }, []);

    return (
        <Flex className="header-container">
            <Box className="badge-container" bg="#ffe6e6 !important">
                <SkeletonContainer isLoading={isLoadingSkeleton} color={'#e0bbbb'} width="70px" height="16px">
                    <Text className="text" color="#815e5e !important">
                        Week {moment().week()}
                    </Text>
                </SkeletonContainer>
            </Box>

            <Box className="badge-container">
                <SkeletonContainer
                    isLoading={isLoadingSkeleton}
                    color={'rgb(166, 191, 239)'}
                    width="70px"
                    height="16px"
                >
                    <Text className="text">{role}</Text>
                </SkeletonContainer>
            </Box>
            <FloatingMenu
                list={clickProfileList}
                isClickMenu
                buttonElement={
                    <Flex alignItems="center" className="button-element__container">
                        <PeopleIcon />
                        <Text className="username-text">{username}</Text>
                        <ChevronDownIcon fontSize="24px" />
                    </Flex>
                }
            />
        </Flex>
    );
};

export default Header;
