export const NodeIcon = ({ color = 'var(--main-color)' }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="18" y="15" width="4" height="4" rx="2" transform="rotate(90 18 15)" stroke={color} />
        <rect x="6" y="8" width="4" height="4" rx="2" transform="rotate(-90 6 8)" stroke={color} />
        <path d="M8 8V13C8 14.8856 8 15.8284 8.58579 16.4142C9.17157 17 10.1144 17 12 17H14" stroke={color} />
    </svg>
);

export const NodeSource = ({ color = 'var(--main-color)' }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="6" y="8" width="4" height="4" rx="2" transform="rotate(-90 6 8)" stroke={color} />

        <path d="M8 8V13C8 14.8856 8 15.8284 8.58579 16.4142C9.17157 17 10.1144 17 12 17H14" stroke={color} />
    </svg>
);

export const NodeTarget = ({ color = 'var(--main-color)' }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="18" y="15" width="4" height="4" rx="2" transform="rotate(90 18 15)" stroke={color} />
        <path d="M8 8V13C8 14.8856 8 15.8284 8.58579 16.4142C9.17157 17 10.1144 17 12 17H14" stroke={color} />
    </svg>
);

export const GroupIcon = ({ color = 'var(--main-color)' }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M14.1159 8.32009C14.2109 8.43409 14.3516 8.5 14.5 8.5H16.8C17.3683 8.5 17.7645 8.50039 18.0729 8.52559C18.3755 8.55031 18.5493 8.5964 18.681 8.66349C18.9632 8.8073 19.1927 9.03677 19.3365 9.31901C19.4036 9.45069 19.4497 9.62454 19.4744 9.92712C19.4996 10.2355 19.5 10.6317 19.5 11.2V13.8C19.5 14.3683 19.4996 14.7645 19.4744 15.0729C19.4497 15.3755 19.4036 15.5493 19.3365 15.681C19.1927 15.9632 18.9632 16.1927 18.681 16.3365C18.5493 16.4036 18.3755 16.4497 18.0729 16.4744C17.7645 16.4996 17.3683 16.5 16.8 16.5H9.2C8.6317 16.5 8.23554 16.4996 7.92712 16.4744C7.62454 16.4497 7.45069 16.4036 7.31901 16.3365C7.03677 16.1927 6.8073 15.9632 6.66349 15.681C6.5964 15.5493 6.55031 15.3755 6.52559 15.0729C6.50039 14.7645 6.5 14.3683 6.5 13.8V8.2C6.5 7.6317 6.50039 7.23554 6.52559 6.92712C6.55031 6.62454 6.5964 6.45069 6.66349 6.31901C6.8073 6.03677 7.03677 5.8073 7.31901 5.66349C7.45069 5.5964 7.62454 5.55031 7.92712 5.52559C8.23554 5.50039 8.6317 5.5 9.2 5.5H10.5012C11.0792 5.5 11.2764 5.50402 11.453 5.55151C11.6226 5.59714 11.7829 5.67222 11.9265 5.77332C12.076 5.87852 12.2054 6.02748 12.5754 6.4715L14.1159 8.32009Z"
            stroke={color}
            strokeLinejoin="round"
        />
        <path
            d="M17 16V17.6C17 18.4401 17 18.8601 16.8365 19.181C16.6927 19.4632 16.4632 19.6927 16.181 19.8365C15.8601 20 15.4401 20 14.6 20H5.4C4.55992 20 4.13988 20 3.81901 19.8365C3.53677 19.6927 3.3073 19.4632 3.16349 19.181C3 18.8601 3 18.4401 3 17.6V10.4C3 9.55992 3 9.13988 3.16349 8.81901C3.3073 8.53677 3.53677 8.3073 3.81901 8.16349C4.13988 8 4.55992 8 5.4 8H7"
            stroke={color}
            strokeLinejoin="round"
        />
    </svg>
);

export const TypeIcon = ({ color = 'var(--main-color)' }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="6" height="6" rx="1" transform="matrix(1 0 0 -1 14 10)" stroke="white" strokeLinecap="round" />
        <path
            d="M10 14H14C14.9428 14 15.4142 14 15.7071 14.2929C16 14.5858 16 15.0572 16 16V18C16 18.9428 16 19.4142 15.7071 19.7071C15.4142 20 14.9428 20 14 20H10V14Z"
            stroke={color}
            strokeLinecap="round"
        />
        <path
            d="M10 10C10 9.05719 10 8.58579 9.70711 8.29289C9.41421 8 8.94281 8 8 8H6C5.05719 8 4.58579 8 4.29289 8.29289C4 8.58579 4 9.05719 4 10V14H10V10Z"
            stroke={color}
            strokeLinecap="round"
        />
        <path
            d="M10 20H6C5.05719 20 4.58579 20 4.29289 19.7071C4 19.4142 4 18.9428 4 18V14H10V20Z"
            stroke={color}
            strokeLinecap="round"
        />
    </svg>
);

export const IdIcon = ({ color = 'var(--main-color)', onClick }) => (
    <svg onClick={onClick} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6 13V5.69371C6 5.37716 6 5.21888 6.10382 5.14405C6.20764 5.06921 6.3578 5.11927 6.65811 5.21937L16.577 8.52566C17.1653 8.72176 17.4594 8.81981 17.4594 9C17.4594 9.18019 17.1653 9.27824 16.577 9.47434L6 13ZM6 13V18V19"
            stroke={color}
            stroke-width="2"
            strokeLinecap="round"
        />
    </svg>
);

export const NoteIcon = ({ color = 'var(--main-color)' }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M5.5 5.9C5.5 5.05992 5.5 4.63988 5.66349 4.31901C5.8073 4.03677 6.03677 3.8073 6.31901 3.66349C6.63988 3.5 7.05992 3.5 7.9 3.5H12.5059C12.8728 3.5 13.0562 3.5 13.2288 3.54145C13.3819 3.57819 13.5282 3.6388 13.6624 3.72104C13.8138 3.8138 13.9435 3.94352 14.2029 4.20294L17.7971 7.79706C18.0565 8.05648 18.1862 8.1862 18.279 8.33757C18.3612 8.47178 18.4218 8.6181 18.4586 8.77115C18.5 8.94378 18.5 9.12723 18.5 9.49411V18.1C18.5 18.9401 18.5 19.3601 18.3365 19.681C18.1927 19.9632 17.9632 20.1927 17.681 20.3365C17.3601 20.5 16.9401 20.5 16.1 20.5H7.9C7.05992 20.5 6.63988 20.5 6.31901 20.3365C6.03677 20.1927 5.8073 19.9632 5.66349 19.681C5.5 19.3601 5.5 18.9401 5.5 18.1V5.9Z"
            stroke={color}
        />
        <path
            d="M12.5 3.5V7.1C12.5 7.94008 12.5 8.36012 12.6635 8.68099C12.8073 8.96323 13.0368 9.1927 13.319 9.33651C13.6399 9.5 14.0599 9.5 14.9 9.5H18.5"
            stroke={color}
        />
    </svg>
);

export const ColorPalleteIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12" cy="12" r="8.5" stroke="var(--main-color)" />
        <path
            d="M16.2426 7.75736C15.1174 6.63214 13.5913 6 12 6C10.4087 6 8.88258 6.63214 7.75736 7.75736C6.63214 8.88258 6 10.4087 6 12C6 13.5913 6.63214 15.1174 7.75736 16.2426L12 12L16.2426 7.75736Z"
            fill="var(--main-color)"
        />
    </svg>
);