import { create } from 'zustand';

const state = {
    status: null,
    title: '',
    description: '',
};

export const useDownloadNotification = create((set) => ({
    ...state,
    onChangeDownloadNotification: (newState) => set((state) => ({ ...state, ...newState })),
    onResetDownloadNotification: () => set(() => ({ ...state })),
}));
