export const getColor = ({ status }) => {
    const defaultColor = {
        borderColor: '#2C54BA',
        backgroundColor: '#EAEEF8',
        iconColor: '#2C54BA',
    };

    const obj = {
        download: {
            borderColor: '#2C54BA',
            backgroundColor: '#EAEEF8',
            iconColor: '#2C54BA',
        },
        error: {
            borderColor: '#E03138',
            backgroundColor: '#FCEAEB',
            iconColor: '#E03138',
        },
        failed: {
            borderColor: '#E03138',
            backgroundColor: '#FCEAEB',
            iconColor: '#E03138',
        },
        success: {
            borderColor: '#2EA31F',
            backgroundColor: '#EAF6E9',
            iconColor: '#2EA31F',
        },
    };

    return obj[status] || defaultColor;
};
