import { memo, useEffect, useState } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { NodeResizer } from 'reactflow';

const Group = ({ data, selected }) => {
    return (
        <>
            <NodeResizer
                lineStyle={{ borderWidth: '2px', borderColor: 'var(--main-color-light-active)' }}
                handleStyle={{
                    border: '2px solid var(--main-color)',
                    background: 'white',
                    width: '18px',
                    height: '18px',
                    borderRadius: '50%',
                }}
                isVisible={selected}
                minWidth={100}
                minHeight={30}
            />
            <Box
                className={`default-group__node ${selected && 'selected-node'}`}
                onClick={() => data?.onSelectElement(data)}
            >
                <Box className="group-title">{data?.label}</Box>
            </Box>
        </>
    );
};

export default memo(Group);
