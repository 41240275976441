import { TextColumn, IconAccessColumn, ActionsComponent } from '@pages/UserManagement/components/actionsTable';

export const tableList = [
    {
        name: 'Email',
        width: 200,
        value: 'email',
        component: ({ value }) => <TextColumn value={value} />,
    },

    {
        name: 'Username',
        width: 180,
        value: 'name',
        component: ({ value }) => <TextColumn value={value} />,
    },

    {
        name: 'Role',
        width: 120,
        value: 'role_name',
        component: ({ value }) => <TextColumn value={value} />,
    },

    {
        name: 'Upload Data',
        width: 100,
        value: 'active_access',
        component: ({ value }) => <IconAccessColumn value={value} type="upload_data" />,
    },
    {
        name: 'Audit Trail',
        width: 100,
        value: 'active_access',
        component: ({ value }) => <IconAccessColumn value={value} type="audit_trail" />,
    },
    {
        name: 'Feedback Report',
        width: 120,
        value: 'active_access',
        component: ({ value }) => <IconAccessColumn value={value} type="feedback_report" />,
    },
    {
        name: 'Cancel Upload',
        width: 120,
        value: 'active_access',
        component: ({ value }) => <IconAccessColumn value={value} type="cancel_sellout" />,
    },
    {
        name: 'User Management',
        width: 130,
        value: 'active_access',
        component: ({ value }) => <IconAccessColumn value={value} type="user_management" />,
    },
    {
        name: 'Actions',
        width: 80,
        value: null,
        component: ({ values }) => <ActionsComponent values={values} />,
    },
];
